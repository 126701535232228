import React from 'react';
import Form from '../../../_ui/Form/Form';
import Input from '../../../_ui/Input/Input';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';
import withTranslation from '../../../components/_hoc/withTranslation';

const UnsupportedCustomConfig = props => {
  const {
    t,
    config,
    onClose
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={config}
      onSubmit={() => {
      }}
      render={() => {
        return (
          <>
            <Input
              fullWidth
              name="id"
              label={'id'}
              disabled={true}
            />
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="button"
                text={t('simpleModal_close')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default withTranslation(UnsupportedCustomConfig);
