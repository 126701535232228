import { isExternalIndicator } from '../../components/IndicatorEdit/IndicatorEditUtils';

export const INDICATOR_STATUS = Object.freeze({
  ORIGINAL: 0,
  ADDED: 1,
  DELETED: 2,
  UPDATED: 3,
  MOVED: 4
});

export const findValidIndex = (indicators, startIndex, direction) => {
  let validIndex = startIndex;

  if (direction > 0) {
    while (validIndex < indicators.length) {
      if (indicators[validIndex].meta.status !== INDICATOR_STATUS.DELETED) {
        return validIndex;
      }

      validIndex++;
    }
  } else if (direction < 0) {
    while (validIndex >= 0) {
      if (indicators[validIndex].meta.status !== INDICATOR_STATUS.DELETED) {
        return validIndex;
      }

      validIndex--;
    }
  }

  return -1;
};

export const isAlreadyChanged = (value, statuses) => statuses.includes(value);

export const indicatorsToQuestionCodes = indicators =>
  indicators
    .filter(({ meta }) => meta.status !== INDICATOR_STATUS.DELETED)
    .map(({ code }) => code);

const removeConfigFields = indicator =>
  Object.keys(indicator).reduce((obj, key) => {
    if (key.startsWith('#')) {
      return obj;
    }

    return {
      ...obj,
      [key]: indicator[key]
    };
  }, {});

export const indicatorsToQuestions = (indicators, oldQuestions = {}) => {
  const questions = removeConfigFields(oldQuestions);

  indicators.forEach(({ meta, ...indicator }) => {
    if (meta.status !== INDICATOR_STATUS.DELETED && !isExternalIndicator(indicator)) {
      questions[indicator.code] = removeConfigFields(indicator);
    }
  });

  return questions;
};
