function asUtf16(str) {
  let buffer, bufferView, val;
  buffer = new ArrayBuffer(str.length * 2);
  bufferView = new Uint16Array(buffer);
  bufferView[0] = 0xfeff;
  for (let i = 0; i < str.length; i++) {
    val = str.charCodeAt(i);
    bufferView[i + 1] = val;
  }
  return bufferView;
}

function makeCsvBlob(rows) {
  return new Blob([asUtf16(toTsv(rows)).buffer], {
    type: 'text/csv;charset=utf-16'
  });
}

function toTsv(rows) {
  function escapeValue(val) {
    const textVal = val ? val + '' : '';

    if (/^0\d+$/.test(textVal)) {
      return `="${textVal}"`;
    }

    return textVal ? textVal.replace(/\t+/g, ' ') : '';
  }

  return rows.map(function(row) {
    return row.map(escapeValue).join('\t');
  }).join('\n') + '\n';
}

export function downloadCsv(rows, filename) {
  let a, blob;
  blob = makeCsvBlob(rows);
  a = document.createElement('a');
  a.style.display = 'none';
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.href = URL.createObjectURL(blob);
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
}
