import React, { useState } from 'react';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import StaticTable from '../../../_ui/Table/StaticTable';
import Modal from '../../../_ui/Modal/Modal';
import Button from '../../../_ui/Button/Button';
import withTranslation from '../../_hoc/withTranslation';
import ExportFormEdit from './ExportFormEdit/ExportFormEdit';
import { useStyles } from './ExportFormsEditStyle';

const ExportFormsEdit = props => {
  const {
    t,
    questions,
    questionCodes,
    exportForms,
    deleteExportForm,
    editExportForm
  } = props;

  const classes = useStyles();

  const [exportFormEditIndex, setExportFormEditIndex] = useState(null);

  const closeExportFormEdit = () => {
    setExportFormEditIndex(null);
  };

  const columns = [
    {
      Header: t('exportFormsEdit_exportFormFileName'),
      accessor: 'exportFormFileName'
    },
    {
      Header: t('exportFormsEdit_exportFormApplicableRule'),
      accessor: 'exportFormApplicableRule',
      sortable: false
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ index }) =>
        <>
          <Button
            size="small"
            icon={<EditIcon/>}
            className={classes.leftButton}
            tooltip={t('exportFormsEdit_actionTooltipEdit')}
            onClick={event => {
              event.stopPropagation();
              setExportFormEditIndex(index);
            }}
          />
          <Button
            size="small"
            icon={<DeleteIcon/>}
            color="secondary"
            className={classes.rightButton}
            tooltip={t('exportFormsEdit_actionTooltipDelete')}
            onClick={event => {
              event.stopPropagation();
              deleteExportForm(index);
            }}
          />
        </>,
      style: {
        textAlign: 'center'
      },
      width: 140
    }
  ];

  return (
    <>
      <Button
        type="button"
        text={t('exportFormsEdit_addFormRule')}
        style={{ marginBottom: '15px' }}
        onClick={() => setExportFormEditIndex(-1)}
      />
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        minRows={Math.max(3, exportForms.length)}
        columns={columns}
        data={exportForms}
      />
      <Modal
        open={exportFormEditIndex !== null}
        onClose={closeExportFormEdit}
        title={
          exportFormEditIndex === -1 ?
            t('exportFormsEdit_addExportFormTitle') :
            t('exportFormsEdit_editExportFormTitle')
        }
      >
        <ExportFormEdit
          questions={questions}
          questionCodes={questionCodes}
          exportForm={exportFormEditIndex === -1 ?
            {
              exportFormApplicableRule: '',
              exportFormFileName: '',
              exportFormQuestionCodes: []
            } :
            exportForms[exportFormEditIndex]
          }
          onClose={closeExportFormEdit}
          onSubmit={exportForm => {
            editExportForm(exportForm, exportFormEditIndex);
            closeExportFormEdit();
          }}
        />
      </Modal>
    </>
  );
};

export default withTranslation(ExportFormsEdit);
