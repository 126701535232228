import React, { useReducer, useEffect, useRef, useState } from 'react';
import { PassportsApi } from '../../_helpers/service';
import { downloadCsv } from '../../utils/csvCreator';
import passportDumpIndicatorsWorker, { PassportDumpIndicatorsWorker } from './PassportDumpIndicatorsWorker';

const initialState = {
  progress: 0,
  error: null
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'START_DUMP':
      return initialState;

    case 'PROGRESS_DUMP':
      return {
        ...state,
        progress: action.payload
      };

    case 'FINISH_DUMP':
      return {
        ...state,
        progress: 100
      };

    case 'ERROR_DUMP':
      return {
        ...initialState,
        error: action.payload
      };

    default:
      return state;
  }
};

const withPassportDumpIndicators = Component => {
  return props => {
    const { lang, passport, ...otherProps } = props;

    const [complexMetadatas, setComplexMetadatas] = useState({ data: null, loading: false });

    const [state, dispatch] = useReducer(stateReducer, initialState);

    const worker = useRef(new PassportDumpIndicatorsWorker(passportDumpIndicatorsWorker));

    useEffect(() => {
        const { questions } = passport;

        const complexKeys =
          Object.keys(questions)
            .filter(key => questions[key].type === 'complex' && !!questions[key].config.metadataKey)
            .map(key => ({ key, metadataKey: questions[key].config.metadataKey }));

        const data = {};

        if (complexKeys.length === 0) {
          setComplexMetadatas(state => ({ ...state, data }));
        } else {
          setComplexMetadatas(state => ({ ...state, loading: true }));

          let index = 0;

          for (let complexKey of complexKeys) {
            PassportsApi.getMetadata(complexKey.metadataKey)
              .then(response => {
                data[complexKey.key] = response.data;
              })
              .catch(() => {
                data[complexKey.key] = null;
              })
              // eslint-disable-next-line no-loop-func
              .finally(() => {
                if (++index === complexKeys.length) {
                  setComplexMetadatas(state => ({ ...state, data, loading: false }));
                }
              });
          }
        }
      },
      [passport]
    );

    useEffect(() => {
        if (complexMetadatas.loading || complexMetadatas.data === null) return;

        worker.current.postMessage({
          type: 'START_DUMP',
          data: { lang, passport, complexMetadatas: complexMetadatas.data }
        });

        worker.current.addEventListener('message',
          event => {
            const { type, data } = event.data;

            switch (type) {
              case 'START_DUMP':
                dispatch({ type });
                break;

              case 'FINISH_DUMP':
                dispatch({ type });
                downloadCsv(data, passport.id);
                break;

              case 'PROGRESS_DUMP':
              case 'ERROR_DUMP':
                dispatch({ type, payload: data });
                break;

              default:
                break;
            }
          });
      },
      [lang, passport, complexMetadatas]
    );

    useEffect(() => {
        const workerRef = worker.current;

        return () => {
          workerRef.terminate();
        };
      },
      []
    );

    const { progress, error } = state;

    return (
      <Component
        lang={lang}
        passport={passport}
        dumpProgress={progress}
        dumpError={error}
        {...otherProps}
      />
    );
  };
};

export default withPassportDumpIndicators;
