import { range } from 'lodash';
import * as Yup from 'yup';
import translate from '../../utils/translate';

const MIN_NUMBER_EMAILS = 5;
const MAX_NUMBER_EMAILS = 10;
const FILL_NUMBER_EMAILS = 3;

export const maxRecipients = recipients =>
  Math.max(MIN_NUMBER_EMAILS, Math.min(recipients.length + FILL_NUMBER_EMAILS, MAX_NUMBER_EMAILS));

export const PassportRecipientsEditSchema = recipients => {
  const length = maxRecipients(recipients);

  return Yup.object().shape(
    range(length)
      .reduce((obj, index) => ({
          ...obj,
          [`email${index}`]: Yup.string(() => translate('passportRecipientsEditValidation_emailHint'))
            .email(() => translate('passportRecipientsEditValidation_emailInvalid'))
        }),
        {}
      )
  );
};

export const fillRecipientsValues = recipients => {
  const length = maxRecipients(recipients);

  return range(length)
    .reduce((obj, index) => ({
        ...obj,
        [`email${index}`]: recipients[index] || ''
      }),
      {}
    );
};

export const recipientsValues = values =>
  range(Object.keys(values).length)
    .reduce((emails, index) => {
        const email = values[`email${index}`];
        if(email) {
          return [...emails, email];
        }
        return emails;
      },
      []
    );
