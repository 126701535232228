import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Dict from '../../utils/Dict';
import { Notice } from '../../utils/Notice';
import withTranslation from '../../components/_hoc/withTranslation';
import Select from '../../_ui/Select/Select';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import { indicatorEditModule } from '../IndicatorEdit/IndicatorEditDucks';

const DimensionEditCode = props => {
  const {
    t, lang,
    code,
    excludedData,
    onClose,
    onSubmit,
    dictionaries,
    dictionariesLoading
  } = props;
  const [dictCode, setDictCode] = useState(code);
  const [excluded, setExcluded] = useState(excludedData[code] || []);

  const [dictData, setDictData] = useState({ items: [], loading: false });

  useEffect(() => {
      setDictData(data => ({ ...data, loading: true }));
      Dict.item(dictCode)
        .then(items => {
          setDictData(data => ({ ...data, items }));
        })
        .finally(() => setDictData(data => ({ ...data, loading: false })));
    },
    [dictCode]
  );

  const dictOptions = useMemo(() =>
      dictionaries.map(info => {
        const label = info[`${lang}Name`] || info.ruName || info.description || '';

        return {
          label: label ? `${info.code} "${label}"` : `${info.code}`,
          value: info.code
        };
      }),
    [dictionaries, lang]
  );

  const dictItemsOptions = useMemo(() =>
      dictData.items.map(item => ({
        label: `(${item.code}) ${item[`${lang}_name`]}`,
        value: item.code
      })),
    [dictData.items, lang]
  );

  const buttonClasses = useButtonStyles();

  return (
    <>
      <div style={{ height: '450px' }}>
        <Select
          withoutForm
          fullWidth
          searchable={true}
          label={t('dimensionEdit_dictCode')}
          options={dictOptions}
          loading={dictionariesLoading}
          value={dictCode}
          onChange={code => {
            setDictCode(code);
            setExcluded(excludedData[code] || []);
          }}
        />
        <Select
          withoutForm
          fullWidth
          multi={true}
          searchable={true}
          label={t('dimensionEdit_excluded')}
          options={dictItemsOptions}
          loading={dictionariesLoading || dictData.loading}
          disabled={!!!dictCode}
          value={excluded}
          onChange={setExcluded}
        />
      </div>
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          disabled={dictCode.length === 0}
          onClick={() => {
            Dict.itemInfo(dictCode).then(info => {
              if (info) {
                const newExcludedData = { ...excludedData };

                if (excluded.length === 0) {
                  delete newExcludedData[info.code];
                } else {
                  newExcludedData[info.code] = [...excluded];
                }

                onSubmit(info.code, newExcludedData);
              } else {
                Notice.error(`Не удалось найти справочник с кодом ${dictCode}`);
              }
            });
          }}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      dictionaries: state[indicatorEditModule].dictionaries,
      dictionariesLoading: state[indicatorEditModule].dictionariesLoading
    }),
    {})
)(DimensionEditCode);