import React from "react";
import MuiButton from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from 'prop-types';
import { CircularProgress } from "@material-ui/core";

const JustButton = React.forwardRef(({ text, icon, loading, disabled, ...rest }, ref) =>
  <MuiButton
    variant="contained"
    color="primary"
    disabled={disabled || loading}
    ref={ref}
    {...rest}
  >
    {loading ?
      <CircularProgress size={20} style={{color: '#2185D0'}} />
      :
      <>
        {icon}
        {text}
      </>
    }
  </MuiButton>
);

function Button({ tooltip, ...rest }) {
  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip} placement="top">
        <JustButton {...rest} />
      </Tooltip>
    );
  }

  return <JustButton {...rest} />;
}

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any
};

export default Button;
