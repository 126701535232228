const COMMON_RULES = [
  'visibilityRule'
];

const sectionRules = () => {
  return [
    ...COMMON_RULES
  ];
};

export const collectSectionRuleMethods = (section = null) => {
  const validRules = sectionRules();

  return validRules.map(name => ({
    type: name,
    method: (section && section[name]) || ''
  }));
};

export const assembleSectionRules = ({ rules = [], ...section }) =>
  rules.reduce((section, rule) => {
      section[rule.type] = rule.method;
      return section;
    },
    { ...section }
  );
