import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { history } from '../../_helpers/store';
import routers from '../../_helpers/routers';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import { Permissions } from '../../_helpers/Permissions';
import IndicatorEdit from '../../components/IndicatorEdit/IndicatorEdit';
import { updateMetadata } from '../PassportsPage/PassportsDucks';
import {
  indicatorsModule,
  updateIndicator
} from '../IndicatorsPage/IndicatorsDucks';

const IndicatorsEditPage = props => {
  const {
    t,
    loadingIndicators,
    indicators,
    indicatorIndex,
    updateIndicator,
    updateMetadata
  } = props;

  useEffect(() => {
      if (indicatorIndex === null) {
        history.push(routers.indicatorsPage.path);
      }
    },
    [indicatorIndex]
  );

  const onSaveIndicator = (indicator, metadata) => {
    const actions = () => {
      updateIndicator(
        indicator, indicatorIndex,
        () => history.push(routers.indicatorsPage.path)
      );
    };

    if (metadata) {
      updateMetadata(metadata, actions);
    } else {
      actions();
    }
  };

  const onCancelIndicator = () => {
    history.push(routers.indicatorsPage.path);
  };

  return (
    <>
      <h3>{t('indicatorsEditPage_title')}</h3>
      <Access permissions={[Permissions.addIndicator.code, Permissions.editIndicator.code]}>
        {indicatorIndex !== null &&
        <IndicatorEdit
          loading={loadingIndicators}
          indicator={indicators[indicatorIndex]}
          onCancel={onCancelIndicator}
          onSubmit={onSaveIndicator}
        />
        }
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingIndicators: state[indicatorsModule].loading,
      indicators: state[indicatorsModule].indicators,
      indicatorIndex: state[indicatorsModule].indicatorIndex
    }),
    {
      updateIndicator,
      updateMetadata
    })
)(IndicatorsEditPage);
