import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import withTranslation from '../../components/_hoc/withTranslation';
import { Notice } from '../../utils/Notice';
import withPassportDumpIndicators from './withPassportDumpIndicators';

const PassportDumpIndicators = props => {
  const {
    t, lang,
    passport,
    dumpProgress,
    dumpError,
    onClose
  } = props;

  useEffect(() => {
      if (!!dumpError) {
        Notice.error(t('passportDumpIndicators_dumpError'));
        onClose();
      }
    },
    [dumpError, onClose]
  );

  useEffect(() => {
      if (dumpProgress >= 100) {
        Notice.success(t('passportDumpIndicators_dumpSuccess'));
        onClose();
      }
    },
    [dumpProgress, onClose]
  );

  return (
    <div>
      <h4>{passport.id} ({passport[`${lang}_name`]})...</h4>
      <LinearProgress
        variant="determinate"
        value={dumpProgress}
      />
    </div>
  );
};

export default withTranslation(withPassportDumpIndicators(PassportDumpIndicators));
