import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Field from '../Form/Field';
import { SelectField, useStyles, useTheme } from './SelectStyle';
import withTranslation from '../../components/_hoc/withTranslation';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  return (
    <SelectField
      margin="dense"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const SelectControl = props => {
  const { options, value, onChange, multi, searchable, disabled, loading, t, ...restProps } = props;

  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    })
  };

  const actualValue =
    multi ?
      value === [] ? null : value.map(value => options.find(entry => value === entry.value)) :
      value === '' ? null : options.find(entry => value === entry.value);

  const actualChange = value =>
    multi ?
      value === null ? [] : value.map(({ value }) => value) :
      value === null ? '' : value.value;

  return (
    <ReactSelect
      classes={classes}
      styles={selectStyles}
      inputId="react-select"
      TextFieldProps={{
        disabled,
        InputLabelProps: {
          htmlFor: 'react-select',
          shrink: true
        },
        ...restProps
      }}
      options={options}
      components={components}
      value={actualValue}
      onChange={value => onChange(actualChange(value))}
      isMulti={multi}
      isSearchable={searchable}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={true}
      placeholder={t('select_placeholder')}
      noOptionsMessage={() => t('select_noOptionsMessage')}
    />
  );
};

const Select = props => {
  const { withoutForm, name, onChange, ...restProps } = props;

  return withoutForm ?
    <SelectControl
      name={name}
      onChange={onChange}
      {...restProps}
    />
    :
    <Field
      name={name}
      onChange={onChange}
    >
      {fieldProps =>
        <SelectControl
          {...restProps}
          {...fieldProps}
        />
      }
    </Field>;
};

Select.propsTypes = {
  withoutForm: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  multi: PropTypes.bool,
  loading: PropTypes.bool
};

Select.defaultProps = {
  withoutForm: false,
  label: '',
  disabled: false,
  searchable: true,
  multi: false,
  loading: false
};

export default withTranslation(Select);
