import React, { useState } from 'react';
import StaticTable from '../../_ui/Table/StaticTable';
import Modal from '../../_ui/Modal/Modal';
import withTranslation from '../../components/_hoc/withTranslation';
import { Permissions } from '../../_helpers/Permissions';
import Access from '../../components/Access';
import RuleEdit from '../RuleEdit/RuleEdit';

const RulesEdit = props => {
  const {
    t,
    title,
    loading = false,
    rules,
    editRule,
    minRows = 5
  } = props;

  const [ruleIndex, setRuleIndex] = useState(null);

  const closeOpenEditRule = () => {
    setRuleIndex(null);
  };

  const rulesColumns = [
    {
      Header: t('rulesEdit_type'),
      accessor: 'type'
    },
    {
      Header: t('rulesEdit_method'),
      accessor: 'method',
      sortable: false
    }
  ];

  return (
    <>
      <StaticTable
        withoutHeader
        minRows={minRows}
        collapsible={false}
        filterable={false}
        loading={loading}
        columns={rulesColumns}
        data={rules}
        onClickRow={({ index }) => setRuleIndex(index)}
      />
      <Access permissions={[Permissions.editIndicatorRule.code]}>
        <Modal
          open={ruleIndex !== null}
          onClose={closeOpenEditRule}
          title={title}
        >
          <RuleEdit
            rule={rules[ruleIndex]}
            onClose={closeOpenEditRule}
            onSubmit={(type, method) => {
              editRule(type, method);
              closeOpenEditRule();
            }}
          />
        </Modal>
      </Access>
    </>
  );
};

export default withTranslation(RulesEdit);
