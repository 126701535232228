import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import {useStyles as useButtonStyles} from '../../_styles/ButtonsStyle';
import Form from '../Form/Form';
import Modal from './Modal';

const SimpleModal = props => {
  const {
    t,
    loading,
    withoutForm,
    onClose,
    onSubmit,
    validate,
    initialValues,
    render,
    children,
    ...restProps
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Modal
      onClose={onClose}
      {...restProps}
    >
      {withoutForm ?
        <>
          {children}
          <div className={buttonClasses.buttonsGroup}>
            <Button
              text={t('simpleModal_submit')}
              className={buttonClasses.submitButton}
              onClick={onSubmit}
              loading={loading}
            />
            <Button
              text={t('simpleModal_cancel')}
              color="secondary"
              onClick={onClose}
            />
          </div>
        </>
        :
        render ?
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            render={(values, props) =>
              <>
                {render(values, props)}
                <div className={buttonClasses.buttonsGroup}>
                  <Button
                    type="submit"
                    text={t('simpleModal_submit')}
                    className={buttonClasses.submitButton}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    text={t('simpleModal_cancel')}
                    color="secondary"
                    onClick={onClose}
                  />
                </div>
              </>
            }
          />
          :
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
          >
            {children}
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="submit"
                text={t('simpleModal_submit')}
                className={buttonClasses.submitButton}
                loading={loading}
              />
              <Button
                type="button"
                text={t('simpleModal_cancel')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </Form>
      }
    </Modal>
  );
};

SimpleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  withoutForm: PropTypes.bool,
  enableBackdropClick: PropTypes.bool,
  validate: PropTypes.any
};

SimpleModal.defaultProps = {
  open: false,
  title: 'Title',
  enableBackdropClick: false
};

export default withTranslation(SimpleModal);