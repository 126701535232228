import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  width: 100%;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid white;
  nav ol li {
    color: white;
  }
  nav ol li a {
    color: white;
  }
  nav ol li .last {
    font-weight: bold;
  }
`;