import React, { useState } from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import Button from '../../_ui/Button/Button';
import StaticTable from '../../_ui/Table/StaticTable';
import Modal from '../../_ui/Modal/Modal';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../../components/_hoc/withTranslation';
import IndicatorCustomConfigEdit from './IndicatorCustomConfigEdit';
import { ensureIndicatorCustomConfigs } from './IndicatorCustomConfigsUtils';

const IndicatorCustomConfigsEdit = props => {
  const {
    t,
    configs: originalConfigs,
    onSave,
    onClose
  } = props;

  const [configs, setConfigs] = useState(ensureIndicatorCustomConfigs(originalConfigs));

  const [customConfigEdit, setCustomConfigEdit] = useState(null);

  const onSaveCustomConfig = config => {
    setConfigs(configs => {
      const index = configs.findIndex(({ id }) => id === config.id);
      if (index >= 0) {
        configs[index] = config;
      }
      return configs;
    });

    setCustomConfigEdit(null);
  };

  const onCloseCustomConfigEdit = () =>
    setCustomConfigEdit(null);

  const buttonClasses = useButtonStyles();

  const columns = [
    {
      Header: 'id',
      accessor: 'id',
      sortable: false
    },
    {
      sortable: false,
      Cell: ({ original }) =>
        <Button
          size="small"
          icon={<EditIcon/>}
          tooltip={t('indicatorEdit_actionTooltipEdit')}
          onClick={event => {
            event.stopPropagation();
            setCustomConfigEdit(original);
          }}
        />,
      style: {
        textAlign: 'center'
      },
      width: 80
    }
  ];

  return (
    <div style={{ marginTop: '15px' }}>
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        minRows={Math.max(3, configs.length)}
        columns={columns}
        data={configs}
      />
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          onClick={() => onSave(configs)}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
      <Modal
        width={'1000px'}
        open={!!customConfigEdit}
        onClose={onCloseCustomConfigEdit}
        title={t('indicatorEdit_customConfigEdit')}
      >
        <IndicatorCustomConfigEdit
          config={customConfigEdit}
          onSave={onSaveCustomConfig}
          onClose={onCloseCustomConfigEdit}
        />
      </Modal>
    </div>
  );
};

export default withTranslation(IndicatorCustomConfigsEdit);
