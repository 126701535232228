import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Field from '../Form/Field';
import { InputField } from './InputStyle';

const trimText = (text, end = false) => {
  text = text.trimStart().replace(/\s\s+/g, ' ');
  return end ? text.trimEnd() : text;
};

const InputControl = props =>
  <InputField
    margin="dense"
    {...props}
  />;

const Input = props => {
  const { withoutForm, name, onChange, autoTrim, ...restProps } = props;

  const [value, setValue] = useState(null);
  const [trimEnd, setTrimEnd] = useState(false);

  const maybeTrim = (value, end = false) => {
    value = autoTrim ? trimText(value, trimEnd || end) : value;
    setTrimEnd(false);
    return value;
  };

  return withoutForm ?
    <InputControl
      name={name}
      onChange={event => {
        event.persist();
        setValue(event);

        onChange({
          ...event,
          target: {
            ...event.target,
            value: maybeTrim(event.target.value)
          }
        });
      }}
      inputProps={{
        onPaste: () => setTrimEnd(true),
        onBlur: () => {
          if (value) {
            onChange({
              ...value,
              target: {
                ...value.target,
                value: maybeTrim(value.target.value, true)
              }
            });
          }
        }
      }}
      {...restProps}
    />
    :
    <Field name={name}>
      {({ onChange, ...fieldProps }) =>
        <InputControl
          name={name}
          onChange={event => {
            event.persist();
            setValue(event.target.value);

            onChange(maybeTrim(event.target.value))
          }}
          inputProps={{
            onPaste: () => setTrimEnd(true),
            onBlur: () => {
              if (value) {
                onChange(maybeTrim(value, true));
              }
            }
          }}
          {...restProps}
          {...fieldProps}
        />
      }
    </Field>;
};

Input.propsTypes = {
  withoutForm: PropTypes.bool,
  autoTrim: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

Input.defaultProps = {
  withoutForm: false,
  autoTrim: false,
  label: ''
};

export default Input;
