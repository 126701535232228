import React, { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useDict } from '../_hooks/useDict';
import { DICTIONARY } from '../../_helpers/constants';
import CheckboxTree, { makeTree, makeNode, insertNode } from '../../_ui/CheckboxTree/CheckboxTree';
import withTranslation from '../../components/_hoc/withTranslation';

const makeOOTypesTree = (lang, ooTypes) => {
  const tree = makeTree();

  sortBy(ooTypes, ['code'])
    .forEach(item => {
      insertNode(
        tree,
        makeNode(item.code, item[`${lang}_name`]),
        item.hasOwnProperty('parent_code') ? item.parent_code : undefined
      )
    });

  return tree;
};

const IndicatorCustomConfigSchoolSubtypesEdit = props => {
  const {
    lang,
    data,
    onChange
  } = props;

  const ooTypes = useDict(DICTIONARY.OO_TYPES);

  const ooTypesTree = useMemo(
    () => makeOOTypesTree(lang, ooTypes),
    [lang, ooTypes]
  );

  return (
    <CheckboxTree
      loading={ooTypes.length === 0}
      readOnly={ooTypes.length === 0}
      options={ooTypesTree}
      checked={data}
      onCheck={onChange}
    />
  );
};

export default withTranslation(IndicatorCustomConfigSchoolSubtypesEdit);
