import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import { login } from './LoginDucks';
import withTranslation from '../../components/_hoc/withTranslation';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import { useStyles } from './LoginStyle';
import { SignInSchema } from './LoginValidation';

function LoginPage({ t, login }) {
  const classes = useStyles();
  const [visible, setVisibility] = useState(false);

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginForm}>
        <h2 className={classes.title}>{t('loginPage_auth')}</h2>
        <Form
          initialValues={{
            username: '',
            password: ''
          }}
          onSubmit={data => login(data)}
          validate={SignInSchema}
        >
          <Input
            fullWidth
            name="username"
            label={t('loginPage_username')}
          />
          <div className={classes.passwordField}>
            <Input
              fullWidth
              name="password"
              label={t('loginPage_password')}
              type={visible ? 'text' : 'password'}
            />
            <div
              className={classes.icon}
              onClick={() => setVisibility(!visible)}
            >
              {
                visible
                  ? <VisibilityIcon/>
                  : <VisibilityOffIcon/>
              }
            </div>
          </div>
          <Button
            type="submit"
            text={t('loginPage_signIn')}
            className={classes.submit}
          />
        </Form>
      </div>
    </div>
  );
}

export default compose(
  withTranslation,
  connect(null, { login })
)(LoginPage);
