export const Permissions = {
  homePage: { code: 'homePage', textCode: 'homePage_permission' },

  passportsPage: { code: 'passportsPage', textCode: 'passportsPage_permission' },
  createPassport: { code: 'createPassport', textCode: 'createPassport_permission' },
  editPassport: { code: 'editPassport', textCode: 'editPassport_permission' },
  deletePassport: { code: 'deletePassport', textCode: 'deletePassport_permission' },
  dumpIndicatorsPassport: {
    code: 'dumpIndicatorsPassport',
    textCode: 'dumpIndicatorsPassport_permission'
  },
  exportPassport: {
    code: 'exportPassport',
    textCode: 'exportPassport_permission'
  },
  importPassport: {
    code: 'importPassport',
    textCode: 'importPassport_permission'
  },
  savePassport: { code: 'savePassport', textCode: 'savePassport_permission' },
  editPassportCustomConfigs: {
    code: 'editPassportCustomConfigs',
    textCode: 'editPassportCustomConfigs_permission'
  },

  sectionsPage: { code: 'sectionsPage', textCode: 'sectionsPage_permission' },
  editSection: { code: 'editSection', textCode: 'editSection_permission' },
  addSection: { code: 'addSection', textCode: 'addSection_permission' },
  addSubsection: { code: 'addSubsection', textCode: 'addSubsection_permission' },
  deleteSection: { code: 'deleteSection', textCode: 'deleteSection_permission' },

  indicatorsPage: { code: 'indicatorsPage', textCode: 'indicatorsPage_permission' },
  addIndicator: { code: 'addIndicator', textCode: 'addIndicator_permission' },
  sortIndicators: { code: 'sortIndicators', textCode: 'sortIndicators_permission' },
  editIndicator: { code: 'editIndicator', textCode: 'editIndicator_permission' },
  copyIndicator: { code: 'copyIndicator', textCode: 'copyIndicator_permission' },
  deleteIndicator: { code: 'deleteIndicator', textCode: 'deleteIndicator_permission' },
  editIndicatorRule: { code: 'editIndicatorRule', textCode: 'editIndicatorRule_permission' },
  editIndicatorCustomConfigs: {
    code: 'editIndicatorCustomConfigs',
    textCode: 'editIndicatorCustomConfigs_permission'
  },

  indicatorsAddPage: { code: 'indicatorsAddPage', textCode: 'indicatorsAddPage_permission' },
  indicatorsEditPage: { code: 'indicatorsEditPage', textCode: 'indicatorsEditPage_permission' },

  indicatorsEditComplexPage: {
    code: 'indicatorsEditComplexPage',
    textCode: 'indicatorsEditComplexPage_permission'
  },
  indicatorsAddComplexPage: {
    code: 'indicatorsAddComplexPage',
    textCode: 'indicatorsAddComplexPage_permission'
  }
};
