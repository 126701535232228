import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import Select from '../../_ui/Select/Select';
import TreeSelect from '../../_ui/TreeSelect/TreeSelect';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import FlexContainer from '../../_styles/FlexContainer';
import withTranslation from '../../components/_hoc/withTranslation';
import IndicatorsEdit from '../../components/IndicatorsEdit/IndicatorsEdit';
import { makeExternalIndicator } from '../../components/IndicatorEdit/IndicatorEditUtils';
import {
  indicatorsToQuestionCodes,
  indicatorsToQuestions
} from '../../components/IndicatorsEdit/IndicatorsEditUtils';
import { updatePassportData } from '../../components/PassportEdit/PassportEditUtils';
import PassportSave from '../../components/PassportSave/PassportSave';
import Access from '../../components/Access';
import { Permissions } from '../../_helpers/Permissions';
import { history } from '../../_helpers/store';
import routers from '../../_helpers/routers';
import {
  resetComplexIndicators,
  setComplexIndicator
} from '../../components/IndicatorEdit/IndicatorEditDucks';
import {
  passportsModule,
  getPassportsVersions,
  getPassport,
  setPassport,
  updatePassport,
  getExternalPassport,
  setExternalPassport
} from '../../pages/PassportsPage/PassportsDucks';
import {
  indicatorsModule,
  setSection,
  setIndicator,
  moveIndicator,
  copyIndicator,
  addIndicator,
  linkIndicator,
  deleteIndicator,
  restoreIndicator
} from './IndicatorsDucks';
import LinkedIndicators from '../../components/LinkedIndicators/LinkedIndicators';
import ExternalIndicators from '../../components/ExternalIndicators/ExternalIndicators';

const sectionsConfig = {
  codeKey: 'id',
  childKey: 'subGroups'
};

const IndicatorsPage = props => {
  const {
    t, lang,
    passportsLoading,
    passportsInfo,
    getPassportsVersions,
    passport,
    passportVersion,
    isPassportChanged,
    externalPassport,
    getPassport,
    setPassport,
    updatePassport,
    getExternalPassport,
    setExternalPassport,
    resetComplexIndicators,
    setComplexIndicator,
    section,
    setSection,
    indicatorsLoading,
    indicators,
    moveIndicator,
    copyIndicator,
    addIndicator,
    linkIndicator,
    deleteIndicator,
    restoreIndicator,
    setIndicator
  } = props;

  const [openExternalIndicators, setOpenExternalIndicators] = useState(false);
  const [openLinkedIndicators, setOpenLinkedIndicators] = useState(false);
  const [openSavePassport, setOpenSavePassport] = useState(false);

  useEffect(() => {
      resetComplexIndicators();
      setComplexIndicator(null);
      setIndicator(null);
    },
    [resetComplexIndicators, setComplexIndicator, setIndicator]
  );

  useEffect(() => {
      getPassportsVersions();
    },
    [getPassportsVersions]
  );

  useEffect(() => {
      if (passport && section.passportName) {
        if (passport.id !== section.passportName) {
          setSection(null, null, null);
        }
      }
    },
    [setSection, passport, section]
  );

  const toggleExternalIndicators = () =>
    setOpenExternalIndicators(open => !open);

  const toggleLinkedIndicators = () =>
    setOpenLinkedIndicators(open => !open);

  const toggleSavePassport = () =>
    setOpenSavePassport(open => !open);

  const onSavePassport = (currentVersion, commitComment) => {
    updatePassport(
      updatePassportData(
        passport,
        section.value,
        indicatorsToQuestionCodes(indicators),
        indicatorsToQuestions(indicators, passport.questions)
      ),
      currentVersion ? passportVersion : null,
      commitComment,
      () => {
        setSection(null, null, null);
        toggleSavePassport();
      }
    );
  };

  const onResetPassport = () => {
    setSection(section.name, section.value, passport);
    toggleSavePassport();
  };

  const sectionsOptions = useMemo(
    () => passport ? cloneDeep(passport.group.subGroups) : [],
    [passport]
  );

  return (
    <>
      <FlexContainer
        justifySpaceBetween
        itemsCenter
        style={{ marginBottom: '15px' }}
      >
        <div style={{ width: '80%' }}>
          <Select
            withoutForm
            fullWidth
            label={t('indicatorsPage_choosePassport')}
            loading={passportsLoading}
            disabled={isPassportChanged}
            options={passportsInfo.map(x => ({ label: x.id, value: x.id }))}
            value={passport && passport.id}
            onChange={name => {
              if (name) {
                getPassport(name);
              } else {
                setPassport(null);
              }
              setSection(null, null, null);
            }}
          />
        </div>
        <Access permissions={[Permissions.savePassport.code]}>
          <Button
            type="button"
            text={t('indicatorsPage_save')}
            disabled={!isPassportChanged}
            onClick={toggleSavePassport}
          />
        </Access>
      </FlexContainer>
      <TreeSelect
        withoutForm
        fullWidth
        label={t('indicatorsPage_chooseSection')}
        parentSelectable={true}
        data={sectionsOptions}
        dataConfig={sectionsConfig}
        value={section.name}
        onChange={(name, value) => setSection(name, value, passport)}
        renderLabel={item => item[`${lang}_name`]}
        disabled={!!!passport || passport.group.subGroups.length === 0 || isPassportChanged}
      />
      <Access permissions={[Permissions.addIndicator.code]}>
        <Button
          type="button"
          text={t('indicatorsPage_addIndicator')}
          style={{ marginTop: '15px', marginBottom: '15px', marginRight: '8px' }}
          disabled={!!!section.name}
          onClick={() => {
            setIndicator(-1);
            history.push(routers.indicatorsAddPage.path);
          }}
        />
        <Button
          type="button"
          text={t('indicatorsPage_addExternalIndicator')}
          style={{ marginTop: '15px', marginBottom: '15px', marginRight: '8px' }}
          disabled={!!!section.name}
          onClick={toggleExternalIndicators}
        />
        <Button
          type="button"
          text={t('indicatorsPage_linkIndicator')}
          style={{ marginTop: '15px', marginBottom: '15px' }}
          disabled={!!!section.name}
          onClick={toggleLinkedIndicators}
        />
      </Access>
      <IndicatorsEdit
        loading={indicatorsLoading}
        indicators={indicators}
        moveIndicator={moveIndicator}
        copyIndicator={copyIndicator}
        deleteIndicator={deleteIndicator}
        restoreIndicator={restoreIndicator}
        editIndicator={index => {
          setIndicator(index);
          history.push(routers.indicatorsEditPage.path);
        }}
      />
      <Access permissions={[Permissions.addIndicator.code]}>
        <Modal
          open={openExternalIndicators}
          onClose={toggleExternalIndicators}
          title={t('indicatorsPage_addExternalIndicatorTitle')}
        >
          <ExternalIndicators
            excludePassport={passport ? passport.id : ''}
            passportsInfo={passportsInfo}
            passportsLoading={passportsLoading}
            passport={externalPassport}
            getPassport={getExternalPassport}
            setPassport={setExternalPassport}
            onSelectCode={code => {
              addIndicator(makeExternalIndicator(code), toggleExternalIndicators);
            }}
          />
        </Modal>
        <Modal
          open={openLinkedIndicators}
          onClose={toggleLinkedIndicators}
          title={t('indicatorsPage_linkIndicatorTitle')}
        >
          <LinkedIndicators
            passport={passport}
            onSelectIndicator={indicator => {
              linkIndicator(indicator, toggleLinkedIndicators);
            }}
          />
        </Modal>
      </Access>
      <Access permissions={[Permissions.savePassport.code]}>
        <Modal
          open={openSavePassport}
          onClose={toggleSavePassport}
          title={t('indicatorsPage_savePassportTitle')}
        >
          <PassportSave
            loading={passportsLoading}
            onClose={toggleSavePassport}
            onReset={onResetPassport}
            onSave={onSavePassport}
          />
        </Modal>
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      passportsLoading: state[passportsModule].loading,
      passportsInfo: state[passportsModule].passportsInfo,
      passport: state[passportsModule].passport,
      passportVersion: state[passportsModule].passportVersion,
      isPassportChanged: state[passportsModule].isPassportChanged,
      externalPassport: state[passportsModule].externalPassport,
      section: state[indicatorsModule].section,
      indicatorsLoading: state[indicatorsModule].loading,
      indicators: state[indicatorsModule].indicators
    }),
    {
      getPassportsVersions,
      getPassport,
      setPassport,
      updatePassport,
      getExternalPassport,
      setExternalPassport,
      resetComplexIndicators,
      setComplexIndicator,
      setSection,
      setIndicator,
      moveIndicator,
      copyIndicator,
      addIndicator,
      linkIndicator,
      deleteIndicator,
      restoreIndicator
    })
)(IndicatorsPage);
