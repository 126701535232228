import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  addButton: {},
  deleteButton: {}
}));
