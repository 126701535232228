export const getRandomDigits = n => {
  const pool = "0123456789";

  const digits = [];

  for(let i = 0; i < n; i++) {
    digits.push(pool[Math.floor((Math.random() * n))]);
  }

  return digits.join('');
};