import { createReducer } from 'redux-starter-kit';
import { cloneDeep } from 'lodash';
import { Notice } from '../../utils/Notice';
import translate from '../../utils/translate';
import { assembleSectionRules } from '../../components/SectionsTree/SectionsTreeUtils';
import { passportChanged } from '../PassportsPage/PassportsDucks';
import {
  NEW_SECTION,
  checkDuplicateId,
  markAdded,
  markAddedSub,
  markDeleted,
  markEdited,
  movingSectionStepOne, movingSectionStepTwo, resetCount
} from './SectionsPageUtils';

/**
 * Constants
 */

export const sectionsModule = 'sections';

const SET_SECTIONS = `${sectionsModule}/SET_SECTION`;

const DELETE_SECTION = `${sectionsModule}/DELETE_SECTION`;

const EDIT_SECTION = `${sectionsModule}/EDIT_SECTION`;

const ADD_SECTION = `${sectionsModule}/ADD_SECTION`;

const ADD_SUB_SECTION = `${sectionsModule}/ADD_SUB_SECTION`;

const SECTION_MODIFICATION = `${sectionsModule}/SECTION_MODIFICATION`;

const MOVE_SECTION = `${sectionsModule}/MOVE_SECTION`;

/**
 * Reducer
 */

const initialState = {
  sections: [],
  sectionsId: null,
  sectionModification: false
};

export default createReducer(initialState, {
  [SET_SECTIONS]: (state, action) => {
    const { passport } = action;

    state.sections = cloneDeep(passport.group.subGroups);
    state.sectionsId = passport.group.id;
  },
  [DELETE_SECTION]: (state, action) => {
    const { sections } = action;
    state.sections = sections;
    state.sectionModification = false;
  },
  [EDIT_SECTION]: (state, action) => {
    const { sections } = action;
    state.sections = sections;
    state.sectionModification = false;
  },
  [ADD_SECTION]: (state, action) => {
    const { sections } = action;
    state.sections = sections;
    state.sectionModification = false;
  },
  [ADD_SUB_SECTION]: (state, action) => {
    const { section, id } = action;
    if (!id) {
      state.sections.push({
        ...NEW_SECTION,
        ...section,
        isAdded: true
      });
    } else {
      state.sections = markAddedSub(id, section, state.sections);
    }
    state.sectionModification = false;
  },
  [SECTION_MODIFICATION]: (state, action) => {
    const { modification } = action;
    state.sectionModification = modification;
  },
  [MOVE_SECTION]: (state, action) => {
    const { sectionId, index, parentId, lastChildId } = action;
    resetCount();
    const sections = movingSectionStepOne(sectionId, state.sections);
    state.sections = movingSectionStepTwo(state.sectionsId, parentId, index, sections, lastChildId);
  }
});

/**
 * Actions
 */

export const setSections = passport => async dispatch => {
  dispatch({ type: SET_SECTIONS, passport });
  dispatch(passportChanged(false));
};

export const deleteSection = (id, setModalState) => (dispatch, getState) => {
  dispatch({ type: SECTION_MODIFICATION, modification: true });

  const sections = getState().sections.sections;
  const subGroups = markDeleted(id, sections);

  dispatch({ type: DELETE_SECTION, sections: subGroups });
  setModalState(false);

  dispatch(passportChanged());
};

export const editSection = (id, section, setModalState) => (dispatch, getState) => {
  dispatch({ type: SECTION_MODIFICATION, modification: true });

  const { sections, sectionsId } = getState().sections;

  const isDuplicate = id !== section.id && checkDuplicateId(section.id, sectionsId, sections);

  if (isDuplicate) {
    dispatch({ type: SECTION_MODIFICATION, modification: false });
    return Notice.warning(translate('sectionsPage_duplicate'));
  }

  const subGroups = markEdited(id, assembleSectionRules(section), sections);

  dispatch({ type: EDIT_SECTION, sections: subGroups });
  setModalState(false);

  dispatch(passportChanged());
};

export const addSection = (id, section, setModalState) => (dispatch, getState) => {
  dispatch({ type: SECTION_MODIFICATION, modification: true });

  const { sections, sectionsId } = getState().sections;

  const isDuplicate = checkDuplicateId(section.id, sectionsId, sections);

  if (isDuplicate) {
    dispatch({ type: SECTION_MODIFICATION, modification: false });
    return Notice.warning(translate('sectionsPage_duplicate'));
  }

  const subGroups = markAdded(id, assembleSectionRules(section), sections);

  dispatch({ type: ADD_SECTION, sections: subGroups });
  setModalState(false);

  dispatch(passportChanged());
};

export const addSubSection = (id, section, setModalState) => (dispatch, getState) => {
  dispatch({ type: SECTION_MODIFICATION, modification: true });

  const { sections: { sections, sectionsId }, passports: { passport: { id: passportId } } } = getState();

  const isDuplicate = checkDuplicateId(section.id, sectionsId, sections);

  if (isDuplicate) {
    dispatch({ type: SECTION_MODIFICATION, modification: false });
    return Notice.warning(translate('sectionsPage_duplicate'));
  }

  if (passportId === id) {
    dispatch({ type: ADD_SUB_SECTION, section: assembleSectionRules(section) });
    setModalState(false);

    dispatch(passportChanged());

    return;
  }

  dispatch({ type: ADD_SUB_SECTION, id, section: assembleSectionRules(section) });
  setModalState(false);

  dispatch(passportChanged());
};

export const moveSection = (sectionId, index, parentId, lastChildId) => dispatch => {
  dispatch({ type: MOVE_SECTION, sectionId, index, parentId, lastChildId });
  dispatch(passportChanged());
};
