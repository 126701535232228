import { createReducer } from 'redux-starter-kit';
import { cloneDeep } from 'lodash';
import { Notice } from '../../utils/Notice';
import translate from '../../utils/translate';
import { arrayMove } from '../../utils/array';
import { makeExternalIndicator } from '../../components/IndicatorEdit/IndicatorEditUtils';
import {
  INDICATOR_STATUS,
  findValidIndex,
  isAlreadyChanged
} from '../../components/IndicatorsEdit/IndicatorsEditUtils';
import {
  collectSectionsQuestionCodes
} from '../../components/PassportEdit/PassportEditUtils';
import { passportChanged } from '../PassportsPage/PassportsDucks';

/**
 * Constants
 */

export const indicatorsModule = 'indicators';

const SET_SECTION = `${indicatorsModule}/SET_SECTION`;

const SET_INDICATOR = `${indicatorsModule}/SET_INDICATOR`;

const ADD_INDICATOR = `${indicatorsModule}/ADD_INDICATOR`;

const LINK_INDICATOR = `${indicatorsModule}/LINK_INDICATOR`;

const UPDATE_INDICATOR = `${indicatorsModule}/UPDATE_INDICATOR`;

const COPY_INDICATOR = `${indicatorsModule}/COPY_INDICATOR`;

const DELETE_INDICATOR = `${indicatorsModule}/DELETE_INDICATOR`;

const RESTORE_INDICATOR = `${indicatorsModule}/RESTORE_INDICATOR`;

const MOVE_INDICATOR = `${indicatorsModule}/MOVE_INDICATOR`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  section: { name: null, value: null, passportName: null },
  indicators: [],
  indicatorIndex: null,
  questionCodes: []
};

export default createReducer(initialState, {
  [SET_SECTION]: (state, action) => {
    const { name, value, passport } = action;

    state.indicators = [];
    state.indicatorIndex = null;
    state.questionCodes = [];

    state.section = name ?
      { name, value: cloneDeep(value), passportName: passport.id } :
      { name: null, value: null, passportName: null };

    if (name) {
      state.questionCodes = collectSectionsQuestionCodes(passport.group.subGroups);

      if(value.sections.length > 0) {
        let count = 0;

        value.sections[0].questionCodes
          .forEach(code => {
            const indicator = passport.questions[code] || makeExternalIndicator(code);

            state.indicators.push({
              ...indicator,
              meta: {
                index: count++,
                status: INDICATOR_STATUS.ORIGINAL
              }
            });
          });
      }
    }
  },
  [SET_INDICATOR]: (state, action) => {
    const { index } = action;

    state.indicatorIndex = index;
  },
  [ADD_INDICATOR]: (state, action) => {
    const { indicator } = action;

    const prevIndex = findValidIndex(state.indicators, state.indicators.length - 1, -1);

    state.indicators.push({
      ...indicator,
      meta: {
        index: prevIndex >= 0 ? state.indicators[prevIndex].meta.index + 1 : 0,
        status: INDICATOR_STATUS.ADDED
      }
    });

    state.questionCodes.push(indicator.code);

    const deletedIndex = state.indicators.findIndex(
      ({code, meta: {status}}) =>
        status === INDICATOR_STATUS.DELETED &&
        code.toLowerCase() === indicator.code.toLowerCase()
    );
    if (deletedIndex > -1) {
      state.indicators.splice(deletedIndex, 1);
    }
  },
  [LINK_INDICATOR]: (state, action) => {
    const { indicator } = action;

    const prevIndex = findValidIndex(state.indicators, state.indicators.length - 1, -1);

    state.indicators.push({
      ...indicator,
      meta: {
        index: prevIndex >= 0 ? state.indicators[prevIndex].meta.index + 1 : 0,
        status: INDICATOR_STATUS.ADDED
      }
    });

    const deletedIndex = state.indicators.findIndex(
      ({code, meta: {status}}) =>
        status === INDICATOR_STATUS.DELETED &&
        code.toLowerCase() === indicator.code.toLowerCase()
    );
    if (deletedIndex > -1) {
      state.indicators.splice(deletedIndex, 1);
    }
  },
  [UPDATE_INDICATOR]: (state, action) => {
    const { indicator, index } = action;
    const oldCode = state.indicators[index].code;

    state.indicators[index] = {
      ...indicator,
      meta: {
        ...indicator.meta,
        status: isAlreadyChanged(state.indicators[index].meta.status, [INDICATOR_STATUS.ADDED]) ?
          INDICATOR_STATUS.ADDED :
          INDICATOR_STATUS.UPDATED
      }
    };

    if (oldCode.toLowerCase() !== indicator.code.toLowerCase()) {
      const codeIndex = state.questionCodes.indexOf(oldCode);
      if (codeIndex > -1) {
        state.questionCodes.splice(codeIndex, 1);
      }
      state.questionCodes.push(indicator.code);

      const deletedIndex = state.indicators.findIndex(
        ({code, meta: {status}}) =>
          status === INDICATOR_STATUS.DELETED &&
          code.toLowerCase() === indicator.code.toLowerCase()
      );
      if (deletedIndex > -1) {
        state.indicators.splice(deletedIndex, 1);

        if(deletedIndex < index) {
          state.indicatorIndex--;
        }
      }
    }
  },
  [COPY_INDICATOR]: (state, action) => {
    const { index } = action;
    const originalIndicator = state.indicators[index];
    const copyIndicator = cloneDeep(originalIndicator);

    copyIndicator.code = chooseCopyCode(state.indicators, originalIndicator.code);
    copyIndicator.meta.status = INDICATOR_STATUS.ADDED;
    copyIndicator.meta.index++;

    state.indicators.splice(index + 1, 0, copyIndicator);

    if ((index + 2) < state.indicators.length) {
      for (let i = index + 2; i < state.indicators.length; i++) {
        if (state.indicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.indicators[i].meta.index++;
        }
      }
    }

    state.questionCodes.push(copyIndicator.code);
  },
  [DELETE_INDICATOR]: (state, action) => {
    const { index } = action;

    state.indicators[index].meta.status = INDICATOR_STATUS.DELETED;

    if ((index + 1) < state.indicators.length) {
      for (let i = index + 1; i < state.indicators.length; i++) {
        if (state.indicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.indicators[i].meta.index--;
        }
      }
    }

    const codeIndex = state.questionCodes.indexOf(state.indicators[index].code);
    if (codeIndex > -1) {
      state.questionCodes.splice(codeIndex, 1);
    }
  },
  [RESTORE_INDICATOR]: (state, action) => {
    const { index } = action;

    let metaIndex = 0;

    for (let i = index - 1; i >= 0; i--) {
      if (state.indicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
        metaIndex = state.indicators[i].meta.index + 1;
        break;
      }
    }

    state.indicators[index].meta.status = INDICATOR_STATUS.UPDATED;
    state.indicators[index].meta.index = metaIndex;

    if ((index + 1) < state.indicators.length) {
      for (let i = index + 1; i < state.indicators.length; i++) {
        if (state.indicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.indicators[i].meta.index++;
        }
      }
    }

    state.questionCodes.push(state.indicators[index].code);
  },
  [MOVE_INDICATOR]: (state, action) => {
    const { index, direction } = action;

    const swapIndex = findValidIndex(
      state.indicators,
      direction === 'UP' ? index - 1 : index + 1,
      direction === 'UP' ? -1 : +1
    );

    if (swapIndex === -1) return state;

    const metaIndex = state.indicators[swapIndex].meta.index;

    state.indicators[swapIndex].meta.index = state.indicators[index].meta.index;
    if (!isAlreadyChanged(
      state.indicators[swapIndex].meta.status,
      [INDICATOR_STATUS.ADDED, INDICATOR_STATUS.UPDATED, INDICATOR_STATUS.DELETED]
    )) {
      state.indicators[swapIndex].meta.status = INDICATOR_STATUS.MOVED;
    }

    state.indicators[index].meta.index = metaIndex;
    if (!isAlreadyChanged(
      state.indicators[index].meta.status,
      [INDICATOR_STATUS.ADDED, INDICATOR_STATUS.UPDATED, INDICATOR_STATUS.DELETED]
    )) {
      state.indicators[index].meta.status = INDICATOR_STATUS.MOVED;
    }

    state.indicators = arrayMove(state.indicators, index, swapIndex);
  }
});

/**
 * Actions
 */

export const setSection = (name, value, passport) => async dispatch => {
  dispatch({ type: SET_SECTION, name, value, passport });
  dispatch(passportChanged(false));
};

export const setIndicator = index => async dispatch => {
  dispatch({ type: SET_INDICATOR, index });
};

export const addIndicator = (indicator, onSuccess) => (dispatch, getState) => {
  const questionCodes = getState().indicators.questionCodes;

  if (questionCodes.find(code => code.toLowerCase() === indicator.code.toLowerCase())) {
    Notice.error(translate('indicatorDucks_INDICATOR_EXISTS'));

    return;
  }

  dispatch({ type: ADD_INDICATOR, indicator });
  dispatch(passportChanged());

  onSuccess();
};

export const linkIndicator = (indicator, onSuccess) => (dispatch, getState) => {
  const indicators = getState().indicators.indicators;

  if (indicators.find(({ code }) => code.toLowerCase() === indicator.code.toLowerCase())) {
    Notice.error(translate('indicatorDucks_INDICATOR_LOCAL_EXISTS'));

    return;
  }

  dispatch({ type: LINK_INDICATOR, indicator });
  dispatch(passportChanged());

  onSuccess();
};

export const updateIndicator = (indicator, index, onSuccess) => (dispatch, getState) => {
  const indicators = getState().indicators.indicators;

  if (indicators[index].code !== indicator.code) {
    const questionCodes = getState().indicators.questionCodes;

    if (questionCodes.find(code => code.toLowerCase() === indicator.code.toLowerCase())) {
      Notice.error(translate('indicatorDucks_INDICATOR_EXISTS'));

      return;
    }
  }

  dispatch({ type: UPDATE_INDICATOR, indicator, index });
  dispatch(passportChanged());

  onSuccess();
};

export const copyIndicator = index => async dispatch => {
  dispatch({ type: COPY_INDICATOR, index });
  dispatch(passportChanged());
};

export const deleteIndicator = index => async dispatch => {
  dispatch({ type: DELETE_INDICATOR, index });
  dispatch(passportChanged());
};

export const restoreIndicator = index => async dispatch => {
  dispatch({ type: RESTORE_INDICATOR, index });
  dispatch(passportChanged());
};

export const moveIndicator = (direction, index) => async dispatch => {
  dispatch({ type: MOVE_INDICATOR, direction, index });
  dispatch(passportChanged());
};

/**
 * Utils
 */

const chooseCopyCode = (indicators, code) => {
  const checkIfExists = copyId =>
    !!indicators.find(({ code: exCode }) => exCode === `${code}_${copyId}`);

  let copyId = 1;

  while (true) {
    if (!checkIfExists(copyId))
      break;

    copyId++;
  }

  return `${code}_${copyId}`;
};
