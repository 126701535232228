import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { history } from '../../_helpers/store';
import { Permissions } from '../../_helpers/Permissions';
import Access from '../../components/Access';
import routers from '../../_helpers/routers';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Select from '../../_ui/Select/Select';
import Checkbox from '../../_ui/Checkbox/Checkbox';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../_hoc/withTranslation';
import IndicatorsEdit from '../IndicatorsEdit/IndicatorsEdit';
import { passportsModule } from '../../pages/PassportsPage/PassportsDucks';
import {
  indicatorsToQuestionCodes,
  indicatorsToQuestions
} from '../IndicatorsEdit/IndicatorsEditUtils';
import CellRulesEdit from '../CellRulesEdit/CellRulesEdit';
import RulesEdit from '../RulesEdit/RulesEdit';
import {
  makePassport,
  makePassportSection,
  updatePassportData,
  updatePassportInfo
} from '../PassportEdit/PassportEditUtils';
import {
  INDICATOR_TYPES,
  TABLE_CELL_TYPES,
  indicatorTypeToRenderer,
  allowsIndicatorTypeRecurrent,
  updateIndicator,
  collectIndicatorRuleMethods,
  extractConfigFields,
  injectConfigFields,
  injectComplexMetadataFields,
  addMetadataKeyPrefix
} from './IndicatorEditUtils';
import {
  indicatorEditModule,
  setEditIndicator,
  setComplexMetadata,
  getComplexMetadata,
  resetComplexMetadata,
  setComplexIndicator,
  moveComplexIndicator,
  copyComplexIndicator,
  deleteComplexIndicator,
  restoreComplexIndicator,
  getDictionaries
} from './IndicatorEditDucks';
import { makeIndicatorSchema } from './IndicatorEditValidation';
import IndicatorCustomConfigsEdit from '../IndicatorCustomConfigsEdit/IndicatorCustomConfigsEdit';

const IndicatorEdit = props => {
  const {
    t,
    lang,
    loading,
    indicator,
    onSubmit,
    onCancel,
    editIndicator,
    setEditIndicator,
    complexMetadata,
    complexIndicators,
    loadingComplexIndicators,
    setComplexMetadata,
    getComplexMetadata,
    resetComplexMetadata,
    setComplexIndicator,
    moveComplexIndicator,
    copyComplexIndicator,
    deleteComplexIndicator,
    restoreComplexIndicator,
    complexIndicatorIndex,
    metadataId,
    savingMetadata,
    dictionaries,
    dictionariesLoading,
    getDictionaries
  } = props;
  const workIndicator = editIndicator || indicator;

  const [indicatorSchema, setIndicatorSchema] = useState(
    makeIndicatorSchema(workIndicator.type)
  );
  const [openCustomConfigsEdit, setOpenCustomConfigsEdit] = useState(false);

  const onCloseCustomConfigsEdit = () => {
    setOpenCustomConfigsEdit(false);
  };

  const dictOptions = useMemo(
    () =>
      dictionaries.map(info => {
        const label = info[`${lang}Name`] || info.ruName || info.description || '';

        return {
          label: label ? `${info.code} "${label}"` : `${info.code}`,
          value: info.code
        };
      }),
    [dictionaries, lang]
  );

  useEffect(() => {
    if (dictionaries.length === 0) {
      getDictionaries();
    }
  }, [dictionaries, getDictionaries]);

  useEffect(() => {
    if (editIndicator === null) {
      setEditIndicator(indicator);
    }
  }, [setEditIndicator, editIndicator, indicator]);

  useEffect(() => {
    if (workIndicator.type === 'complex' && !!workIndicator.config.metadataKey) {
      if (complexMetadata === null) {
        getComplexMetadata(workIndicator.config.metadataKey);
      }
    }
  }, [
    getComplexMetadata,
    complexMetadata,
    workIndicator.type,
    workIndicator.config.metadataKey
  ]);

  useEffect(() => {
    if (complexIndicatorIndex !== null) {
      if (complexIndicatorIndex < 0) {
        history.push(routers.indicatorsAddComplexPage.path);
      } else {
        history.push(routers.indicatorsEditComplexPage.path);
      }
    }
  }, [complexIndicatorIndex]);

  const buttonClasses = useButtonStyles();

  const indicatorTypes = useMemo(
    () => INDICATOR_TYPES.map(x => ({ label: x, value: x })),
    []
  );

  const tableCellTypes = useMemo(
    () => TABLE_CELL_TYPES.map(x => ({ label: x, value: x })),
    []
  );

  const customConfigDisabled = () => {
    const { code, ru_name, kk_name, en_name } = indicator;

    return !(code && ru_name && kk_name && en_name);
  };

  return (
    <>
      <Form
        initialValues={extractConfigFields(workIndicator, complexMetadata)}
        validate={indicatorSchema}
        onSubmit={indicator => {
          switch (indicator.type) {
            case 'document': {
              const metadataKey = indicator['#config#metadataKey'];
              const metadataRealKey = addMetadataKeyPrefix(metadataKey, indicator.type);

              const updatedIndicator = updateIndicator(injectConfigFields(indicator));

              onSubmit({
                ...updatedIndicator,
                config: {
                  ...updatedIndicator.config,
                  metadataKey: metadataRealKey
                }
              });
              break;
            }

            case 'complex': {
              const updatedComplexMetadata = injectComplexMetadataFields(
                indicator,
                complexMetadata
              );
              const { ru_name, kk_name, en_name } = updatedComplexMetadata;

              const metadataKey = indicator['#config#metadataKey'];
              const metadataRealKey = addMetadataKeyPrefix(metadataKey, indicator.type);

              const metadataSection = makePassportSection({
                id: metadataKey,
                ru_name,
                kk_name,
                en_name
              });

              const updatedIndicator = updateIndicator(injectConfigFields(indicator));

              onSubmit(
                {
                  ...updatedIndicator,
                  config: {
                    ...updatedIndicator.config,
                    metadataKey: metadataRealKey
                  }
                },
                updatePassportData(
                  updatePassportInfo(
                    {
                      ...updatedComplexMetadata,
                      group: {
                        ...updatedComplexMetadata.group,
                        subGroups: [metadataSection]
                      }
                    },
                    {
                      id: metadataRealKey,
                      ru_name,
                      kk_name,
                      en_name
                    }
                  ),
                  metadataSection,
                  indicatorsToQuestionCodes(complexIndicators),
                  indicatorsToQuestions(
                    complexIndicators,
                    updatedComplexMetadata.questions
                  )
                )
              );
              break;
            }

            default: {
              onSubmit(updateIndicator(injectConfigFields(indicator)));
              break;
            }
          }
        }}
        render={(indicator, { setValues: setVals }) => {
          const setValues = values => {
            setVals(values);
            setEditIndicator(values);
          };

          return (
            <>
              <Input fullWidth autoTrim name="code" label={t('common_code')} />
              <Input fullWidth autoTrim name="ru_name" label={t('common_ruName')} />
              <Input fullWidth autoTrim name="kk_name" label={t('common_kkName')} />
              <Input fullWidth autoTrim name="en_name" label={t('common_enName')} />
              <Select
                fullWidth
                name="type"
                label={t('indicatorEdit_type')}
                options={indicatorTypes}
                disabled={!!!indicator.code}
                onChange={type => {
                  if (type !== indicator.type) {
                    setIndicatorSchema(makeIndicatorSchema(type));

                    const newIndicator = extractConfigFields(
                      updateIndicator(injectConfigFields(indicator), type)
                    );

                    if (type === 'complex') {
                      newIndicator[
                        '#config#metadataKey'
                      ] = `${metadataId}_${indicator.code}`;
                      setComplexMetadata(makePassport());
                    } else {
                      resetComplexMetadata();
                    }

                    setValues(newIndicator);
                  }
                }}
              />
              {allowsIndicatorTypeRecurrent(indicator.type) && (
                <Checkbox
                  fullWidth
                  name="recurrent"
                  label={t('indicatorEdit_recurrent')}
                  disabled={!!!indicator.code}
                />
              )}
              {indicator.type === 'dynamic lookup' && (
                <Checkbox
                  fullWidth
                  name="#config#anyTreeLevelSelectable"
                  label={t('indicatorEdit_anyTreeLevelSelectable')}
                />
              )}
              <Select
                fullWidth
                name="renderer"
                label={t('indicatorEdit_renderer')}
                disabled={!!!indicator.code}
                options={indicatorTypeToRenderer(indicator.type).map(x => ({
                  label: x,
                  value: x
                }))}
              />
              {(indicator.type === 'dynamic lookup' ||
                indicator.type === 'multiple dynamic lookup') && (
                <div style={{ marginBottom: '15px' }}>
                  <h4>
                    {indicator.type === 'dynamic lookup'
                      ? t('indicatorEdit_dynamicLookupTitle')
                      : t('indicatorEdit_multipleDynamicLookupTitle')}
                  </h4>
                  <Select
                    fullWidth
                    name="#config#lookupId"
                    searchable={true}
                    label={t('indicatorEdit_lookupId')}
                    options={dictOptions}
                    loading={dictionariesLoading}
                  />
                </div>
              )}
              {indicator.type === 'document' && (
                <div style={{ marginBottom: '15px' }}>
                  <h4>{t('indicatorEdit_documentTitle')}</h4>
                  <Input
                    fullWidth
                    name="#config#metadataKey"
                    label={t('indicatorEdit_documentMetadataKey')}
                  />
                </div>
              )}
              {indicator.type === 'complex' && (
                <div style={{ marginBottom: '15px' }}>
                  <h4>{t('indicatorEdit_complexTitle')}</h4>
                  <Checkbox
                    withoutForm
                    fullWidth
                    label={t('indicatorEdit_complexPageSplit')}
                    value={
                      indicator['#config#pageSize'] === '' ||
                      indicator['#config#pageSize'] > 0
                    }
                    onChange={() => {
                      setValues(
                        extractConfigFields(
                          updateIndicator(
                            injectConfigFields({
                              ...indicator,
                              '#config#pageSize':
                                indicator['#config#pageSize'] === '' ||
                                indicator['#config#pageSize'] > 0
                                  ? 0
                                  : 5
                            })
                          )
                        )
                      );
                    }}
                  />
                  {(indicator['#config#pageSize'] === '' ||
                    parseInt(indicator['#config#pageSize']) > 0) && (
                    <Input
                      fullWidth
                      type="number"
                      name="#config#pageSize"
                      label={t('indicatorEdit_complexPageSize')}
                    />
                  )}
                  <Input fullWidth name="#config#ru_name" label={t('common_ruName')} />
                  <Input fullWidth name="#config#kk_name" label={t('common_kkName')} />
                  <Input fullWidth name="#config#en_name" label={t('common_enName')} />
                  <h4>{t('indicatorEdit_complexIndicatorsTitle')}</h4>
                  <Input
                    fullWidth
                    name="#config#metadataKey"
                    label={t('indicatorEdit_complexMetadataKey')}
                  />
                  <Button
                    type="button"
                    text={t('indicatorEdit_addComplexIndicator')}
                    style={{ marginTop: '10px', marginBottom: '15px' }}
                    onClick={() => {
                      setValues(
                        extractConfigFields(
                          updateIndicator(injectConfigFields(indicator))
                        )
                      );

                      setComplexIndicator(-1);
                    }}
                  />
                  <IndicatorsEdit
                    loading={loading || loadingComplexIndicators || dictionariesLoading}
                    indicators={complexIndicators}
                    moveIndicator={moveComplexIndicator}
                    editIndicator={setComplexIndicator}
                    copyIndicator={copyComplexIndicator}
                    deleteIndicator={deleteComplexIndicator}
                    restoreIndicator={restoreComplexIndicator}
                  />
                </div>
              )}
              {indicator.type === 'table' && (
                <div style={{ marginBottom: '15px' }}>
                  <h4>{t('indicatorEdit_tableTitle')}</h4>
                  <Select
                    fullWidth
                    name="#cellType#type"
                    label={t('indicatorEdit_tableCellType')}
                    options={tableCellTypes}
                    onChange={type => {
                      if (type !== indicator['#cellType#type']) {
                        setValues(
                          extractConfigFields(
                            updateIndicator(
                              injectConfigFields({
                                ...indicator,
                                '#cellType#type': type,
                                '#cellType#renderer#id': indicatorTypeToRenderer(type)[0]
                              })
                            )
                          )
                        );
                      }
                    }}
                  />
                  <Checkbox
                    fullWidth
                    name="#cellType#recurrent"
                    label={t('indicatorEdit_tableCellRecurrent')}
                  />
                  <Select
                    fullWidth
                    name="#cellType#renderer#id"
                    label={t('indicatorEdit_tableCellRenderer')}
                    options={indicatorTypeToRenderer(
                      indicator['#cellType#type']
                    ).map(x => ({ label: x, value: x }))}
                  />
                  <h4>{t('indicatorEdit_tableCellRulesTitle')}</h4>
                  <CellRulesEdit
                    horizontalData={indicator.config.horizontalDomainDatas || []}
                    verticalData={indicator.config.verticalDomainDatas || []}
                    excludedData={indicator.config.excludedDomainItems || {}}
                    cellRules={indicator.cellRules || []}
                    editCellRules={(
                      horizontalData,
                      verticalData,
                      excludedData,
                      cellRules
                    ) => {
                      const updatedIndicator = extractConfigFields(
                        updateIndicator(injectConfigFields(indicator))
                      );

                      setValues({
                        ...updatedIndicator,
                        config: {
                          ...updatedIndicator.config,
                          horizontalDomainDatas: horizontalData,
                          verticalDomainDatas: verticalData,
                          excludedDomainItems: excludedData
                        },
                        cellRules: [...cellRules]
                      });
                    }}
                  />
                </div>
              )}
              <div style={{ marginBottom: '15px' }}>
                <h4>{t('indicatorEdit_flkTitle')}</h4>
                <RulesEdit
                  title={t('indicatorEdit_flkEditTitle')}
                  loading={loading || dictionariesLoading}
                  rules={collectIndicatorRuleMethods(indicator)}
                  editRule={(type, method) => {
                    const updatedComplexMetadata =
                      complexMetadata &&
                      injectComplexMetadataFields(indicator, complexMetadata);

                    setValues({
                      ...extractConfigFields(
                        updateIndicator(injectConfigFields(indicator)),
                        updatedComplexMetadata
                      ),
                      [type]: method
                    });

                    if (updatedComplexMetadata) {
                      setComplexMetadata(updatedComplexMetadata);
                    }
                  }}
                />
                <Checkbox
                  fullWidth
                  name="alwaysRequired"
                  label={t('indicatorEdit_alwaysRequired')}
                />
                <Checkbox
                  fullWidth
                  name="keepIfHidden"
                  label={t('indicatorEdit_keepIfHidden')}
                />
              </div>
              <Button
                type="button"
                size="small"
                text={t('indicatorEdit_customConfigsEdit')}
                style={{ marginBottom: '15px' }}
                disabled={customConfigDisabled()}
                onClick={() => setOpenCustomConfigsEdit(true)}
              />
              <div
                className={buttonClasses.buttonsGroup}
                style={{ marginTop: '0px', marginBottom: '20px' }}
              >
                <Button
                  type="submit"
                  text={t('simpleModal_submit')}
                  className={buttonClasses.submitButton}
                  loading={savingMetadata}
                />
                <Button
                  type="button"
                  text={t('simpleModal_cancel')}
                  color="secondary"
                  onClick={onCancel}
                />
              </div>
            </>
          );
        }}
      />
      <Access
        permissions={[
          Permissions.editIndicator.code,
          Permissions.editIndicatorCustomConfigs.code
        ]}
      >
        <Modal
          open={openCustomConfigsEdit}
          onClose={onCloseCustomConfigsEdit}
          title={t('indicatorEdit_customConfigsEdit')}
        >
          <IndicatorCustomConfigsEdit
            configs={indicator.customConfigs}
            onSave={customConfigs => {
              onSubmit({
                ...indicator,
                customConfigs
              });
              setOpenCustomConfigsEdit(false);
            }}
            onClose={onCloseCustomConfigsEdit}
          />
        </Modal>
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      editIndicator: state[indicatorEditModule].editIndicator,
      loadingComplexIndicators: state[indicatorEditModule].loading,
      complexMetadata: state[indicatorEditModule].complexMetadata,
      complexIndicators: state[indicatorEditModule].complexIndicators,
      complexIndicatorIndex: state[indicatorEditModule].complexIndicatorIndex,
      metadataId: state[passportsModule].passport.id,
      savingMetadata: state[passportsModule].saving,
      dictionaries: state[indicatorEditModule].dictionaries,
      dictionariesLoading: state[indicatorEditModule].dictionariesLoading
    }),
    {
      setEditIndicator,
      setComplexMetadata,
      getComplexMetadata,
      resetComplexMetadata,
      setComplexIndicator,
      moveComplexIndicator,
      copyComplexIndicator,
      deleteComplexIndicator,
      restoreComplexIndicator,
      getDictionaries
    }
  )
)(IndicatorEdit);
