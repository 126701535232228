import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const LoadingLayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const useCondensedCheckBoxStyles = makeStyles(theme => ({
  condensed: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingTop: 0,
    paddingBottom: 0
  }
}));
