import { createReducer } from 'redux-starter-kit';
import { PassportsApi } from '../../_helpers/service';
import { Notice } from '../../utils/Notice';
import { getErrorCode, translateError } from '../../utils/apiError';
import translate from '../../utils/translate';

/**
 * Constants
 */

export const passportsModule = 'passports';

const LOADING = `${passportsModule}/LOADING`;

const SAVING = `${passportsModule}/SAVING`;

const GET_PASSPORTS_VERSIONS = `${passportsModule}/GET_PASSPORTS_VERSIONS`;
const GET_PASSPORTS_VERSIONS_ERROR = `${passportsModule}/GET_PASSPORTS_VERSIONS_ERROR`;

const GET_PASSPORT = `${passportsModule}/GET_PASSPORT`;
const GET_PASSPORT_ERROR = `${passportsModule}/GET_PASSPORT_ERROR`;

const SET_PASSPORT = `${passportsModule}/SET_PASSPORT`;

const GET_EXTERNAL_PASSPORT = `${passportsModule}/GET_EXTERNAL_PASSPORT`;
const GET_EXTERNAL_PASSPORT_ERROR = `${passportsModule}/GET_EXTERNAL_PASSPORT_ERROR`;

const SET_EXTERNAL_PASSPORT = `${passportsModule}/SET_EXTERNAL_PASSPORT`;

const ADD_PASSPORT_ERROR = `${passportsModule}/ADD_PASSPORT_ERROR`;

const CLONE_PASSPORT_ERROR = `${passportsModule}/CLONE_PASSPORT_ERROR`;

const UPDATE_PASSPORT_ERROR = `${passportsModule}/UPDATE_PASSPORT_ERROR`;

const DELETE_PASSPORT_ERROR = `${passportsModule}/DELETE_PASSPORT_ERROR`;

const PASSPORT_CHANGED = `${passportsModule}/PASSPORT_CHANGED`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  saving: false,
  passportsVersions: [],
  passportsInfo: [],
  passport: null,
  passportVersion: null,
  isPassportChanged: false,
  externalPassport: null,
  error: null
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    const { loading } = action;

    state.loading = loading;
  },
  [SAVING]: (state, action) => {
    const { saving } = action;

    state.saving = saving;
  },
  [GET_PASSPORTS_VERSIONS]: (state, action) => {
    const { passportsVersions } = action;

    state.passportsInfo = Object.keys(passportsVersions)
      .flatMap(key =>
        Object.keys(passportsVersions[key])
          .map(version => ({ version, ...passportsVersions[key][version] }))
          .filter(passport => passport.master === true)
      )
      .sort(({ id: a }, { id: b }) => a.localeCompare(b));

    state.passportsVersions = passportsVersions;
    state.error = null;
  },
  [GET_PASSPORTS_VERSIONS_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [GET_PASSPORT]: (state, action) => {
    const { passport } = action;

    const passportInfo = state.passportsInfo.find(info => info.id === passport.id);
    state.passportVersion = passportInfo ? passportInfo.version : null;

    state.passport = passport;
    state.isPassportChanged = false;
    state.error = null;
  },
  [GET_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [GET_EXTERNAL_PASSPORT]: (state, action) => {
    const { passport } = action;

    state.externalPassport = passport;
    state.error = null;
  },
  [GET_EXTERNAL_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [SET_PASSPORT]: (state, action) => {
    const { passport, passportVersion } = action;

    state.passport = passport;
    state.passportVersion = passportVersion;
    state.isPassportChanged = false;
  },
  [SET_EXTERNAL_PASSPORT]: (state, action) => {
    const { passport } = action;

    state.externalPassport = passport;
  },
  [ADD_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [CLONE_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [UPDATE_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [DELETE_PASSPORT_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  },
  [PASSPORT_CHANGED]: (state, action) => {
    const { changed } = action;

    state.isPassportChanged = changed;
  }
});

/**
 * Actions
 */

export const getPassportsVersions = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    const passportsVersions = (await PassportsApi.getPassportsVersions()).data;

    dispatch({ type: GET_PASSPORTS_VERSIONS, passportsVersions });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_getPassportsVersionsError'));

    dispatch({ type: GET_PASSPORTS_VERSIONS_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const getPassport = name => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    const passport = (await PassportsApi.getPassport(name)).data;

    dispatch({ type: GET_PASSPORT, passport });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_getPassportError'));

    dispatch({ type: GET_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const setPassport = (passport, version = null) => async dispatch => {
  dispatch({ type: SET_PASSPORT, passport, passportVersion: version });
};

export const getExternalPassport = name => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    const passport = (await PassportsApi.getPassport(name)).data;

    dispatch({ type: GET_EXTERNAL_PASSPORT, passport });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_getPassportError'));

    dispatch({ type: GET_EXTERNAL_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const setExternalPassport = passport => async dispatch => {
  dispatch({ type: SET_EXTERNAL_PASSPORT, passport });
};

export const addPassport = (passport, commitComment, onSuccess) => async (
  dispatch,
  getState
) => {
  const passportIds = getState().passports.passportsInfo.map(({ id }) =>
    id.toLowerCase()
  );

  if (passportIds.find(id => id === passport.id.toLowerCase())) {
    Notice.error(translate('passportsDucks_passportExistsError'));

    return;
  }

  dispatch({ type: LOADING, loading: true });

  try {
    const data = (await PassportsApi.addPassport(passport, commitComment)).data;
    Notice.success(translate('passportsDucks_addPassportSuccess'));

    let newPassport = data.value;
    if (data.recipients) {
      newPassport.recipients = data.recipients;
    }

    dispatch({
      type: SET_PASSPORT,
      passport: newPassport,
      passportVersion: data.version
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_addPassportError'));

    dispatch({ type: ADD_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }

  dispatch(getPassportsVersions());
};

export const clonePassport = (version, id, cloneId, onSuccess) => async (
  dispatch,
  getState
) => {
  const passportIds = getState().passports.passportsInfo.map(({ id }) =>
    id.toLowerCase()
  );

  if (passportIds.find(id => id === cloneId.toLowerCase())) {
    Notice.error(translate('passportsDucks_passportExistsError'));

    return;
  }

  dispatch({ type: LOADING, loading: true });

  try {
    const data = (await PassportsApi.clonePassport(version, id, cloneId)).data;
    Notice.success(translate('passportsDucks_clonePassportSuccess'));

    let newPassport = data.value;
    if (data.recipients) {
      newPassport.recipients = data.recipients;
    }

    dispatch({
      type: SET_PASSPORT,
      passport: newPassport,
      passportVersion: data.version
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_clonePassportError'));

    dispatch({ type: CLONE_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }

  dispatch(getPassportsVersions());
};

export const updatePassport = (
  passport,
  version = null,
  commitComment,
  onSuccess
) => async (dispatch, getState) => {
  if (getState().passports.passport.id.toLowerCase() !== passport.id.toLowerCase()) {
    const passportIds = getState().passports.passportsInfo.map(({ id }) =>
      id.toLowerCase()
    );

    if (passportIds.find(id => id === passport.id.toLowerCase())) {
      Notice.error(translate('passportsDucks_passportExistsError'));

      return;
    }
  }

  dispatch({ type: LOADING, loading: true });

  try {
    const data = (await PassportsApi.updatePassport(passport, commitComment, version))
      .data;
    Notice.success(translate('passportsDucks_updatePassportSuccess'));

    let newPassport = data.value;
    if (data.recipients) {
      newPassport.recipients = data.recipients;
    }

    dispatch({
      type: SET_PASSPORT,
      passport: newPassport,
      passportVersion: data.version
    });

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_updatePassportError'));

    dispatch({ type: UPDATE_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }

  dispatch(getPassportsVersions());
};

export const addMetadata = (metadata, onSuccess) => async dispatch => {
  dispatch({ type: SAVING, saving: true });

  try {
    await PassportsApi.addMetadata(metadata);
    Notice.success(translate('passportsDucks_addMetadataSuccess'));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_addMetadataError'));
  } finally {
    dispatch({ type: SAVING, saving: false });
  }
};

export const updateMetadata = (metadata, onSuccess) => async dispatch => {
  dispatch({ type: SAVING, saving: true });

  try {
    await PassportsApi.updateMetadata(metadata);
    Notice.success(translate('passportsDucks_updateMetadataSuccess'));

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_updateMetadataError'));
  } finally {
    dispatch({ type: SAVING, saving: false });
  }
};

export const deletePassport = name => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    await PassportsApi.deletePassport(name);
    Notice.success(translate('passportsDucks_deletePassportSuccess'));

    dispatch({ type: SET_PASSPORT, passport: null, passportVersion: null });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_deletePassportError'));

    dispatch({ type: DELETE_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }

  dispatch(getPassportsVersions());
};

export const deleteAllPassportVersions = name => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    await PassportsApi.deleteAllPassportVersions(name);
    Notice.success(translate('passportsDucks_deleteAllPassportVersionsSuccess'));

    dispatch({ type: SET_PASSPORT, passport: null, passportVersion: null });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(
      translateError(errorCode, 'passportsDucks_deleteAllPassportVersionsError')
    );

    dispatch({ type: DELETE_PASSPORT_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }

  dispatch(getPassportsVersions());
};

export const passportChanged = (changed = true) => async dispatch => {
  dispatch({ type: PASSPORT_CHANGED, changed });
};
