import React, { useState } from 'react';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import StaticTable from '../../../_ui/Table/StaticTable';
import Modal from '../../../_ui/Modal/Modal';
import Button from '../../../_ui/Button/Button';
import withTranslation from '../../_hoc/withTranslation';
import ExternalClientConfigEdit from './ExternalClientConfigEdit';
import { useStyles } from './ExternalClientConfigsStyles';

const ExternalClientConfigsEdit = props => {
  const {
    t,
    lang,
    questions,
    questionCodes,
    externalClientConfigs,
    deleteExternalClientConfig,
    editExternalClientConfig
  } = props;

  const classes = useStyles();

  const [externalClientConfigEditIndex, setExternalClientConfigEditIndex] = useState(
    null
  );

  const closeExternalClientConfigEdit = () => {
    setExternalClientConfigEditIndex(null);
  };

  const columns = [
    {
      Header: t('passportCustomConfigEdit_externalClientMetadata_tableClientCode'),
      accessor: 'clientCode',
      sortable: false
    },
    {
      Header: t('passportCustomConfigEdit_externalClientMetadata_tableClientName'),
      sortable: false,
      Cell: ({ original: { clientNameKk, clientNameRu } }) =>
        lang === 'ru' ? clientNameRu : clientNameKk
    },
    {
      Header: t(
        'passportCustomConfigEdit_externalClientMetadata_tableClientApplicableRule'
      ),
      accessor: 'clientApplicableRule',
      sortable: false
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ index }) => (
        <>
          <Button
            size="small"
            icon={<EditIcon />}
            className={classes.leftButton}
            tooltip={t(
              'passportCustomConfigEdit_externalClientMetadata_actionTooltipEdit'
            )}
            onClick={event => {
              event.stopPropagation();
              setExternalClientConfigEditIndex(index);
            }}
          />
          <Button
            size="small"
            icon={<DeleteIcon />}
            color="secondary"
            className={classes.rightButton}
            tooltip={t(
              'passportCustomConfigEdit_externalClientMetadata_actionTooltipDelete'
            )}
            onClick={event => {
              event.stopPropagation();
              deleteExternalClientConfig(index);
            }}
          />
        </>
      ),
      style: {
        textAlign: 'center'
      },
      width: 140
    }
  ];

  return (
    <>
      <Button
        type="button"
        text={t('passportCustomConfigEdit_externalClientMetadata_addClientMetadata')}
        style={{ marginBottom: '15px' }}
        onClick={() => setExternalClientConfigEditIndex(-1)}
      />
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        minRows={Math.max(3, externalClientConfigs.length)}
        columns={columns}
        data={externalClientConfigs}
      />
      <Modal
        width={1200}
        open={externalClientConfigEditIndex !== null}
        onClose={closeExternalClientConfigEdit}
        title={
          externalClientConfigEditIndex === -1
            ? t('passportCustomConfigEdit_externalClientMetadata_addClientMetadataTitle')
            : t('passportCustomConfigEdit_externalClientMetadata_editClientMetadataTitle')
        }
      >
        <ExternalClientConfigEdit
          questions={questions}
          questionCodes={questionCodes}
          externalClientConfig={
            externalClientConfigEditIndex === -1
              ? {
                  clientCode: '',
                  clientNameKk: '',
                  clientNameRu: '',
                  clientNameEn: '',
                  clientApplicableRule: '',
                  clientQuestionCodesConfigs: []
                }
              : externalClientConfigs[externalClientConfigEditIndex]
          }
          onClose={closeExternalClientConfigEdit}
          onSubmit={externalClientConfig => {
            editExternalClientConfig(externalClientConfig, externalClientConfigEditIndex);
            closeExternalClientConfigEdit();
          }}
        />
      </Modal>
    </>
  );
};

export default withTranslation(ExternalClientConfigsEdit);
