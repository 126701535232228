import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  loginContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginForm: {
    width: '400px',
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '5px 5px 15px -5px grey',
    border: '5px dashed #b9b9b9'
  },
  title: {
    margin: 0,
    background: '#2185d0',
    color: 'white',
    padding: '10px'
  },
  submit: {
    float: 'right',
    marginTop: '10px',
    fontWeight: 'bold'
  },
  passwordField: {
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    top: '30px',
    right: '0px',
    cursor: 'pointer'
  }
}));