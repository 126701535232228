import React from "react";
import { connect } from 'react-redux';
import { injectIntl } from "react-intl";
import { localeModule } from "../LocaleProvider/LocaleDucks";

const withTranslation = Component => {
  class WithTranslation extends React.Component {
    render() {
      const { intl, ...otherProps } = this.props;
      return (
        <Component
          {...otherProps}
          lang={intl.locale}
          t={param => {
            if (param instanceof Object) {
              return intl.formatMessage(param);
            } else {
              return intl.formatMessage({ id: param });
            }
          }}
        />
      );
    }
  }
  const mapStateToProps = state => ({
    lang: state[localeModule].lang
  });

  return connect(mapStateToProps)(injectIntl(WithTranslation));
};

export default withTranslation;
