import { useState, useEffect } from 'react';
import Dict from '../../utils/Dict';

export function useDict(code) {
  const [dictList, setDictList] = useState([]);

  useEffect(() => {
      let isCancelled = false;
      Dict.item(code).then(list => {
        if (!isCancelled) setDictList(list);
      });
      return () => {
        isCancelled = true;
      };
    },
    [code]
  );

  return dictList;
}

export function useDicts(codes) {
  const [dicts, setDicts] = useState({});

  useEffect(() => {
      let isCancelled = false;

      Dict.items(codes).then(dicts => {
        if (!isCancelled) setDicts(dicts);
      });

      return () => {
        isCancelled = true;
      };
    },
    [codes]
  );

  return dicts;
}