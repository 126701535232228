import React, { useState } from 'react';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import StaticTable from '../../../../_ui/Table/StaticTable';
import Modal from '../../../../_ui/Modal/Modal';
import Button from '../../../../_ui/Button/Button';
import withTranslation from '../../../_hoc/withTranslation';
import ExportFormQuestionCodeEdit from './ExportFormQuestionCodeEdit';
import { useStyles } from '../ExportFormsEditStyle';

const unshowData = showData => showData.map(({ value }) => value).slice(null, -1);

const ExportFormQuestionCodesEdit = props => {
  const { t, questions, questionCodes, data, onChange } = props;

  const classes = useStyles();

  const [questionCodeEditIndex, setQuestionCodeEditIndex] = useState(null);

  const closeQuestionCodeEditIndex = () => {
    setQuestionCodeEditIndex(null);
  };

  const showData = [...data.map(value => ({ value })), { value: null }];

  const columns = [
    {
      sortable: false,
      Cell: ({ original: { value } }) => {
        return (
          value === null && (
            <Button
              size="small"
              icon={<AddIcon />}
              className={classes.addButton}
              tooltip={t('exportFormQuestionCodesEdit_actionTooltipAdd')}
              onClick={event => {
                event.stopPropagation();
                setQuestionCodeEditIndex(-1);
              }}
            />
          )
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 80
    },
    {
      Header: t('exportFormQuestionCodesEdit_tableIndicatorCode'),
      sortable: false,
      Cell: ({ original: { value } }) => {
        if (value === null) {
          return (
            <div style={{ color: 'gray' }}>
              {t('exportFormQuestionCodesEdit_addCode')}
            </div>
          );
        }

        return value;
      }
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ original: { value }, index }) => {
        return (
          value && (
            <>
              <Button
                size="small"
                icon={<EditIcon />}
                className={classes.leftButton}
                tooltip={t('exportFormQuestionCodesEdit_actionTooltipEdit')}
                onClick={event => {
                  event.stopPropagation();
                  setQuestionCodeEditIndex(index);
                }}
              />
              <Button
                size="small"
                icon={<DeleteIcon />}
                className={classes.rightButton}
                color="secondary"
                tooltip={t('exportFormQuestionCodesEdit_actionTooltipDelete')}
                onClick={event => {
                  event.stopPropagation();

                  const data = unshowData(showData);
                  data.splice(index, 1);

                  onChange(data);
                }}
              />
            </>
          )
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 140
    }
  ];

  return (
    <>
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        columns={columns}
        data={showData}
      />
      <Modal
        open={questionCodeEditIndex !== null}
        onClose={closeQuestionCodeEditIndex}
        title={
          questionCodeEditIndex === -1
            ? t('exportFormQuestionCodesEdit_addQuestionCodeTitle')
            : t('exportFormQuestionCodesEdit_editQuestionCodeTitle')
        }
      >
        <ExportFormQuestionCodeEdit
          questions={questions}
          questionCodes={questionCodes}
          excludedQuestionCodes={data}
          requiredQuestionCode={
            questionCodeEditIndex === -1
              ? ''
              : unshowData(showData)[questionCodeEditIndex]
          }
          onClose={closeQuestionCodeEditIndex}
          onSubmit={questionCode => {
            const data = unshowData(showData);
            if (questionCodeEditIndex === -1) {
              data.push(questionCode);
            } else {
              data[questionCodeEditIndex] = questionCode;
            }
            onChange(data);

            closeQuestionCodeEditIndex();
          }}
        />
      </Modal>
    </>
  );
};

export default withTranslation(ExportFormQuestionCodesEdit);
