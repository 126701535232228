import * as Yup from "yup";
import translate from "../../utils/translate";

export const SectionEditSchema = Yup.object().shape({
  id: Yup.string()
    .matches(/^[A-Za-z0-9_]+$/, () => translate('sectionTreeValidation_idMatch'))
    .required(() => translate('common_required')),
  ru_name: Yup.string()
    .required(() => translate('common_required')),
  kk_name: Yup.string()
    .required(() => translate('common_required')),
  en_name: Yup.string()
    .required(() => translate('common_required'))
});