import { cloneDeep } from 'lodash';

export const NEW_SECTION = {
  id: '',
  kk_name: '',
  ru_name: '',
  en_name: '',
  subGroups: [],
  sections: [],
  visibleToRoles: []
};

export const markDeleted = (id, sections, acc = [], root = true) => {
  if (root) {
    return markDeleted(id, sections, [], false);
  }

  for (let i = 0; i < sections.length; i++) {
    const { subGroups, isRemoved } = sections[i];

    if (subGroups.length > 0) {
      acc.push({
        ...sections[i],
        isRemoved: isRemoved ? true : id === sections[i].id || undefined,
        subGroups: markDeleted(id, subGroups, [], false)
      });
    } else {
      acc.push({
        ...sections[i],
        isRemoved: isRemoved ? true : id === sections[i].id || undefined
      });
    }
  }

  return acc;
};

export const markEdited = (id, section, sections, acc = [], root = true) => {
  if (root) {
    return markEdited(id, section, sections, [], false);
  }

  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];
    const changedSection = id === sections[i].id
      ? {
        ...sections[i],
        ...section,
        isEdited: true
      }
      : sections[i];
    if (subGroups.length > 0) {
      acc.push({
        ...changedSection,
        subGroups: markEdited(id, section, subGroups, [], false)
      });
    } else {
      acc.push(changedSection);
    }
  }

  return acc;
};

export const markAdded = (id, section, sections, acc = [], root = true) => {
  if (root) {
    return markAdded(id, section, sections, [], false);
  }

  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];
    const newSection = id === sections[i].id
      && {
        ...NEW_SECTION,
        ...section,
        isAdded: true
      };
    if (subGroups.length > 0) {
      acc.push({
        ...sections[i],
        subGroups: markAdded(id, section, subGroups, [], false)
      });
    } else {
      acc.push(sections[i]);
    }
    if (newSection) {
      acc.push(newSection);
    }
  }

  return acc;
};

export const markAddedSub = (id, section, sections, acc = [], root = true) => {
  if (root) {
    return markAddedSub(id, section, sections, [], false);
  }

  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];
    const newSection = id === sections[i].id
      && {
        ...NEW_SECTION,
        ...section,
        isAdded: true
      };
    if (newSection) {
      subGroups.push(newSection);
    }
    if (subGroups.length > 0) {
      acc.push({
        ...sections[i],
        subGroups: markAddedSub(id, section, subGroups, [], false)
      });
    } else {
      acc.push(sections[i]);
    }
  }

  return acc;
};

export const checkDuplicateId = (id, sectionsId, sections) => {
  if (sectionsId === id) return true;
  const set = recursiveChecking(id, sections, new Set());
  return set.has(true);
};

const recursiveChecking = (id, sections, set) => {
  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];
    const isDuplicate = sections[i].id === id;
    set.add(isDuplicate);
    if (subGroups.length > 0) {
      recursiveChecking(id, subGroups, set);
    }
  }

  return set;
};

export const buildNewSections = (sections, acc = [], root = true) => {
  if (root) {
    return buildNewSections(sections, [], false);
  }

  for (let i = 0; i < sections.length; i++) {
    const { subGroups, isRemoved } = sections[i];

    if (!isRemoved) {
      if (subGroups.length > 0) {
        acc.push({
          ...sections[i],
          subGroups: buildNewSections(subGroups, [], false),
          isEdited: undefined,
          isAdded: undefined
        });
      } else {
        acc.push({
          ...sections[i],
          isEdited: undefined,
          isAdded: undefined
        });
      }
    }
  }

  return acc;
};

let movable = null;
export const movingSectionStepOne = (sectionId, sections, acc = []) => {

  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];

    if (sections[i].id !== sectionId) {
      if (subGroups.length > 0) {
        acc.push({
          ...sections[i],
          subGroups: movingSectionStepOne(sectionId, subGroups, [])
        });
      } else {
        acc.push({
          ...sections[i]
        });
      }
    } else {
      movable = cloneDeep(sections[i]);
    }
  }

  return acc;
};

let k = 0;
export function resetCount() {
  k = 0;
  movable = null;
}
export const movingSectionStepTwo = (parentId, pId, index, sections, lastChildId, acc = []) => {
  for (let i = 0; i < sections.length; i++) {
    const { subGroups } = sections[i];
    k++;
    if (parentId === pId && k === index) {
      acc.push(movable);
    }

    if (subGroups.length > 0) {
      acc.push({
        ...sections[i],
        subGroups: movingSectionStepTwo(sections[i].id, pId, index, subGroups, lastChildId, [])
      });
    } else {
      if (sections[i].id === pId && k === index - 1) {
        acc.push({
          ...sections[i],
          subGroups: [{ ...movable }]
        });
      } else {
        acc.push({
          ...sections[i]
        });
      }
    }

    if (parentId === pId && acc[acc.length - 1].id === lastChildId) {
      acc.push(movable);
    }
  }

  return acc;
};