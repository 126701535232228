import React from 'react';
import Form from '../../../_ui/Form/Form';
import Input from '../../../_ui/Input/Input';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';
import withTranslation from '../../../components/_hoc/withTranslation';
import ExternalClientConfigsEdit from './ExternalClientConfigsEdit';
import { ExternalClientMetadataCustomConfigSchema } from './ExternalClientMetadataCustomConfigValidation';

const ExternalClientMetadataCustomConfig = props => {
  const { t, questions, questionCodes, config, onSave, onClose } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={config}
      validate={ExternalClientMetadataCustomConfigSchema}
      onSubmit={values => {
        onSave(values);
      }}
      render={(config, { setValues: setConfig }) => {
        return (
          <>
            <Input fullWidth name="id" label={'id'} disabled={true} />
            <div style={{ marginTop: '15px' }}>
              <h4>{t('passportCustomConfigEdit_externalClientMetadata')}</h4>
            </div>
            <ExternalClientConfigsEdit
              questions={questions}
              questionCodes={questionCodes}
              externalClientConfigs={config.externalClientConfigs}
              deleteExternalClientConfig={index => {
                const externalClientConfigs = [...config.externalClientConfigs];
                externalClientConfigs.splice(index, 1);

                setConfig({
                  ...config,
                  externalClientConfigs
                });
              }}
              editExternalClientConfig={(externalClientConfig, index) => {
                const externalClientConfigs = [...config.externalClientConfigs];

                if (index === -1) {
                  externalClientConfigs.push(externalClientConfig);
                } else {
                  externalClientConfigs[index] = { ...externalClientConfig };
                }

                setConfig({
                  ...config,
                  externalClientConfigs
                });
              }}
            />
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="submit"
                text={t('simpleModal_submit')}
                className={buttonClasses.submitButton}
              />
              <Button
                type="button"
                text={t('simpleModal_cancel')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default withTranslation(ExternalClientMetadataCustomConfig);
