import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PassportsIcon from '@material-ui/icons/ChromeReaderMode';
import SectionsIcon from '@material-ui/icons/Storage';
import IndicatorsIcon from '@material-ui/icons/CenterFocusWeak';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import clsx from 'clsx';

import { history } from '../../_helpers/store';
import withTranslation from '../_hoc/withTranslation';
import { useStyles } from '../Sidebar/SidebarStyle';
import { passportsModule } from '../../pages/PassportsPage/PassportsDucks';
import Button from '../../_ui/Button/Button';

function Sidebar({ t, isPassportChanged }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}/>
      <List>
        <ListItem
          button
          disabled={isPassportChanged}
          onClick={() => history.push('/passports')}
        >
          <ListItemIcon><PassportsIcon/></ListItemIcon>
          <ListItemText primary={t('sidebar_passports')}/>
        </ListItem>
        <ListItem
          button
          disabled={isPassportChanged}
          onClick={() => history.push('/sections')}
        >
          <ListItemIcon><SectionsIcon/></ListItemIcon>
          <ListItemText primary={t('sidebar_sections')}/>
        </ListItem>
        <ListItem
          button
          disabled={isPassportChanged}
          onClick={() => history.push('/indicators')}
        >
          <ListItemIcon><IndicatorsIcon/></ListItemIcon>
          <ListItemText primary={t('sidebar_indicators')}/>
        </ListItem>
      </List>
      <Button
        variant="contained"
        className={classes.arrow}
        onClick={() => setOpen(!open)}
        icon={open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      />
    </Drawer>
  );
}

const mapStateToProps = state => ({
  isPassportChanged: state[passportsModule].isPassportChanged
});

export default compose(
  withTranslation,
  connect(mapStateToProps)
)(Sidebar);
