import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core';
import withTranslation from "../_hoc/withTranslation";
import { logout } from "../../pages/LoginPage/LoginDucks";
import { LoginApi } from "../../_helpers/service";
import Button from "../../_ui/Button/Button";
import { ModalBody } from "./ControlRefreshTokenStyle";

const timerDuration = 600000;

class ControlRefreshToken extends React.Component {
  state = {
    diff: timerDuration,
    userActive: true,
    expiredAt: null
  };

  componentDidMount() {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt > Date.now() + 10000) {
      window.addEventListener('mousemove', this.moveListener);
      window.addEventListener('keypress', this.moveListener);
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
      this.interval = setInterval(this.checkToken, 1000);
      this.expiredInterval = setInterval(this.checkExpiredAt, 5000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.moveListener);
    window.removeEventListener('keypress', this.moveListener);
    clearInterval(this.interval);
    clearInterval(this.expiredInterval);
  };

  checkExpiredAt = () => {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt !== this.state.expiredAt) {
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
    }
  };

  moveListener = () => {
    const { diff, userActive } = this.state;
    if (diff > 60000 && !userActive) {
      this.setState({ userActive: true });
    }
  };

  checkToken = () => {
    const { diff, userActive } = this.state;
    if (diff <= 500) {
      this.props.logout();
    } else if (diff <= 60000 && userActive) {
      this.refreshToken();
    }

    if (diff > 500) {
      this.setState({ diff: diff - 1000 });
    }
  };

  refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    clearInterval(this.interval);
    try {
      if (refreshToken) {
        const response = await LoginApi.refreshUser(refreshToken);
        if (response.data.status === 'SUCCESS') {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('expiredAt', Date.now() + timerDuration);
          this.setState({ diff: timerDuration, userActive: false });
          this.interval = setInterval(this.checkToken, 1000);
        }
      }
    } catch (ignore) {}
  };

  render() {
    const { diff, userActive } = this.state;
    const { t } = this.props;
    if (diff <= 60000 && !userActive) {
      return (
        <Modal
          open={true}
        >
          <ModalBody>
            <div>
              <h2>
                {t('controlToken_expiredAt')} {Math.round(diff / 1000)} {t('controlToken_second')}
              </h2>
              <Button
                onClick={this.refreshToken}
                text={t('controlToken_stay')}
              />
            </div>
          </ModalBody>
        </Modal>
      );
    }

    return null;
  }
}

export default connect(
  null,
  { logout }
)(withTranslation(ControlRefreshToken));
