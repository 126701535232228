import React from 'react';
import { range } from 'lodash';
import withTranslation from '../../components/_hoc/withTranslation';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import {
  maxRecipients,
  PassportRecipientsEditSchema,
  fillRecipientsValues,
  recipientsValues
} from './PassportRecipientsEditValidation';

const PassportRecipientsEdit = props => {
  const {
    t,
    recipients,
    onSave,
    onClose
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={fillRecipientsValues(recipients)}
      validate={PassportRecipientsEditSchema(recipients)}
      onSubmit={values => onSave(recipientsValues(values))}
    >
      {range(maxRecipients(recipients))
        .map(index => (
          <Input
            fullWidth
            key={`email${index}`}
            name={`email${index}`}
            label={`${t('common_email')} ${index + 1}`}
          />
        ))}
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="submit"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </Form>
  );
};

export default withTranslation(PassportRecipientsEdit);
