import React, { useState, useMemo } from 'react';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon
} from '@material-ui/icons';
import { useDicts } from '../_hooks/useDict';
import StaticTable from '../../_ui/Table/StaticTable';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import { useStyles as useModalButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../../components/_hoc/withTranslation';
import DimensionsEdit from '../DimensionsEdit/DimensionsEdit';
import { makeCellRule, collectCellRulesMethods } from '../IndicatorEdit/IndicatorEditUtils';
import RulesEdit from '../RulesEdit/RulesEdit';
import { useStyles as useButtonStyles } from './CellRulesEditStyle';

const removeUnusedExcluded = (horizontalData, verticalData, excludedData) =>
  Object.keys(excludedData)
    .reduce((obj, code) => {
        if (horizontalData.includes(code) || verticalData.includes(code)) {
          return {
            ...obj,
            [code]: excludedData[code]
          };
        }
        return obj;
      },
      {}
    );

const removeUnusedCellRules = (horizontalData, verticalData, cellRules) => {
  if (horizontalData.length === 0 || verticalData.length === 0) {
    return [];
  }

  return cellRules.filter(
    ({ cellsFilter }) =>
      Object.keys(cellsFilter)
        .every(key => horizontalData.includes(key) || verticalData.includes(key))
  );
};

const validDimensions = (horizontalData, verticalData) =>
  (horizontalData.length > 0 && verticalData.length > 0) &&
  horizontalData.every(value => value !== '') &&
  verticalData.every(value => value !== '');

const unshowCellRules = showCellRules =>
  showCellRules.slice(null, -1);

const CellRulesEdit = props => {
  const {
    t, lang,
    horizontalData,
    verticalData,
    excludedData,
    cellRules,
    editCellRules
  } = props;

  const buttonClasses = useButtonStyles();
  const modalButtonClasses = useModalButtonStyles();

  const [editRules, setEditRules] = useState({ index: null, cellRules: null });

  const codes = useMemo(() => [
      ...horizontalData, ...verticalData
    ],
    [horizontalData, verticalData]
  );
  const dicts = useDicts(codes);

  const openEditRules = (index, cellRules) =>
    setEditRules({ index, cellRules });

  const closeEditRules = () =>
    setEditRules({ index: null, cellRules: null });

  const showCellRules = [
    ...cellRules,
    {
      cellsFilter: null
    }
  ];

  const columns = [
    {
      Header: '№',
      Cell: ({ original: { cellsFilter, ...cellRules }, index }) => {
        return (cellsFilter !== null) ?
          index + 1
          :
          <Button
            size="small"
            icon={<AddIcon/>}
            className={buttonClasses.addButton}
            disabled={!validDimensions(horizontalData, verticalData)}
            tooltip={t('cellRulesEdit_actionTooltipAdd')}
            onClick={event => {
              event.stopPropagation();

              const cellRules = unshowCellRules(showCellRules);
              cellRules.push(makeCellRule());

              editCellRules(horizontalData, verticalData, excludedData, cellRules);
            }}
          />;
      },
      sortable: false,
      width: 80,
      style: {
        textAlign: 'center'
      }
    },
    ...horizontalData.map(code => ({
      Header: `${code}`,
      sortable: false,
      Cell: ({ original: { cellsFilter }, index }) => {
        if (cellsFilter === null) return '';

        const selectKey = `horizontal-${code}-${index}`;

        return (
          dicts[code] &&
          <select
            style={{ width: '100%' }}
            key={selectKey}
            value={cellsFilter[code] || ''}
            onChange={event => {
              const value = event.target.value;

              const newCellsFilter = { ...cellsFilter };
              if (value) {
                newCellsFilter[code] = value;
              } else {
                delete newCellsFilter[code];
              }

              showCellRules[index] = {
                ...showCellRules[index],
                cellsFilter: newCellsFilter
              };

              editCellRules(
                horizontalData, verticalData, excludedData,
                unshowCellRules(showCellRules)
              );
            }}
          >
            {[
              <option
                key={`${selectKey}-option${dicts[code].length}`}
                value={''}
              >
                {''}
              </option>,
              ...dicts[code].map((dict, index) =>
                <option
                  key={`${selectKey}-option${index}`}
                  value={dict.code}
                >
                  {`(${dict.code}) ${dict[`${lang}_name`]}`}
                </option>
              )]}
          </select>
        );
      }
    })),
    ...verticalData.map(code => ({
      Header: `${code}`,
      sortable: false,
      Cell: ({ original: { cellsFilter }, index }) => {
        if (cellsFilter === null) return '';

        const selectKey = `vertical-${code}-${index}`;

        return (
          dicts[code] &&
          <select
            style={{ width: '100%' }}
            key={selectKey}
            value={cellsFilter[code] || ''}
            onChange={event => {
              const value = event.target.value;

              const newCellsFilter = { ...cellsFilter };
              if (value) {
                newCellsFilter[code] = value;
              } else {
                delete newCellsFilter[code];
              }

              showCellRules[index] = {
                ...showCellRules[index],
                cellsFilter: newCellsFilter
              };

              editCellRules(
                horizontalData, verticalData, excludedData,
                unshowCellRules(showCellRules)
              );
            }}
          >
            {[
              <option
                key={`${selectKey}-option${dicts[code].length}`}
                value={''}
              >
                {''}
              </option>,
              ...dicts[code].map((dict, index) =>
                <option
                  key={`${selectKey}-option${index}`}
                  value={dict.code}
                >
                  {`(${dict.code}) ${dict[`${lang}_name`]}`}
                </option>
              )]}
          </select>
        );
      }
    })),
    {
      Header: t('common_actions'),
      sortable: false,
      width: 140,
      Cell: ({ original: { cellsFilter, ...cellRules }, index }) => {
        return (
          cellsFilter &&
          <>
            <Button
              size="small"
              icon={<EditIcon/>}
              className={buttonClasses.leftButton}
              tooltip={t('cellRulesEdit_actionTooltipEdit')}
              onClick={event => {
                event.stopPropagation();

                openEditRules(index, collectCellRulesMethods(cellRules));
              }}
            />
            <Button
              size="small"
              icon={<DeleteIcon/>}
              className={buttonClasses.rightButton}
              color="secondary"
              tooltip={t('cellRulesEdit_actionTooltipDelete')}
              onClick={event => {
                event.stopPropagation();

                const cellRules = unshowCellRules(showCellRules);
                cellRules.splice(index, 1);

                editCellRules(horizontalData, verticalData, excludedData, cellRules);
              }}
            />
          </>
        );
      },
      style: {
        textAlign: 'center'
      }
    }
  ];

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <DimensionsEdit
          horizontalData={horizontalData}
          verticalData={verticalData}
          excludedData={excludedData}
          onChange={(horizontalData, verticalData, excludedData) => {
            editCellRules(
              horizontalData, verticalData,
              removeUnusedExcluded(horizontalData, verticalData, excludedData),
              removeUnusedCellRules(horizontalData, verticalData, cellRules)
            );
          }}
        />
      </div>
      <StaticTable
        withoutHeader={true}
        collapsible={false}
        filterable={false}
        columns={columns}
        data={showCellRules}
      />
      <Modal
        open={!!editRules.cellRules}
        onClose={closeEditRules}
        title={t('cellRulesEdit_flkTitle')}
      >
        <div style={{ marginTop: '15px' }}>
          <RulesEdit
            title={t('cellRulesEdit_editFLKTitle')}
            loading={false}
            rules={editRules.cellRules}
            editRule={(type, method) => {
              const { index, cellRules } = editRules;

              const ruleIndex = cellRules.findIndex(({ type: ruleType }) => ruleType === type);
              cellRules[ruleIndex].method = method;

              setEditRules({ index, cellRules });
            }}
          />
          <div className={modalButtonClasses.buttonsGroup}>
            <Button
              type="button"
              text={t('simpleModal_submit')}
              className={modalButtonClasses.submitButton}
              onClick={() => {
                const { index, cellRules } = editRules;

                showCellRules[index] = cellRules.reduce(
                  (obj, { type, method }) => ({
                    ...obj,
                    [type]: method
                  }),
                  showCellRules[index]
                );

                editCellRules(
                  horizontalData, verticalData, excludedData,
                  unshowCellRules(showCellRules)
                );

                closeEditRules();
              }}
            />
            <Button
              type="button"
              text={t('simpleModal_cancel')}
              color="secondary"
              onClick={closeEditRules}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withTranslation(CellRulesEdit);
