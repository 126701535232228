import { uniq } from 'lodash';

const initialPassport = {
  id: '',
  ru_name: '',
  kk_name: '',
  en_name: '',
  group: {},
  questions: {},
  customConfigs: [],
  allowSavingWithFailedRules: false
};

const initialPassportGroup = {
  id: '',
  ru_name: '',
  kk_name: '',
  en_name: '',
  subGroups: [],
  sections: [],
  visibleToRoles: [],
  visibilityRule: '',
  visibilityRuleMethodName: ''
};

export const makePassportSection = ({ id, ru_name, kk_name, en_name }) => ({
  ...initialPassportGroup,
  id, ru_name, kk_name, en_name
});

export const makePassport = () => ({
  ...initialPassport,
  group: {
    ...initialPassportGroup
  }
});

export const filledPassport = ({ id, ru_name, kk_name, en_name }) =>
  id && ru_name && kk_name && en_name;

export const updatePassportInfo = (passport, { id, ru_name, kk_name, en_name }) => ({
  ...passport,
  id, ru_name, kk_name, en_name,
  group: {
    ...passport.group,
    id, ru_name, kk_name, en_name
  }
});

const updatePassportSections = (sections, newSection) =>
  sections.reduce((acc, sec) => {
      const section = { ...sec };

      if (section.subGroups.length > 0) {
        section.subGroups = updatePassportSections(section.subGroups, newSection);
      }

      if (section.id === newSection.id) {
        acc.push(newSection);
      } else {
        acc.push(section);
      }

      return acc;
    },
    []
  );

export const collectSectionsQuestionCodes = sections =>
  sections.reduce((acc, section) => {
      const questionCodes = section.sections.find(obj => obj.hasOwnProperty('questionCodes'));
      if (questionCodes) {
        acc = [...acc, ...questionCodes.questionCodes];
      }

      if (section.subGroups.length > 0) {
        acc = [...acc, ...collectSectionsQuestionCodes(section.subGroups)];
      }

      return uniq(acc);
    },
    []
  );

export const gcQuestions = (sections, questions) =>
  collectSectionsQuestionCodes(sections)
    .reduce((acc, code) => {
        if (questions.hasOwnProperty(code)) {
          return {
            ...acc,
            [code]: questions[code]
          };
        }

        return acc;
      },
      {}
    );

export const updatePassportData = (passport, section, questionCodes, questions) => {
  const sections = updatePassportSections(
    passport.group.subGroups,
    {
      ...section,
      sections: [{ questionCodes }]
    }
  );

  return {
    ...passport,
    group: {
      ...passport.group,
      subGroups: sections
    },
    questions: gcQuestions(sections, questions)
  };
};

export const updatePassportRecipients = (passport, recipients) => {
  if(!recipients) return passport;

  return {
    ...passport,
    recipients
  };
};

export const hasPassportCustomConfigs = passport =>
  passport.hasOwnProperty('customConfigs') &&
  passport.customConfigs.length > 0;

export const updatePassportCustomConfigs = (passport, configs) => ({
  ...passport,
  customConfigs: configs
});
