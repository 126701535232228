import React from 'react';
import withTranslation from '../../components/_hoc/withTranslation';
import DimensionEdit from './DimensionEdit';

const DimensionsEdit = props => {
  const {
    t,
    horizontalData,
    verticalData,
    excludedData,
    onChange
  } = props;

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <DimensionEdit
          title={t('dimensionsEdit_tableDimensionsHorizontal')}
          data={horizontalData}
          excludedData={excludedData}
          onChange={(data, excludedData) => onChange(data, verticalData, excludedData)}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <DimensionEdit
          title={t('dimensionsEdit_tableDimensionsVertical')}
          data={verticalData}
          excludedData={excludedData}
          onChange={(data, excludedData) => onChange(horizontalData, data, excludedData)}
        />
      </div>
    </>
  );
};

export default withTranslation(DimensionsEdit);
