import styled from 'styled-components';

export const ModalBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50%;
    height: 50px;
    color: white;
    text-align: center;
  }
  
  & button {
    font-weight: bold;
  }
`;