import { createReducer } from 'redux-starter-kit';
import { cloneDeep } from 'lodash';
import { PassportsApi, DictionaryApi } from '../../_helpers/service';
import { Notice } from '../../utils/Notice';
import translate from '../../utils/translate';
import { arrayMove } from '../../utils/array';
import { getErrorCode, translateError } from '../../utils/apiError';
import {
  INDICATOR_STATUS,
  findValidIndex,
  isAlreadyChanged
} from '../IndicatorsEdit/IndicatorsEditUtils';
import {
  collectSectionsQuestionCodes
} from '../PassportEdit/PassportEditUtils';

/**
 * Constants
 */

export const indicatorEditModule = 'indicatorEdit';

const SET_EDIT_INDICATOR = `${indicatorEditModule}/SET_EDIT_INDICATOR`;

const LOADING = `${indicatorEditModule}/LOADING`;

const SET_COMPLEX_METADATA = `${indicatorEditModule}/SET_COMPLEX_METADATA`;

const GET_COMPLEX_METADATA_ERROR = `${indicatorEditModule}/GET_COMPLEX_METADATA_ERROR`;

const RESET_COMPLEX_METADATA = `${indicatorEditModule}/RESET_COMPLEX_METADATA`;

const SET_COMPLEX_INDICATOR = `${indicatorEditModule}/SET_COMPLEX_INDICATOR`;

const ADD_COMPLEX_INDICATOR = `${indicatorEditModule}/ADD_COMPLEX_INDICATOR`;

const UPDATE_COMPLEX_INDICATOR = `${indicatorEditModule}/UPDATE_COMPLEX_INDICATOR`;

const COPY_COMPLEX_INDICATOR = `${indicatorEditModule}/COPY_COMPLEX_INDICATOR`;

const DELETE_COMPLEX_INDICATOR = `${indicatorEditModule}/DELETE_COMPLEX_INDICATOR`;

const RESTORE_COMPLEX_INDICATOR = `${indicatorEditModule}/RESTORE_COMPLEX_INDICATOR`;

const MOVE_COMPLEX_INDICATOR = `${indicatorEditModule}/MOVE_COMPLEX_INDICATOR`;

const RESET_COMPLEX_INDICATORS = `${indicatorEditModule}/RESET_COMPLEX_INDICATORS`;

// dictionaries

const GET_DICTIONARIES = `${indicatorEditModule}/GET_DICTIONARIES`;

const GET_DICTIONARIES_ERROR = `${indicatorEditModule}/GET_DICTIONARIES_ERROR`;

const LOADING_DICTIONARIES = `${indicatorEditModule}/LOADING_DICTIONARIES`;

/**
 * Reducer
 */

const initialState = {
  editIndicator: null,
  loading: false,
  error: null,
  // complex
  complexMetadata: null,
  complexIndicators: [],
  complexIndicatorIndex: null,
  complexQuestionCodes: [],
  // dictionaries
  dictionaries: [],
  dictionariesLoading: false
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    const { loading } = action;

    state.loading = loading;
  },
  [SET_EDIT_INDICATOR]: (state, action) => {
    const { indicator } = action;

    state.editIndicator = { ...indicator };
  },
  [SET_COMPLEX_METADATA]: (state, action) => {
    const { complexMetadata } = action;

    state.complexMetadata = complexMetadata;
    state.complexIndicators = [];
    state.complexIndicatorIndex = null;
    state.complexQuestionCodes = [];
    state.error = null;

    if (complexMetadata.group.subGroups.length > 0 &&
        complexMetadata.group.subGroups[0].sections.length > 0) {
      let count = 0;

      complexMetadata.group.subGroups[0].sections[0].questionCodes
        .forEach(code => {
          state.complexIndicators.push({
            ...complexMetadata.questions[code],
            meta: {
              index: count++,
              status: INDICATOR_STATUS.ORIGINAL
            }
          });
        });
    }

    state.complexQuestionCodes = collectSectionsQuestionCodes(complexMetadata.group.subGroups);
  },
  [GET_COMPLEX_METADATA_ERROR]: (state, action) => {
    const { error } = action;

    state.complexMetadata = null;
    state.complexIndicators = [];
    state.complexQuestionCodes = [];
    state.error = error;
  },
  [RESET_COMPLEX_METADATA]: state => {
    state.complexMetadata = null;
    state.complexIndicators = [];
    state.complexIndicatorIndex = null;
    state.complexQuestionCodes = [];
  },
  [SET_COMPLEX_INDICATOR]: (state, action) => {
    const { index } = action;

    state.complexIndicatorIndex = index;
  },
  [ADD_COMPLEX_INDICATOR]: (state, action) => {
    const { indicator } = action;

    const prevIndex = findValidIndex(state.complexIndicators, state.complexIndicators.length - 1, -1);

    state.complexIndicators.push({
      ...indicator,
      meta: {
        index: prevIndex >= 0 ? state.complexIndicators[prevIndex].meta.index + 1 : 0,
        status: INDICATOR_STATUS.ADDED
      }
    });

    state.complexQuestionCodes.push(indicator.code);

    const deletedIndex = state.complexIndicators.findIndex(
      ({code, meta: {status}}) =>
        status === INDICATOR_STATUS.DELETED &&
        code.toLowerCase() === indicator.code.toLowerCase()
    );
    if (deletedIndex > -1) {
      state.complexIndicators.splice(deletedIndex, 1);
    }
  },
  [UPDATE_COMPLEX_INDICATOR]: (state, action) => {
    const { index, indicator } = action;
    const oldCode = state.complexIndicators[index].code;

    state.complexIndicators[index] = {
      ...indicator,
      meta: {
        ...indicator.meta,
        status: isAlreadyChanged(state.complexIndicators[index].meta.status, [INDICATOR_STATUS.ADDED]) ?
          INDICATOR_STATUS.ADDED :
          INDICATOR_STATUS.UPDATED
      }
    };

    if (oldCode.toLowerCase() !== indicator.code.toLowerCase()) {
      const codeIndex = state.complexQuestionCodes.indexOf(oldCode);
      if (codeIndex > -1) {
        state.complexQuestionCodes.splice(codeIndex, 1);
      }
      state.complexQuestionCodes.push(indicator.code);

      const deletedIndex = state.complexIndicators.findIndex(
        ({code, meta: {status}}) =>
          status === INDICATOR_STATUS.DELETED &&
          code.toLowerCase() === indicator.code.toLowerCase()
      );
      if (deletedIndex > -1) {
        state.complexIndicators.splice(deletedIndex, 1);

        if(deletedIndex < index) {
          state.complexIndicatorIndex--;
        }
      }
    }
  },
  [COPY_COMPLEX_INDICATOR]: (state, action) => {
    const { index } = action;
    const originalIndicator = state.complexIndicators[index];
    const copyIndicator = cloneDeep(originalIndicator);

    copyIndicator.code = chooseCopyCode(state.complexIndicators, originalIndicator.code);
    copyIndicator.meta.status = INDICATOR_STATUS.ADDED;
    copyIndicator.meta.index++;

    state.complexIndicators.splice(index + 1, 0, copyIndicator);

    if ((index + 2) < state.complexIndicators.length) {
      for (let i = index + 2; i < state.complexIndicators.length; i++) {
        if (state.complexIndicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.complexIndicators[i].meta.index++;
        }
      }
    }

    state.complexQuestionCodes.push(copyIndicator.code);
  },
  [DELETE_COMPLEX_INDICATOR]: (state, action) => {
    const { index } = action;

    state.complexIndicators[index].meta.status = INDICATOR_STATUS.DELETED;

    if ((index + 1) < state.complexIndicators.length) {
      for (let i = index + 1; i < state.complexIndicators.length; i++) {
        if (state.complexIndicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.complexIndicators[i].meta.index--;
        }
      }
    }

    const codeIndex = state.complexQuestionCodes.indexOf(state.complexIndicators[index].code);
    if (codeIndex > -1) {
      state.complexQuestionCodes.splice(codeIndex, 1);
    }
  },
  [RESTORE_COMPLEX_INDICATOR]: (state, action) => {
    const { index } = action;

    let metaIndex = 0;

    for (let i = index - 1; i >= 0; i--) {
      if (state.complexIndicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
        metaIndex = state.complexIndicators[i].meta.index + 1;
        break;
      }
    }

    state.complexIndicators[index].meta.status = INDICATOR_STATUS.UPDATED;
    state.complexIndicators[index].meta.index = metaIndex;

    if ((index + 1) < state.complexIndicators.length) {
      for (let i = index + 1; i < state.complexIndicators.length; i++) {
        if (state.complexIndicators[i].meta.status !== INDICATOR_STATUS.DELETED) {
          state.complexIndicators[i].meta.index++;
        }
      }
    }

    state.complexQuestionCodes.push(state.complexIndicators[index].code);
  },
  [MOVE_COMPLEX_INDICATOR]: (state, action) => {
    const { index, direction } = action;

    const swapIndex = findValidIndex(
      state.complexIndicators,
      direction === 'UP' ? index - 1 : index + 1,
      direction === 'UP' ? -1 : +1
    );

    if (swapIndex === -1) return state;

    const metaIndex = state.complexIndicators[swapIndex].meta.index;

    state.complexIndicators[swapIndex].meta.index = state.complexIndicators[index].meta.index;
    if (!isAlreadyChanged(
      state.complexIndicators[swapIndex].meta.status,
      [INDICATOR_STATUS.ADDED, INDICATOR_STATUS.UPDATED, INDICATOR_STATUS.DELETED]
    )) {
      state.complexIndicators[swapIndex].meta.status = INDICATOR_STATUS.MOVED;
    }

    state.complexIndicators[index].meta.index = metaIndex;
    if (!isAlreadyChanged(
      state.complexIndicators[index].meta.status,
      [INDICATOR_STATUS.ADDED, INDICATOR_STATUS.UPDATED, INDICATOR_STATUS.DELETED]
    )) {
      state.complexIndicators[index].meta.status = INDICATOR_STATUS.MOVED;
    }

    state.complexIndicators = arrayMove(state.complexIndicators, index, swapIndex);
  },
  [RESET_COMPLEX_INDICATORS]: state => {
    const { dictionaries, dictionariesLoading } = state;

    return {
      ...initialState,
      dictionaries,
      dictionariesLoading
    };
  },
  // dictionaries
  [GET_DICTIONARIES]: (state, action) => {
    state.dictionaries = action.dictionaries.sort(
      (a, b) => {
        const codeA = a.code.toUpperCase();
        const codeB = b.code.toUpperCase();
        if (codeA < codeB) {
          return -1;
        }
        if (codeA > codeB) {
          return 1;
        }
        return 0;
      }
    );
  },
  [GET_DICTIONARIES_ERROR]: state => {
    state.dictionaries = [];
  },
  [LOADING_DICTIONARIES]: (state, action) => {
    state.dictionariesLoading = action.loading;
  }
});

/**
 * Actions
 */

export const setEditIndicator = indicator => async dispatch => {
  dispatch({ type: SET_EDIT_INDICATOR, indicator });
};

export const setComplexMetadata = complexMetadata => async dispatch => {
  dispatch({ type: SET_COMPLEX_METADATA, complexMetadata });
};

export const getComplexMetadata = metadataKey => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });

    const complexMetadata = (await PassportsApi.getMetadata(metadataKey)).data;

    dispatch({ type: SET_COMPLEX_METADATA, complexMetadata });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'indicatorEditDucks_getComplexIndicatorsError'));

    dispatch({ type: GET_COMPLEX_METADATA_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const resetComplexMetadata = () => async dispatch => {
  dispatch({ type: RESET_COMPLEX_METADATA });
};

export const setComplexIndicator = index => async dispatch => {
  dispatch({ type: SET_COMPLEX_INDICATOR, index });
};

export const addComplexIndicator = (indicator, onSuccess) => (dispatch, getState) => {
  const questionCodes = getState().indicatorEdit.complexQuestionCodes;

  if (questionCodes.find(code => code.toLowerCase() === indicator.code.toLowerCase())) {
    Notice.error(translate('indicatorEditDucks_INDICATOR_COMPLEX_EXISTS'));

    return;
  }

  dispatch({ type: ADD_COMPLEX_INDICATOR, indicator });

  onSuccess();
};

export const updateComplexIndicator = (indicator, index, onSuccess) => (dispatch, getState) => {
  const indicators = getState().indicatorEdit.complexIndicators;

  if (indicators[index].code !== indicator.code) {
    const questionCodes = getState().indicatorEdit.complexQuestionCodes;

    if (questionCodes.find(code => code.toLowerCase() === indicator.code.toLowerCase())) {
      Notice.error(translate('indicatorEditDucks_INDICATOR_EXISTS'));

      return;
    }
  }

  dispatch({ type: UPDATE_COMPLEX_INDICATOR, indicator, index });

  onSuccess();
};

export const copyComplexIndicator = index => async dispatch => {
  dispatch({ type: COPY_COMPLEX_INDICATOR, index });
};

export const deleteComplexIndicator = index => async dispatch => {
  dispatch({ type: DELETE_COMPLEX_INDICATOR, index });
};

export const restoreComplexIndicator = index => async dispatch => {
  dispatch({ type: RESTORE_COMPLEX_INDICATOR, index });
};

export const moveComplexIndicator = (direction, index) => async dispatch => {
  dispatch({ type: MOVE_COMPLEX_INDICATOR, direction, index });
};

export const resetComplexIndicators = () => async dispatch => {
  dispatch({ type: RESET_COMPLEX_INDICATORS });
};

export const getDictionaries = () => async dispatch => {
  try {
    dispatch({ type: LOADING_DICTIONARIES, loading: true });

    const data = (await DictionaryApi.getDictionaries()).data;

    if (data.status === 'SUCCESS') {
      dispatch({ type: GET_DICTIONARIES, dictionaries: data.result });
    } else {
      Notice.error(translate('indicatorEditDucks_getDictionariesError'));

      dispatch({ type: GET_DICTIONARIES_ERROR });
    }
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'indicatorEditDucks_getDictionariesError'));

    dispatch({ type: GET_DICTIONARIES_ERROR });
  } finally {
    dispatch({ type: LOADING_DICTIONARIES, loading: false });
  }
};

/**
 * Utils
 */

const chooseCopyCode = (indicators, code) => {
  const checkIfExists = copyId =>
    !!indicators.find(({ code: exCode }) => exCode === `${code}_${copyId}`);

  let copyId = 1;

  while (true) {
    if (!checkIfExists(copyId))
      break;

    copyId++;
  }

  return `${code}_${copyId}`;
};
