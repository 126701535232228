import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  leftButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  middleButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  rightButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}));

export const IndicatorStatus = {
  original: styled.span`
  `,
  added: styled.span`
    color: #428f49;
    font-weight: bold;
  `,
  deleted: styled.span`
    color: #d25441;
    text-decoration: line-through;
    font-weight: bold;
  `,
  updated: styled.span`
    color: #8248d6;
    font-weight: bold;
  `,
  moved: styled.span`
    color: #1a7cbf;
    font-weight: bold;
  `
};