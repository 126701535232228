import * as Yup from 'yup';
import translate from '../../../utils/translate';

export const EsMetadataCustomConfigSchema = Yup.object().shape({
  '@type': Yup.string(),
  id: Yup.string(),
  assigneeGroupNameTemplate:
    Yup.string(() => translate('passportCustomConfigsEditValidation_assigneeGroupNameTemplateHint'))
      .required(() => translate('passportCustomConfigsEditValidation_assigneeGroupNameTemplateRequired')),
  sendIntermediateNotifications:
    Yup.boolean(() => translate('passportCustomConfigsEditValidation_sendIntermediateNotificationsHint'))
});
