import React, { useState } from 'react';
import withTranslation from '../../components/_hoc/withTranslation';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import Checkbox from '../../_ui/Checkbox/Checkbox';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import FlexContainer from '../../_styles/FlexContainer';
import { Permissions } from '../../_helpers/Permissions';
import Access from '../../components/Access';
import { PassportSaveSchema } from './PassportSaveValidation';

const PassportSave = props => {
  const {
    t,
    message,
    loading,
    onClose,
    onSave,
    onReset
  } = props;

  const [currentVersion, setCurrentVersion] = useState(false);

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={{
        commit_comment: ''
      }}
      validate={PassportSaveSchema}
      onSubmit={values => {
        onSave(currentVersion, values.commit_comment)
      }}
    >
      <p>{message || t('passportSave_message')}</p>
      <Input
        fullWidth
        multiline
        rows="3"
        name="commit_comment"
        label={t('common_commitComment')}
      />
      <Checkbox
        fullWidth
        withoutForm
        label={t('passportSave_currentVersion')}
        value={currentVersion}
        onChange={() => setCurrentVersion(value => !value)}
      />
      <Access permissions={[Permissions.savePassport.code]}>
        <div className={buttonClasses.buttonsGroup}>
          <FlexContainer
            justifySpaceBetween
            itemsCenter
          >
            <Button
              type="submit"
              text={t('passportSave_save')}
              loading={loading}
            />
            <Button
              type="button"
              text={t('passportSave_reset')}
              color="secondary"
              disabled={loading}
              onClick={onReset}
            />
            <Button
              type="button"
              text={t('simpleModal_close')}
              color="secondary"
              onClick={onClose}
            />
          </FlexContainer>
        </div>
      </Access>
    </Form>
  );
};

export default withTranslation(PassportSave);
