import React, { useState, useMemo } from 'react';
import Select from '../../_ui/Select/Select';
import withTranslation from '../../components/_hoc/withTranslation';

const ExternalIndicators = props => {
  const {
    t, lang,
    excludePassport,
    passportsInfo,
    passportsLoading,
    passport,
    getPassport,
    setPassport,
    onSelectCode
  } = props;

  const [indicatorCode, setIndicatorCode] = useState(null);

  const passportOptions = useMemo(() => {
      return passportsInfo
        .filter(passport => passport.id !== excludePassport)
        .map(passport => ({ label: passport.id, value: passport.id }));
    },
    [excludePassport, passportsInfo]
  );

  const indicatorCodeOptions = useMemo(() => {
      if (!passport) return [];

      return Object.keys(passport.questions)
        .map(key => {
          const indicator = passport.questions[key];

          return {
            label: `[${indicator.code}] ${indicator[`${lang}_name`]}`,
            value: indicator.code
          };
        });
    },
    [lang, passport]
  );

  return (
    <div style={{ height: '450px' }}>
      <Select
        withoutForm
        fullWidth
        searchable={true}
        label={t('externalIndicators_choosePassport')}
        loading={passportsLoading}
        options={passportOptions}
        value={passport && passport.id}
        onChange={name => {
          if (name) {
            getPassport(name);
          } else {
            setPassport(null);
          }
          setIndicatorCode(null);
        }}
      />
      <Select
        withoutForm
        fullWidth
        searchable={true}
        label={t('externalIndicators_chooseIndicator')}
        loading={passportsLoading}
        options={indicatorCodeOptions}
        value={indicatorCode}
        disabled={!!!passport}
        onChange={code => {
          setIndicatorCode(code || null);
          if (code) {
            onSelectCode(code);
          }
        }}
      />
    </div>
  );
};

export default withTranslation(ExternalIndicators);
