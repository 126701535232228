import React, { useState } from 'react';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Undo as UndoIcon,
  FileCopy as CopyIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@material-ui/icons';
import Button from '../../_ui/Button/Button';
import IconButton from '../../_ui/Button/IconButton';
import StaticTable from '../../_ui/Table/StaticTable';
import { Permissions } from '../../_helpers/Permissions';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import { INDICATOR_STATUS } from '../IndicatorsEdit/IndicatorsEditUtils';
import { useStyles, IndicatorStatus } from './IndicatorsEditStyle';

const colorizeChanges = (status, text) => {
  switch (status) {
    case INDICATOR_STATUS.ADDED:
      return <IndicatorStatus.added>{text}</IndicatorStatus.added>;

    case INDICATOR_STATUS.DELETED:
      return <IndicatorStatus.deleted>{text}</IndicatorStatus.deleted>;

    case INDICATOR_STATUS.UPDATED:
      return <IndicatorStatus.updated>{text}</IndicatorStatus.updated>;

    case INDICATOR_STATUS.MOVED:
      return <IndicatorStatus.moved>{text}</IndicatorStatus.moved>;

    case INDICATOR_STATUS.ORIGINAL:
    default:
  }

  return <IndicatorStatus.original>{text}</IndicatorStatus.original>;
};

const IndicatorsEdit = props => {
  const {
    t,
    loading,
    indicators,
    moveIndicator,
    editIndicator,
    copyIndicator,
    deleteIndicator,
    restoreIndicator
  } = props;

  const classes = useStyles();

  const [descMetaIndex, setDescMetaIndex] = useState(false);

  const onSortedMetaIndex = (newSorted, column) => {
    if (column.id === 'meta') {
      const data = newSorted.find(({ id }) => id === 'meta');
      if (data) {
        setDescMetaIndex(data.desc === true);
      }
    }
  };

  const indicatorsColumns = [
    {
      Header: t('indicatorShow_columnOrder'),
      filterable: true,
      accessor: 'meta',
      Cell: ({ index, original: { meta: { index: metaIndex, status: metaStatus } } }) =>
        <>
          {colorizeChanges(metaStatus, metaIndex + 1)}
          &nbsp;&nbsp;
          <Access permissions={[Permissions.sortIndicators.code]}>
            <IconButton
              size="small"
              icon={<ArrowUpwardIcon/>}
              disabled={INDICATOR_STATUS.DELETED === metaStatus}
              onClick={event => {
                event.stopPropagation();
                moveIndicator(!descMetaIndex ? 'UP' : 'DOWN', index);
              }}
            />
            <IconButton
              size="small"
              icon={<ArrowDownwardIcon/>}
              disabled={INDICATOR_STATUS.DELETED === metaStatus}
              onClick={event => {
                event.stopPropagation();
                moveIndicator(!descMetaIndex ? 'DOWN' : 'UP', index);
              }}
            />
          </Access>
        </>,
      sortMethod: (a, b) => {
        if (a.index > b.index) {
          return 1;
        }
        if (a.index < b.index) {
          return -1;
        }
        return 0;
      },
      filterMethod: (filter, row) => {
        return String(row.meta.index + 1).startsWith(filter.value);
      },
      style: {
        textAlign: 'center'
      }
    },
    {
      Header: t('common_code'),
      filterable: true,
      accessor: 'code',
      Cell: ({ original: { code, meta: { status: metaStatus } } }) =>
        colorizeChanges(metaStatus, code)
    },
    {
      Header: t('common_type'),
      filterable: true,
      accessor: 'type',
      Cell: ({ original: { type, meta: { status: metaStatus } } }) =>
        colorizeChanges(metaStatus, type),
      width: 140
    },
    {
      Header: t('common_kkName'),
      filterable: true,
      accessor: 'kk_name',
      Cell: ({ original: { type, kk_name, meta: { status: metaStatus } } }) =>
        colorizeChanges(
          metaStatus,
          type === 'external' ? t('common_kkExternalName') : kk_name
        )
    },
    {
      Header: t('common_ruName'),
      filterable: true,
      accessor: 'ru_name',
      Cell: ({ original: { type, ru_name, meta: { status: metaStatus } } }) =>
        colorizeChanges(
          metaStatus,
          type === 'external' ? t('common_ruExternalName') : ru_name
        )
    },
    {
      Header: t('common_enName'),
      filterable: true,
      accessor: 'en_name',
      Cell: ({ original: { type, en_name, meta: { status: metaStatus } } }) =>
        colorizeChanges(
          metaStatus,
          type === 'external' ? t('common_enExternalName') : en_name
        )
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ index, original: { type, meta: { status: metaStatus } } }) =>
        <>
          <Access permissions={[Permissions.editIndicator.code]}>
            <Button
              size="small"
              icon={<EditIcon/>}
              className={classes.leftButton}
              disabled={INDICATOR_STATUS.DELETED === metaStatus || type === 'external'}
              tooltip={t('indicatorsPage_actionTooltipEdit')}
              onClick={event => {
                event.stopPropagation();
                editIndicator(index);
              }}
            />
          </Access>
          <Access permissions={[Permissions.copyIndicator.code]}>
            <Button
              size="small"
              icon={<CopyIcon/>}
              className={classes.middleButton}
              disabled={INDICATOR_STATUS.DELETED === metaStatus || type === 'external'}
              tooltip={t('indicatorsPage_actionTooltipCopy')}
              onClick={event => {
                event.stopPropagation();
                copyIndicator(index);
              }}
            />
          </Access>
          <Access permissions={[Permissions.deleteIndicator.code]}>
            {INDICATOR_STATUS.DELETED === metaStatus ?
              <Button
                size="small"
                style={{ backgroundColor: '#009688' }}
                icon={<UndoIcon/>}
                className={classes.rightButton}
                tooltip={t('indicatorsPage_actionTooltipRestore')}
                onClick={event => {
                  event.stopPropagation();
                  restoreIndicator(index);
                }}
              /> :
              <Button
                size="small"
                icon={<DeleteIcon/>}
                color={'secondary'}
                className={classes.rightButton}
                tooltip={t('indicatorsPage_actionTooltipDelete')}
                onClick={event => {
                  event.stopPropagation();
                  deleteIndicator(index);
                }}
              />
            }
          </Access>
        </>,
      style: {
        textAlign: 'center'
      },
      width: 208
    }
  ];

  return (
    <StaticTable
      withoutHeader
      collapsible={false}
      filterable={false}
      loading={loading}
      columns={indicatorsColumns}
      data={indicators}
      onSortedChange={onSortedMetaIndex}
    />
  );
};

export default withTranslation(IndicatorsEdit);
