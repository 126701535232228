import React from 'react';
import ReactTable from 'react-table';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import withTranslation from '../../components/_hoc/withTranslation';
import Loading from '../Loading/CircularLoading';
import { Div, LoadingBox } from './TableStyle';

const ReactTableWrapper = props => {
  const {
    loading,
    data,
    columns,
    onClickRow,
    pageSize,
    markText,
    t,
    ...restProps
  } = props;

  const getMarkedText = currentText => {
    if (markText && currentText) {
      currentText = String(currentText);
      let arrText = currentText.split(markText);
      return arrText.map((text, index) => {
        if (index !== arrText.length - 1) {
          return (
            <span key={index}>
              {text}
              <span style={{ backgroundColor: 'yellow' }}>{markText}</span>
            </span>
          );
        } else {
          return <span key={index}>{text}</span>;
        }
      });
    }
    return <span>{currentText}</span>;
  };

  const getColumns = () => {
    let newColumns = [];

    for (let column of columns) {
      let newColumn = {
        Cell: row => getMarkedText(row.original[column.accessor]),
        ...column,
        Header: () => (
          <div>
            {column.Header}
            {column.sortable !== false && (
              <span className="rt-sort">
                <div style={{ marginBottom: -10 }}>
                  <ArrowDropUpIcon/>
                </div>
                <div>
                  <ArrowDropDownIcon/>
                </div>
              </span>
            )}
          </div>
        )
      };
      if (typeof column.accessor === 'function') {
        delete newColumn.Cell;
        newColumn.accessor = row => {
          let text = column.accessor(row);
          return getMarkedText(text);
        };
      }
      newColumns.push(newColumn);
    }
    return newColumns;
  };

  return (
    <Div>
      <ReactTable
        NoDataComponent={() => {
          if (loading) {
            return null;
          } else {
            return (
              <LoadingBox className="-loading -active">
                <div className="-loading-inner">{t('reactTableWrapper_selectNoOptions')}</div>
              </LoadingBox>
            );
          }
        }}
        LoadingComponent={data => {
          if (!data.loading) return null;
          return (
            <LoadingBox className="-loading -active">
              <div className="-loading-inner">
                <Loading/>
              </div>
            </LoadingBox>
          );
        }}
        getTrProps={(restProps, object) => {
          return {
            onClick() {
              if (typeof onClickRow === 'function') {
                onClickRow(object);
              }
            },
            style: {
              cursor: typeof onClickRow === 'function' ? 'pointer' : 'default'
            }
          };
        }}
        showPaginationBottom={false}
        data={data}
        pageSize={pageSize}
        defaultFilterMethod={(filter, row) => {
          return (
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        }}
        columns={getColumns()}
        className="-striped -highlight"
        loading={loading}
        {...restProps}
      />
    </Div>
  );
};

export default withTranslation(ReactTableWrapper);
