import styled, { css } from 'styled-components';

export const Title = styled.div`
  ${props => props.isRemoved &&
    css`
      color: red !important;
      text-decoration: line-through !important;
    `
  }
  ${props => (props.isEdited || props.isAdded) &&
    css`
      color: #1a7cbf;
    `
  }
`;