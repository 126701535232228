import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '../Button/IconButton';
import { useStyles } from './ModalStyle';

const JustModal = props => {
  const {
    title,
    open, onClose,
    enableBackdropClick,
    enableEscapeKeyDown,
    children
  } = props;

  const classes = useStyles(props)();

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={onClose}
      disableBackdropClick={!enableBackdropClick}
      disableEscapeKeyDown={!enableEscapeKeyDown}
    >
      <div className={classes.paper}>
        {onClose &&
        <IconButton
          color="secondary"
          icon={<CancelIcon/>}
          className={classes.closeIcon}
          onClick={onClose}
        />}
        <Typography variant="h6" id="modal-title">
          {title}
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          {children}
        </Typography>
      </div>
    </Modal>
  );
};

JustModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  enableBackdropClick: PropTypes.bool,
  enableEscapeKeyDown: PropTypes.bool
};

JustModal.defaultProps = {
  open: false,
  title: 'Title',
  enableBackdropClick: false,
  enableEscapeKeyDown: false
};

export default JustModal;