export const INDICATOR_TYPES = [
  'text',
  'number',
  'yes/no',
  'date',
  'dynamic lookup',
  'multiple dynamic lookup',
  'complex',
  'table',
  'document'
];

export const COMPLEX_INDICATOR_TYPES = [
  'text',
  'number',
  'yes/no',
  'date',
  'dynamic lookup',
  'multiple dynamic lookup',
  'document'
];

export const TABLE_CELL_TYPES = [
  'text',
  'number',
  'yes/no',
  'date',
  'dynamic lookup',
  'multiple dynamic lookup'
];

export const indicatorTypeToRenderer = type => {
  switch (type) {
    case 'text':
      return ['long text', 'short text'];

    case 'number':
      return ['numeric text'];

    case 'yes/no':
      return ['radio button', 'check box'];

    case 'date':
      return ['date', 'date time'];

    case 'dynamic lookup':
      return ['dropdown', 'dropdown_tree'];

    case 'multiple dynamic lookup':
      return ['checkbox group', 'dropdown_tree'];

    case 'complex':
      return ['complex table', 'complex inline'];

    case 'table':
      return ['table'];

    case 'document':
      return ['document'];

    default:
  }

  throw new Error(`Invalid indicator type: ${type}`);
};

export const allowsIndicatorTypeRecurrent = type => {
  switch (type) {
    case 'text':
    case 'number':
    case 'date':
    case 'yes/no':
    case 'dynamic lookup':
    case 'multiple dynamic lookup':
    case 'complex':
    case 'table':
    case 'document':
      return true;

    default:
  }

  return false;
};

const COMMON_RULES = ['visibilityRule', 'readOnlyRule', 'requiredRule', 'validationRule'];

const indicatorTypeToValidRules = type => {
  switch (type) {
    case 'text':
    case 'number':
    case 'date':
      return [
        ...COMMON_RULES,
        'itemVisibilityRule',
        'calculationRule',
        'inputRestrictionRule'
      ];

    case 'complex':
      return [...COMMON_RULES, 'inputRestrictionRule', 'rowAddRule', 'rowDeletionRule'];

    case 'table':
    case 'document':
      return COMMON_RULES;

    case 'cells':
      return [
        'cellsReadOnlyRule',
        'cellsRequiredRule',
        'cellsValidationRule',
        'cellsCalculationRule',
        'cellsInputRestrictionRule',
        'cellsVisibilityRule',
        'cellsFilterRule'
      ];

    default:
      break;
  }

  return [...COMMON_RULES, 'itemVisibilityRule', 'calculationRule'];
};

const removeAllRules = indicator => {
  return Object.keys(indicator).reduce((obj, key) => {
    if (key.endsWith('Rule') || key.endsWith('RuleMethodName')) {
      return obj;
    }

    return {
      ...obj,
      [key]: indicator[key]
    };
  }, {});
};

const initialIndicator = {
  code: '',
  ru_name: '',
  kk_name: '',
  en_name: '',
  type: '',
  recurrent: false,
  renderer: '',
  visibleToRoles: [],
  config: {},
  readOnly: false,
  hasDefaultValue: false,
  alwaysRequired: false,
  relativeQuestions: [],
  cellRules: [],
  keepIfHidden: false,
  static: false,
  customConfigs: []
};

const externalIndicator = {
  code: '',
  type: 'external'
};

export const isExternalIndicator = indicator => indicator.type === 'external';

const baseIndicatorConfig = (type, renderer) => ({
  type: type,
  recurrent: false,
  renderer: {
    id: renderer
  }
});

const initialIndicatorConfig = (type, renderer) => {
  switch (type) {
    case 'text':
      return {
        ...baseIndicatorConfig(type, renderer)
      };

    case 'number':
      return {
        ...baseIndicatorConfig(type, renderer)
      };

    case 'yes/no':
      return {
        ...baseIndicatorConfig(type, renderer)
      };

    case 'date':
      return {
        ...baseIndicatorConfig(type, renderer)
      };

    case 'dynamic lookup':
      return {
        ...baseIndicatorConfig(type, renderer),
        anyTreeLevelSelectable: false,
        multiple: false,
        lookupId: ''
      };

    case 'multiple dynamic lookup':
      return {
        ...baseIndicatorConfig(type, renderer),
        multiple: true,
        lookupId: ''
      };

    case 'complex':
      return {
        ...baseIndicatorConfig(type, renderer),
        recurrent: true,
        metadataKey: '',
        pageSize: 0
      };

    case 'table':
      return {
        ...baseIndicatorConfig(type, renderer),
        horizontalDomainDatas: [],
        verticalDomainDatas: [],
        excludedDomainItems: {},
        cellType: {
          recurrent: false,
          type: 'number',
          renderer: {
            id: indicatorTypeToRenderer('number')[0]
          }
        }
      };

    case 'document':
      return {
        ...baseIndicatorConfig(type, renderer),
        metadataKey: ''
      };

    default:
      throw new Error(`Invalid indicator config type: ${type}`);
  }
};

export const addMetadataKeyPrefix = (metadataKey, type) => {
  if (type === 'complex' && !metadataKey.startsWith('dynamic_table_')) {
    return `dynamic_table_${metadataKey}`;
  }

  if (type === 'document' && !metadataKey.startsWith('doc_')) {
    return `doc_${metadataKey}`;
  }

  return metadataKey;
};

const removeMetadataKeyPrefix = (metadataKey, type) => {
  if (type === 'complex' && metadataKey.startsWith('dynamic_table_')) {
    return metadataKey.replace('dynamic_table_', '');
  }

  if (type === 'document' && metadataKey.startsWith('doc_')) {
    return metadataKey.replace('doc_', '');
  }

  return metadataKey;
};

export const extractConfigFields = (indicator, complexMetadata) => {
  const { type, config } = indicator;

  switch (type) {
    case 'dynamic lookup':
      return {
        ...indicator,
        '#config#anyTreeLevelSelectable': config.anyTreeLevelSelectable,
        '#config#lookupId': config.lookupId
      };

    case 'multiple dynamic lookup':
      return {
        ...indicator,
        '#config#lookupId': config.lookupId
      };

    case 'document':
      return {
        ...indicator,
        '#config#metadataKey': removeMetadataKeyPrefix(config.metadataKey, type)
      };

    case 'complex':
      return {
        ...indicator,
        '#config#metadataKey':
          indicator['#config#metadataKey'] ||
          removeMetadataKeyPrefix(config.metadataKey, type),
        '#config#ru_name':
          indicator['#config#ru_name'] ||
          (complexMetadata && complexMetadata.ru_name) ||
          '',
        '#config#kk_name':
          indicator['#config#kk_name'] ||
          (complexMetadata && complexMetadata.kk_name) ||
          '',
        '#config#en_name':
          indicator['#config#en_name'] ||
          (complexMetadata && complexMetadata.en_name) ||
          '',
        '#config#pageSize': config.pageSize || 0
      };

    case 'table':
      return {
        ...indicator,
        '#cellType#recurrent': config.cellType.recurrent,
        '#cellType#type': config.cellType.type,
        '#cellType#renderer#id': config.cellType.renderer.id
      };

    default:
      break;
  }

  return indicator;
};

const injectConfigFieldsByType = indicator => {
  switch (indicator.type) {
    case 'dynamic lookup':
      return {
        anyTreeLevelSelectable: indicator['#config#anyTreeLevelSelectable'],
        lookupId: indicator['#config#lookupId']
      };

    case 'multiple dynamic lookup':
      return {
        lookupId: indicator['#config#lookupId']
      };

    case 'complex':
      return {
        metadataKey: indicator['#config#metadataKey'],
        pageSize: parseInt(indicator['#config#pageSize'])
      };

    case 'document':
      return {
        metadataKey: indicator['#config#metadataKey']
      };

    case 'table':
      return {
        cellType: {
          recurrent: indicator['#cellType#recurrent'],
          type: indicator['#cellType#type'],
          renderer: {
            id: indicator['#cellType#renderer#id']
          }
        }
      };

    default:
      break;
  }

  return {};
};

export const injectConfigFields = indicator => ({
  ...indicator,
  config: {
    ...indicator.config,
    ...injectConfigFieldsByType(indicator)
  }
});

export const injectComplexMetadataFields = (indicator, complexMetadata) => {
  const [ru_name, kk_name, en_name] = [
    indicator['#config#ru_name'],
    indicator['#config#kk_name'],
    indicator['#config#en_name']
  ];

  return {
    ...complexMetadata,
    ru_name,
    kk_name,
    en_name
  };
};

export const makeIndicator = (type = 'text') => updateIndicator(initialIndicator, type);

export const makeExternalIndicator = code => ({
  ...externalIndicator,
  code
});

export const updateIndicator = (indicator, type = null) => {
  if (type) {
    return {
      ...removeAllRules(indicator),
      ...indicatorTypeToValidRules(type).reduce(
        (obj, ruleName) => ({
          ...obj,
          [ruleName]: '',
          [`${ruleName}MethodName`]: ''
        }),
        {}
      ),
      type,
      renderer: indicatorTypeToRenderer(type)[0],
      recurrent: type === 'complex',
      config: {
        ...initialIndicatorConfig(type, indicatorTypeToRenderer(type)[0])
      },
      cellRules: []
    };
  }

  const { config, renderer, recurrent } = indicator;

  return {
    ...indicator,
    config: {
      ...config,
      recurrent,
      renderer: {
        ...config.renderer,
        id: renderer
      }
    }
  };
};

export const collectIndicatorRuleMethods = indicator => {
  const validRules = indicatorTypeToValidRules(indicator.type);

  return validRules.map(name => ({
    type: name,
    method: indicator[name] || ''
  }));
};

export const makeCellRule = () => ({
  cellsFilter: {},
  ...indicatorTypeToValidRules('cells').reduce(
    (obj, ruleName) => ({
      ...obj,
      [ruleName]: '',
      [`${ruleName}MethodName`]: ''
    }),
    {}
  )
});

export const collectCellRulesMethods = cellRules => {
  const validRules = indicatorTypeToValidRules('cells');

  return validRules.map(name => ({
    type: name,
    method: cellRules[name] || ''
  }));
};
