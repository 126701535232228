export const RU_TRANSLATION = {
  lang: 'ru',
  messages: {
    // common messages
    common_id: 'ID',
    common_code: 'Код показателя',
    common_type: 'Тип показателя',
    common_kkName: 'Наименование на казахском',
    common_ruName: 'Наименование на русском',
    common_enName: 'Наименование на английском',
    common_actions: 'Действия',
    common_required: 'Это поле обязательное для заполнения',
    common_kkExternalName: 'Внешний показатель',
    common_ruExternalName: 'Внешний показатель',
    common_enExternalName: 'Внешний показатель',
    common_commitComment: 'Описание изменений',
    common_email: 'Почтовый адрес',

    // UI components
    select_placeholder: 'Выберите ...',
    select_noOptionsMessage: 'Нет данных',

    reactTableWrapper_selectNoOptions: 'Нет данных',
    staticTable_collapse: 'Свернуть таблицу',
    staticTable_expand: 'Развернуть таблицу',
    staticTable_load: 'Загрузить еще',

    simpleModal_submit: 'ОК',
    simpleModal_cancel: 'Отмена',
    simpleModal_close: 'Закрыть',

    // components
    header_login: 'Войти',
    header_logout: 'Выйти',
    headerMenu_profile: 'Профиль',
    headerMenu_settings: 'Настройки',

    sidebar_passports: 'Паспорта',
    sidebar_sections: 'Разделы',
    sidebar_indicators: 'Показатели',

    sectionsTree_edit: 'Редактировать',
    sectionsTree_addSection: 'Добавить раздел',
    sectionsTree_addSubSection: 'Добавить подраздел',
    sectionsTree_remove: 'Удалить',
    sectionsTree_removeSection: 'Чтобы удалить раздел необходимо ввести следующий код - ',
    sectionsTree_notEqual: 'Код введён неверно!',
    sectionTree_enterCode: 'Введите код',
    sectionTree_flkTitle: 'ФЛК раздела',
    sectionTree_flkEditTitle: 'Редактирование ФЛК раздела',
    sectionTreeValidation_idMatch:
      'Код раздела может только состоять из букв латинского алфавита, цифр и знака подчеркивания',

    controlToken_expiredAt: 'Ваш сеанс истекает через:',
    controlToken_second: 'сек.',
    controlToken_stay: 'Оставаться в системе',

    passportEdit_currentVersion: 'Редактировать текущую версию',
    passportEditValidation_idHint: 'Введите ID паспорта',
    passportEditValidation_idMatch:
      'Код меты может только состоять из букв латинского алфавита, цифр и знака подчеркивания',
    passportEditValidation_idRequired: 'ID паспорта обязателен',
    passportEditValidation_ruNameHint: 'Введите наименование на русском',
    passportEditValidation_ruNameRequired: 'Наименование на русском обязательно',
    passportEditValidation_kkNameHint: 'Введите наименование на казахском',
    passportEditValidation_kkNameRequired: 'Наименование на казахском обязательно',
    passportEditValidation_enNameHint: 'Введите наименование на английском',
    passportEditValidation_enNameRequired: 'Наименование на английском обязательно',
    passportEditValidation_currentVersion: 'Текущая версия',

    passportEdit_cloneIdNote: '(не забудьте уникальный ID)',
    passportEdit_commitCommentNew: 'Создание нового паспорта',
    passportEdit_commitCommentUpdate: 'Изменение настроек паспорта',
    passportEdit_commitCommentRecipients:
      'Изменение списка адресов рассылки при изменении метаданных',
    passportEdit_commitCommentCustomConfigs:
      'Изменение дополнительной конфигурации паспорта',

    passportEdit_recipientsEdit: 'Список адресов рассылки',
    passportEdit_recipientsEditTitle: 'Список адресов рассылки при изменении метаданных',
    passportRecipientsEditValidation_emailHint: 'Введите адрес электронной почты',
    passportRecipientsEditValidation_emailInvalid:
      'Некорректный формат адреса электронной почты',

    passportEdit_customConfigsEdit: 'Дополнительная конфигурация',
    passportEdit_actionTooltipEdit: 'Редактировать дополнительную конфигурацию паспорта',

    passportEdit_customConfig_ES_METADATA_CONFIG:
      'Редактирование конфигурации уведомлений',
    passportEdit_customConfig_EXPORT_FORM_METADATA_CONFIG:
      'Редактирование конфигурации выходных форм',
    passportEdit_customConfig_EXTERNAL_CLIENT_METADATA_CONFIG:
      'Редактирование конфигурации экспорта данных для внешних систем',

    passportCustomConfigsEditValidation_assigneeGroupNameTemplateHint:
      'Введите assigneeGroupNameTemplate',
    passportCustomConfigsEditValidation_assigneeGroupNameTemplateRequired:
      'assigneeGroupNameTemplate обязательно',
    passportCustomConfigsEditValidation_sendIntermediateNotificationsHint:
      'Отправлять промежуточные уведомления',

    passportExport_title: 'Следующие паспорта будут экспортированы:',
    passportExport_export: 'Экспорт',
    passportExport_exportPassportSuccess: 'Паспорт(а) успешно экспортирован',
    passportExport_exportPassportError: 'Не удалось экспортировать паспорт(а)',

    passportImport_title: 'Выбирете файл для импорта паспорта:',
    passportImport_import: 'Импорт',
    passportImport_buttonUpload: 'Выбрать файл',
    passportImport_labelSelectedFile: 'Выбран файл',
    passportImport_labelNoSelectedFile: 'Файл не выбран',

    passportImport_importPassportSuccess: 'Паспорт успешно импортирован',
    passportImport_importPassportError: 'Не удалось импортировать паспорт',

    passportCustomConfigEdit_sendIntermediateNotifications:
      'Отправлять промежуточные уведомления',
    passportCustomConfigEdit_sendResolutionToIisCon:
      'Отправлять решение в ИИС ЦОН',
    passportCustomConfigEdit_intermediateNotificationQuestionCodesTitle:
      'Список показателей для промежуточных уведомлений',
    passportCustomConfigEdit_serviceRagConfigTitle: 'Сроки исполнения',
    passportCustomConfigEdit_serviceRagConfigGreenUntil: 'Нормально до',
    passportCustomConfigEdit_serviceRagConfigAmberUntil: 'В зоне риска до',
    passportCustomConfigEdit_exportFormsTitle: 'Выходные формы',
    passportCustomConfigEdit_exportFormsRequiredQuestionsCodesTitle:
      'Список выходных показателей',

    passportCustomConfigEdit_externalClientMetadata: 'Список внешних систем',
    passportCustomConfigEdit_externalClientMetadata_addClientMetadata: 'Добавить систему',
    passportCustomConfigEdit_externalClientMetadata_actionTooltipEdit:
      'Редактировать настройки системы',
    passportCustomConfigEdit_externalClientMetadata_actionTooltipDelete:
      'Удалить систему',
    passportCustomConfigEdit_externalClientMetadata_editClientMetadataTitle:
      'Редактирование настроек системы',
    passportCustomConfigEdit_externalClientMetadata_addClientMetadataTitle:
      'Добавление настроек системы',
    passportCustomConfigEdit_externalClientMetadata_tableClientCode: 'Код',
    passportCustomConfigEdit_externalClientMetadata_tableClientName: 'Наименование',
    passportCustomConfigEdit_externalClientMetadata_tableClientApplicableRule:
      'Правило доступности',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_clientCode:
      'Код внешней системы',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_clientNameRu:
      'Наименование внешней системы на русском',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_clientNameKk:
      'Наименование внешней системы на казахском',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_clientApplicableRule:
      'Правило доступности',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_clientQuestionCodesConfigsTitle:
      'Список показателей',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_editQuestionCodeTitle:
      'Редактирование кода показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_addQuestionCodeTitle:
      'Добавление кода показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipAdd:
      'Добавить код показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_tablеQuestionCodeInner:
      'Внутренний код',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeOuter:
      'Внешний код',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeValue:
      'Значение',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeAsStatic:
      'Отправлять как статический',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_addCode:
      'Добавить код показателя...',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipEdit:
      'Редактировать код показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipDelete:
      'Удалить код показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeInner:
      'Внутренний код',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeOuter:
      'Внешний код',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_manualEdit:
      'Ручной ввод показателя',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeValue:
      'Значение',
    passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodeAsStatic:
      'Отправлять как статический',

    passportSave_save: 'Сохранить',
    passportSave_reset: 'Отменить изменения',
    passportSave_message: 'Сохранить изменения в паспорте?',
    passportSave_currentVersion: 'Редактировать текущую версию',
    passportSave_allowSavingWithFailedRules:
      'Разрешать сохранять паспорт с ошибками валидации',

    passportSaveValidation_commitCommentHint: 'Введите описание изменений паспорта',
    passportSaveValidation_commitCommentRequired:
      'Описание изменений паспорта обязательно',

    passportDumpIndicators_dumpSuccess: 'Дамп показателей завершен',
    passportDumpIndicators_dumpError: 'Ошибка дампа показателей паспорта',

    indicatorShow_columnOrder: 'Порядок',

    indicatorEdit_type: 'Тип показателя',
    indicatorEdit_recurrent: 'Массив полей',
    indicatorEdit_anyTreeLevelSelectable: 'Возможность выбирать все элементы иерархии',
    indicatorEdit_renderer: 'Tип визуализации показателя',
    indicatorEdit_dynamicLookupTitle: "Свойства 'dynamic lookup'",
    indicatorEdit_multipleDynamicLookupTitle: "Свойства 'multiple dynamic lookup'",
    indicatorEdit_lookupId: 'Код справочника',
    indicatorEdit_complexTitle: 'Свойства динамической таблицы',
    indicatorEdit_documentTitle: 'Свойства документа',
    indicatorEdit_complexIndicatorsTitle: 'Показатели динамической таблицы',
    indicatorEdit_complexPageSplit: 'Разбивка на страницы',
    indicatorEdit_complexPageSize: 'Количество записей на странице',
    indicatorEdit_complexMetadataKey: 'Имя метаданных динамической таблицы',
    indicatorEdit_addComplexIndicator: 'Добавить показатель',
    indicatorEdit_documentMetadataKey: 'Имя метаданных документа',
    indicatorEdit_tableTitle: 'Свойства статической таблицы',
    indicatorEdit_tableCellRecurrent: 'Массив ячеек статической таблицы',
    indicatorEdit_tableCellType: 'Тип ячеек статической таблицы',
    indicatorEdit_tableCellRenderer: 'Tип визуализации ячеек статической таблицы',
    indicatorEdit_tableCellRulesTitle: 'Правила для ячеек статической таблицы',
    indicatorEdit_flkTitle: 'ФЛК показателя',
    indicatorEdit_flkEditTitle: 'Редактирование ФЛК показателя',
    indicatorEdit_customConfigsEdit: 'Дополнительная конфигурация',
    indicatorEdit_customConfigEdit:
      'Редактирование дополнительной конфигурации показателя',
    indicatorEdit_alwaysRequired: 'Всегда требуется',
    indicatorEdit_keepIfHidden:
      'Не очищать значение если показатель скрыт по правилам ФЛК',
    indicatorEdit_actionTooltipEdit:
      'Редактировать дополнительную конфигурацию показателя',

    indicatorCustomConfigEdit_SchoolSubtypesTitle:
      'Виды ОО для которых этот показатель видимый',
    indicatorCustomConfigEdit_SchoolSubtypesTableSubtype: 'Виды ОО',
    indicatorCustomConfigEdit_SchoolSubtypesTableSubtypeName: 'Названия ОО',
    indicatorCustomConfigEdit_addSubtype: 'Добавить тип ОО...',

    indicatorEditValidation_codeHint: 'Введите код показателя',
    indicatorEditValidation_codeMatch:
      'Код показателя может только состоять из букв латинского алфавита, цифр и знака подчеркивания',
    indicatorEditValidation_codeRequired: 'Код показателя обязателен',
    indicatorEditValidation_ruNameHint: 'Введите наименование на русском',
    indicatorEditValidation_ruNameRequired: 'Наименование на русском обязательно',
    indicatorEditValidation_kkNameHint: 'Введите наименование на казахском',
    indicatorEditValidation_kkNameRequired: 'Наименование на казахском обязательно',
    indicatorEditValidation_enNameHint: 'Введите наименование на английском',
    indicatorEditValidation_enNameRequired: 'Наименование на английском обязательно',
    indicatorEditValidation_typeHint: 'Выберите тип показателя',
    indicatorEditValidation_typeRequired: 'Тип показателя обязателен',
    indicatorEditValidation_recurrentHint: 'Массив полей',
    indicatorEditValidation_rendererHint: 'Выберите тип визуализации показателя',
    indicatorEditValidation_rendererRequired: 'Тип визуализации показателя обязателен',
    indicatorEditValidation_dynamicLookupIdHint: 'Выберите код справочника',
    indicatorEditValidation_dynamicLookupIdRequired: 'Код справочника обязателен',
    indicatorEditValidation_complexMetadataKeyHint: 'Выберите имя метаданных таблицы',
    indicatorEditValidation_complexMetadataKeyRequired:
      'Имя метаданных таблицы обязательно',
    indicatorEditValidation_complexPageSizeHint: 'Введите количество записей на странице',
    indicatorEditValidation_complexPageSizeRequired:
      'Количество записей на странице обязательно',
    indicatorEditValidation_documentMetadataKeyHint: 'Выберите имя метаданных документа',
    indicatorEditValidation_documentMetadataKeyRequired:
      'Имя метаданных документа обязательно',
    indicatorEditValidation_alwaysRequired: 'Всегда требуется',
    indicatorEditValidation_keepIfHidden:
      'Не очищать значение если показатель скрыт по правилам ФЛК',
    indicatorEditValidation_cellTypeRecurrentHint: 'Массив полей ячейки таблицы',
    indicatorEditValidation_cellTypeTypeHint: 'Выберите тип показателя ячейки таблицы',
    indicatorEditValidation_cellTypeTypeRequired:
      'Тип показателя ячейки таблицы обязателен',
    indicatorEditValidation_cellTypeRendererHint:
      'Выберите тип визуализации показателя ячейки таблицы',
    indicatorEditValidation_cellTypeRendererRequired:
      'Тип визуализации показателя ячейки таблицы обязателен',

    indicatorEditDucks_getComplexIndicatorsError:
      'Не удалось загрузить показатели таблицы',
    indicatorEditDucks_getDocumentIndicatorsError:
      'Не удалось загрузить показатели документа',
    indicatorEditDucks_getDictionariesError: 'Не удалось загрузить справочники',

    rulesEdit_type: 'Имя правила',
    rulesEdit_method: 'Код правила',

    exportFormsEdit_editExportFormTitle: 'Редактирование формы',
    exportFormsEdit_addExportFormTitle: 'Добавление формы',
    exportFormsEdit_exportFormFileName: 'Файл выходной формы',
    exportFormsEdit_exportFormReportNameRu: 'Название выходной формы на русском',
    exportFormsEdit_exportFormReportNameKk: 'Название выходной формы на казахском',
    exportFormsEdit_exportFormApplicableRule: 'Правило доступности формы',
    exportFormsEdit_exportFormQuestionCodesTitle: 'Список выходных показателей',
    exportFormsEdit_addFormRule: 'Добавить правило',
    exportFormsEdit_actionTooltipEdit: 'Редактировать правило выходной формы',
    exportFormsEdit_actionTooltipDelete: 'Удалить правило выходной формы',

    exportFormQuestionCodesEdit_tableIndicatorCode: 'Код показателя',
    exportFormQuestionCodesEdit_addCode: 'Добавить код показателя...',
    exportFormQuestionCodesEdit_questionCode: 'Код показателя',
    exportFormQuestionCodesEdit_manualEdit: 'Ручной ввод показателя',
    exportFormQuestionCodesEdit_editQuestionCodeTitle: 'Редактирование кода показателя',
    exportFormQuestionCodesEdit_addQuestionCodeTitle: 'Добавление кода показателя',
    exportFormQuestionCodesEdit_actionTooltipAdd: 'Добавить код показателя',
    exportFormQuestionCodesEdit_actionTooltipEdit: 'Редактировать код показателя',
    exportFormQuestionCodesEdit_actionTooltipDelete: 'Удалить код показателя',

    dimensionEdit_tableDictCodeTitle: 'Код справочника',
    dimensionEdit_addMore: 'Добавить код справочника...',
    dimensionEdit_editTitle: 'Редактирование кода справочника',
    dimensionEdit_addTitle: 'Добавление кода справочника',
    dimensionEdit_dictCode: 'Код справочника',
    dimensionEdit_excluded: 'Исключения для справочника',
    dimensionEdit_actionTooltipAdd: 'Добавить код справочника',
    dimensionEdit_actionTooltipEdit: 'Редактировать код справочника',
    dimensionEdit_actionTooltipDelete: 'Удалить код справочника',

    dimensionsEdit_tableDimensionsHorizontal: 'Справочники по столбцам',
    dimensionsEdit_tableDimensionsVertical: 'Справочники по строкам',

    cellRulesEdit_flkTitle: 'ФЛК ячеек статической таблицы',
    cellRulesEdit_editFLKTitle: 'Редактирование ФЛК ячеек статической таблицы',
    cellRulesEdit_actionTooltipAdd: 'Добавить правило ячеек',
    cellRulesEdit_actionTooltipEdit: 'Редактировать правило ячеек',
    cellRulesEdit_actionTooltipDelete: 'Удалить правило ячеек',

    externalIndicators_choosePassport: 'Выберите паспорт',
    externalIndicators_chooseIndicator: 'Выберите код внешнего показателя',

    linkedIndicators_chooseSection: 'Выберите раздел',
    linkedIndicators_chooseIndicator: 'Выберите код показателя',

    // pages
    page404_oops: 'Упс, Страница не найдена!',
    page404_notFound:
      'Возможно вы ввели не правильный адрес, <br/> или страница была удалена.',
    page404_toHome: 'на главную',

    page403_oops: 'Упс, Отказано в доступе!',
    page403_access: 'У вас нет прав для посещения этой страницы.',
    page403_toHome: 'на главную',

    homePage_permission: 'Доступ к странице "Главная"',

    loginPage_auth: 'АВТОРИЗАЦИЯ',
    loginPage_username: 'Имя пользователя',
    loginPage_password: 'Пароль',
    loginPage_signIn: 'Войти',
    loginPage_nameValidationNotCyrillic:
      'Имя пользователя должно содержать только латинские буквы и цифры',
    loginPage_wrongPasswordLength: 'Пароль должен быть длинной не менее 8 символов',
    loginPage_wrongPasswordNotCyrillic: 'Пароль не должен содержать кириллицу',
    loginPage_wrongPasswordDigits: 'Пароль должен включать цифры',
    loginPage_wrongPasswordLowercase:
      'Пароль должен включать латинские буквы нижнего регистра',
    loginPage_wrongPasswordUppercase:
      'Пароль должен включать латинские буквы вехнего регистра',
    loginPage_wrongPasswordSymbols:
      "Пароль должен включать не буквенные и не цифровые символы (например, '#', '!' или '&')",

    passportsPage_name: 'Паспорта',
    passportsPage_permission: 'Доступ к странице "Паспорта"',
    passportsPage_create: 'Создать новый паспорт',
    passportsPage_export: 'Экспортировать паспорт',
    passportsPage_import: 'Импортировать паспорт',

    passportsPage_addPassportTitle: 'Создание паспорта',
    passportsPage_editPassportTitle: 'Редактирование паспорта',
    passportsPage_clonePassportTitle: 'Клонирование паспорта',
    passportsPage_dumpIndicatorsPassportTitle: 'Дамп показателей паспорта',
    passportsPage_exportPassportTitle: 'Экспорт паспорта',
    passportsPage_importPassportTitle: 'Импорт паспорта',
    passportsPage_deleting: 'Удаление паспорта',
    passportsPage_deletePassport:
      'Чтобы удалить паспорт необходимо ввести следующий код - ',
    passportsPage_deleteAllPassportVersions: 'Удалить ВСЕ ВЕРСИИ паспорта',
    passportsPage_actionTooltipEdit: 'Редактировать паспорт',
    passportsPage_actionTooltipClone: 'Клонировать паспорт',
    passportsPage_actionTooltipDelete: 'Удалить паспорт',
    passportsPage_actionTooltipDumpIndicators: 'Дамп показателей',
    passportsPage_actionTooltipExport: 'Экспортировать паспорт',
    passportsDucks_getPassportsVersionsError:
      'Не удалось загрузить список версий паспорта',
    passportsDucks_getPassportError: 'Не удалось загрузить паспорт',
    passportsDucks_passportExistsError: 'Паспорт с таким же ID уже существует',
    passportsDucks_addPassportSuccess: 'Новый паспорт успешно создан',
    passportsDucks_addPassportError: 'Не удалось добавить новый паспорт',
    passportsDucks_clonePassportSuccess: 'Новый паспорт успешно клонирован',
    passportsDucks_clonePassportError: 'Не удалось клонировать паспорт',
    passportsDucks_updatePassportSuccess: 'Паспорт успешно сохранён',
    passportsDucks_updatePassportError: 'Не удалось сохранить паспорт',
    passportsDucks_deletePassportSuccess: 'Паспорт успешно удален',
    passportsDucks_deletePassportError: 'Не удалось удалить паспорт',
    passportsDucks_deleteAllPassportVersionsSuccess:
      'Все версии паспорта успешно удалены',
    passportsDucks_deleteAllPassportVersionsError:
      'Не удалось удалить все версии паспорта',
    passportsDucks_addMetadataSuccess: 'Метаданные успешно сохранены',
    passportsDucks_addMetadataError: 'Не удалось сохранить метаданные',
    passportsDucks_updateMetadataSuccess: 'Метаданные успешно сохранены',
    passportsDucks_updateMetadataError: 'Не удалось сохранить метаданные',

    sectionsPage_name: 'Разделы',
    sectionsPage_permission: 'Доступ к странице "Разделы"',
    sectionsPage_save: 'Сохранить паспорт',
    sectionsPage_choosePassport: 'Выберите паспорт',
    sectionsPage_editingSection: 'Редактирование раздела',
    sectionsPage_addingSection: 'Добавление раздела',
    sectionsPage_addingSubSection: 'Добавление подраздела',
    sectionsPage_removingSection: 'Удаление раздела',
    sectionsPage_duplicate: 'Раздел с таким ID уже существует!',

    indicatorsPage_name: 'Показатели',
    indicatorsPage_permission: 'Доступ к странице "Показатели"',
    indicatorsPage_save: 'Сохранить паспорт',
    indicatorsPage_choosePassport: 'Выберите паспорт',
    indicatorsPage_chooseSection: 'Выберите раздел',
    indicatorsPage_addIndicator: 'Добавить показатель',
    indicatorsPage_linkIndicator: 'Копировать показатель',
    indicatorsPage_linkIndicatorTitle: 'Копирование показателя из другого раздела',
    indicatorsPage_addExternalIndicator: 'Добавить внешний показатель',
    indicatorsPage_addExternalIndicatorTitle: 'Выбор внешнего показателя',
    indicatorsPage_savePassportTitle: 'Сохранение измененных показателей паспорта',
    indicatorsPage_actionTooltipEdit: 'Редактировать показатель',
    indicatorsPage_actionTooltipCopy: 'Копировать показатель',
    indicatorsPage_actionTooltipDelete: 'Удалить показатель',
    indicatorsPage_actionTooltipRestore: 'Восстановить показатель',

    indicatorsAddPage_name: 'Добавление показателя',
    indicatorsAddPage_permission: 'Доступ к странице "Добавить показатель"',
    indicatorsAddPage_title: 'Добавление показателя',

    indicatorsEditPage_name: 'Редактирование показателя',
    indicatorsEditPage_permission: 'Доступ к странице "Редактировать показатель"',
    indicatorsEditPage_title: 'Редактирование показателя',

    indicatorsAddComplexPage_name: 'Добавление показателя таблицы',
    indicatorsAddComplexPage_permission:
      'Доступ к странице "Добавить показатель таблицы"',
    indicatorsAddComplexPage_title: 'Добавление показателя таблицы',

    indicatorsEditComplexPage_name: 'Редактирование показателя таблицы',
    indicatorsEditComplexPage_permission:
      'Доступ к странице "Редактировать показатель таблицы"',
    indicatorsEditComplexPage_title: 'Редактирование показателя таблицы',

    // ducks error messages
    passportsDucks_PASSPORT_EXISTS: 'Паспорт с таким же ID уже существует',
    indicatorDucks_INDICATOR_EXISTS:
      'Показатель с таким же кодом уже существует в паспорте',
    indicatorDucks_INDICATOR_LOCAL_EXISTS:
      'Показатель с таким же кодом уже существует в этом разделе',
    indicatorEditDucks_INDICATOR_COMPLEX_EXISTS:
      'Показатель с таким же кодом уже существует в этой динамической таблице',

    // API error messages
    apiErrors_UNKNOWN_ERROR: 'Неизвестная ошибка',
    apiErrors_CONNECTION: 'Нет подключения к интернету',
    apiErrors_FAIL: 'Неудачный запрос',
    apiErrors_SERVER_ERROR: 'Ошибка сервера, попробуйте повторить операцию позже',
    apiErrors_BAD_GATEWAY:
      'В данный момент ведутся технические работы.  Попробуйте войти позже',
    apiErrors_NETWORK_TIMEOUT: 'Ошибка соединения, сервер не отвечает',
    apiErrors_NETWORK_ERROR: 'Ошибка, соединение прервано',
    apiErrors_NETWORK_PARAMS: 'Попробуте проверить параметры подключения к интернету',
    apiErrors_AUTH_HEADER_IS_NULL: 'Ошибка авторизации',
    'apiErrors_error.runtime_questionnaire_metadata_not_found':
      'Не найдены метаданные таблицы или документа',
    'apiErrors_error.metadata_recipients_is_invalid':
      'Один или более из адресов электронной почты для уведомления при сохранинии паспорта некорректены',
    'apiErrors_error.metadata_comment_length_must_be_less_than_or_equal_to_500':
      'Длина описания изменений при сохранении не может быть больше 500 символов',
    'apiErrors_error.metadata_comment_length_must_be_not_blank':
      'Нет описания изменений при сохранении'
  }
};
