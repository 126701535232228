import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import ru from 'react-intl/locale-data/ru';
import kk from 'react-intl/locale-data/kk';
import en from 'react-intl/locale-data/en';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/kk';
import { changeLocale, localeModule } from './LocaleDucks';

addLocaleData([...ru, ...kk, ...en]);

export let formatMessage = id => id;

function LocaleProvider({ locale, changeLocale, children, messages }) {
  let intlProvider = new IntlProvider({ locale: locale, messages: messages});
  let { intl } = intlProvider.getChildContext();
  formatMessage = intl.formatMessage;

  useEffect(() => {
    let lang = localStorage.getItem('lang');
    if (lang && lang !== locale) {
      changeLocale(lang);
      moment.locale(locale);
    }
  });

  return <IntlProvider messages={messages} locale={locale} children={children} />;
}

const mapStateProp = state => ({
  locale: state[localeModule].lang,
  messages: state[localeModule].messages
});

export default connect(
  mapStateProp,
  { changeLocale }
)(LocaleProvider);
