import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import reducers from './reducers';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import saga from "./saga";

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const middleware = [...getDefaultMiddleware(), sagaMiddleware, routerMiddleware(history)];
export const store = configureStore({
  reducer: combineReducers(reducers),
  middleware: middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

sagaMiddleware.run(saga);
