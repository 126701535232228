import { routerReducer } from 'react-router-redux';
import sortReducers from '../utils/sortReducers';
import localeReducer, { localeModule } from '../components/LocaleProvider/LocaleDucks';
import loginReducer, { loginModule } from '../pages/LoginPage/LoginDucks';
import passportsReducer, { passportsModule } from '../pages/PassportsPage/PassportsDucks';
import sectionsReducer, { sectionsModule } from '../pages/SectionsPage/SectionsDucks';
import indicatorsReducer, { indicatorsModule } from '../pages/IndicatorsPage/IndicatorsDucks';
import indicatorEditReducer, {
  indicatorEditModule
} from '../components/IndicatorEdit/IndicatorEditDucks';
import passportCustomConfigsEditReducer, {
  passportCustomConfigsEditModule
} from '../components/PassportCustomConfigsEdit/PassportCustomConfigsEditDucks';

export default sortReducers({
  router: routerReducer,
  [localeModule]: localeReducer,
  [loginModule]: loginReducer,
  [passportsModule]: passportsReducer,
  [sectionsModule]: sectionsReducer,
  [indicatorsModule]: indicatorsReducer,
  [indicatorEditModule]: indicatorEditReducer,
  [passportCustomConfigsEditModule]: passportCustomConfigsEditReducer
});
