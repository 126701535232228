import { all, put, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { LoginApi, getAppName } from '../../_helpers/service';
import { history } from '../../_helpers/store';
import { Notice } from '../../utils/Notice';
import { createReducer } from 'redux-starter-kit';
import { Permissions } from '../../_helpers/Permissions';
import routers from '../../_helpers/routers';

/**
 * Constants
 */

export const loginModule = 'login';
export const LOADING = `${loginModule}/LOADING`;
export const LOGIN = `${loginModule}/LOGIN`;
export const LOGOUT = `${loginModule}/LOGOUT`;

/**
 * Reducer
 */

const initialState = {
  loadingLogin: false,
  loading: false,
  user: null,
  permissions: []
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [LOGIN]: (state, action) => {
    state.user = action.user;
    state.permissions = action.permissions;
  },
  [LOGOUT]: state => {
    state.user = null;
    state.permissions = [];
  }
});

/**
 * Actions
 */

export function login(data) {
  return async dispatch => {
    try {
      dispatch({ type: LOADING, loading: true });
      let response = await LoginApi.login(data);
      if (response.data.status === 'SUCCESS') {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('expiredAt', Date.now() + 600000);

        const hasPermissions = response.data.user.groups.some(group =>
          group.items.some(
            item =>
              item.name === 'CAN_MANAGE_METADATA' && item.active && item.appName === getAppName()
          )
        );
        const permissions = [];

        if (hasPermissions) {
          for (const key in Permissions) {
            permissions.push(Permissions[key].code);
          }
        }

        dispatch({ type: LOGIN, user: response.data.user, permissions });

        history.push('/');
      } else {
        Notice.error('Не удалось авторизоваться');
      }
    } catch (e) {
      console.error(e);
      Notice.error('Не удалось авторизоваться');
    } finally {
      dispatch({ type: LOADING, loading: false });
    }
  };
}

export const logout = () => async dispatch => {
  try {
    await LoginApi.logout();
    dispatch({ type: LOGOUT });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
  } catch (e) {
    console.log(e);
  }
};

/**
 * Sagas
 */

function checkRedirect() {
  let pathName = window.location.pathname;
  let urls = Object.values(routers)
    .filter(item => item.withoutAuth)
    .map(item => item.url);

  if (pathName === '/') return false;
  return !urls.some(url => pathName.indexOf(url) !== -1);
}

function* updateUser() {
  try {
    const { data } = yield call([LoginApi, LoginApi.getUser]);

    const hasPermissions = data.user.groups.some(group =>
      group.items.some(
        item => item.name === 'CAN_MANAGE_METADATA' && item.active && item.appName === getAppName()
      )
    );
    const permissions = [];

    if (hasPermissions) {
      for (const key in Permissions) {
        permissions.push(Permissions[key].code);
      }
    }

    yield put({ type: LOGIN, user: data.user, permissions });
  } catch (error) {
    if (checkRedirect()) {
      window.lastUserUrl = `${window.location.pathname}${window.location.search}`;
      yield put(push('/'));
    }
  } finally {
    yield put({ type: LOADING, loading: false });
  }
}

function* watchStatusChange() {
  yield put({ type: LOADING, loading: true });
  let token = localStorage.getItem('accessToken');
  let expiredAt = localStorage.getItem('expiredAt');
  if (!expiredAt || expiredAt < Date.now() + 10000) {
    token = undefined;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
  }
  yield call(updateUser, token);
}

export const loginSaga = function*() {
  yield all([watchStatusChange()]);
};
