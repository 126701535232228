import React from 'react';
import UnsupportedCustomConfig from './UnsupportedCustomConfig/UnsupportedCustomConfig';
import EsMetadataCustomConfig from './EsMetadataCustomConfig/EsMetadataCustomConfig';
import ExportFormMetadataCustomConfig from './ExportFormMetadataCustomConfig/ExportFormMetadataCustomConfig';
import ExternalClientMetadataCustomConfig from './ExternalClientMetadataCustomConfig/ExternalClientMetadataCustomConfig';

const PassportCustomConfigEdit = ({ config, ...rest }) => {
  switch (config['@type']) {
    case 'EsMetadataCustomConfig':
      return <EsMetadataCustomConfig config={config} {...rest} />;

    case 'ExportFormMetadataCustomConfig':
      return <ExportFormMetadataCustomConfig config={config} {...rest} />;

    case 'ExternalClientMetadataCustomConfig':
      return <ExternalClientMetadataCustomConfig config={config} {...rest} />;

    default:
      break;
  }

  return <UnsupportedCustomConfig config={config} {...rest} />;
};

export default PassportCustomConfigEdit;
