import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Select from '../../_ui/Select/Select';
import Checkbox from '../../_ui/Checkbox/Checkbox';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../_hoc/withTranslation';
import RulesEdit from '../RulesEdit/RulesEdit';
import {
  indicatorEditModule,
  setComplexIndicator
} from '../IndicatorEdit/IndicatorEditDucks';
import {
  COMPLEX_INDICATOR_TYPES,
  indicatorTypeToRenderer,
  allowsIndicatorTypeRecurrent,
  updateIndicator,
  collectIndicatorRuleMethods,
  extractConfigFields,
  injectConfigFields,
  addMetadataKeyPrefix
} from '../IndicatorEdit/IndicatorEditUtils';
import { makeIndicatorSchema } from '../IndicatorEdit/IndicatorEditValidation';

const IndicatorEditComplex = props => {
  const {
    t,
    lang,
    loading,
    indicator,
    onSubmit,
    onCancel,
    setComplexIndicator,
    dictionaries,
    dictionariesLoading
  } = props;

  const [indicatorSchema, setIndicatorSchema] = useState(
    makeIndicatorSchema(indicator.type)
  );

  const dictOptions = useMemo(
    () =>
      dictionaries.map(info => {
        const label = info[`${lang}Name`] || info.ruName || info.description || '';

        return {
          label: label ? `${info.code} "${label}"` : `${info.code}`,
          value: info.code
        };
      }),
    [dictionaries, lang]
  );

  const buttonClasses = useButtonStyles();

  const indicatorTypes = useMemo(
    () => COMPLEX_INDICATOR_TYPES.map(x => ({ label: x, value: x })),
    []
  );

  return (
    <Form
      initialValues={extractConfigFields(indicator)}
      validate={indicatorSchema}
      onSubmit={indicator => {
        switch (indicator.type) {
          case 'document': {
            const metadataKey = indicator['#config#metadataKey'];
            const metadataRealKey = addMetadataKeyPrefix(metadataKey, indicator.type);

            const updatedIndicator = updateIndicator(injectConfigFields(indicator));

            onSubmit({
              ...updatedIndicator,
              config: {
                ...updatedIndicator.config,
                metadataKey: metadataRealKey
              }
            });
            break;
          }

          default: {
            onSubmit(updateIndicator(injectConfigFields(indicator)));
            break;
          }
        }
      }}
      render={(indicator, { setValues }) => (
        <>
          <Input fullWidth autoTrim name="code" label={t('common_code')} />
          <Input fullWidth autoTrim name="ru_name" label={t('common_ruName')} />
          <Input fullWidth autoTrim name="kk_name" label={t('common_kkName')} />
          <Input fullWidth autoTrim name="en_name" label={t('common_enName')} />
          <Select
            fullWidth
            name="type"
            label={t('indicatorEdit_type')}
            options={indicatorTypes}
            disabled={!!!indicator.code}
            onChange={type => {
              if (type !== indicator.type) {
                setIndicatorSchema(makeIndicatorSchema(type));

                setValues(
                  extractConfigFields(
                    updateIndicator(injectConfigFields(indicator), type)
                  )
                );
              }
            }}
          />
          {allowsIndicatorTypeRecurrent(indicator.type) && (
            <Checkbox
              fullWidth
              name="recurrent"
              label={t('indicatorEdit_recurrent')}
              disabled={!!!indicator.code}
            />
          )}
          {indicator.type === 'dynamic lookup' && (
            <Checkbox
              fullWidth
              name="#config#anyTreeLevelSelectable"
              label={t('indicatorEdit_anyTreeLevelSelectable')}
            />
          )}
          <Select
            fullWidth
            name="renderer"
            label={t('indicatorEdit_renderer')}
            disabled={!!!indicator.code}
            options={indicatorTypeToRenderer(indicator.type).map(x => ({
              label: x,
              value: x
            }))}
          />
          {(indicator.type === 'dynamic lookup' ||
            indicator.type === 'multiple dynamic lookup') && (
            <div style={{ marginBottom: '15px' }}>
              <h4>
                {indicator.type === 'dynamic lookup'
                  ? t('indicatorEdit_dynamicLookupTitle')
                  : t('indicatorEdit_multipleDynamicLookupTitle')}
              </h4>
              <Select
                fullWidth
                name="#config#lookupId"
                searchable={true}
                label={t('indicatorEdit_lookupId')}
                options={dictOptions}
                loading={dictionariesLoading}
              />
            </div>
          )}
          {indicator.type === 'document' && (
            <div style={{ marginBottom: '15px' }}>
              <h4>{t('indicatorEdit_documentTitle')}</h4>
              <Input
                fullWidth
                name="#config#metadataKey"
                label={t('indicatorEdit_documentMetadataKey')}
              />
            </div>
          )}
          <div style={{ marginBottom: '15px' }}>
            <h4>{t('indicatorEdit_flkTitle')}</h4>
            <RulesEdit
              loading={loading || dictionariesLoading}
              rules={collectIndicatorRuleMethods(indicator)}
              editRule={(type, method) => {
                setValues({
                  ...indicator,
                  [type]: method
                });
              }}
            />
            <Checkbox
              fullWidth
              name="alwaysRequired"
              label={t('indicatorEdit_alwaysRequired')}
            />
            <Checkbox
              fullWidth
              name="keepIfHidden"
              label={t('indicatorEdit_keepIfHidden')}
            />
          </div>
          <div
            className={buttonClasses.buttonsGroup}
            style={{ marginTop: '0px', marginBottom: '20px' }}
          >
            <Button
              type="submit"
              text={t('simpleModal_submit')}
              className={buttonClasses.submitButton}
            />
            <Button
              type="button"
              text={t('simpleModal_cancel')}
              color="secondary"
              onClick={() => {
                setComplexIndicator(null);
                onCancel();
              }}
            />
          </div>
        </>
      )}
    />
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      dictionaries: state[indicatorEditModule].dictionaries,
      dictionariesLoading: state[indicatorEditModule].dictionariesLoading
    }),
    {
      setComplexIndicator
    }
  )
)(IndicatorEditComplex);
