import React, { useState } from 'react';
import { Typography, Grid, Button as MUIButton } from '@material-ui/core';
import withTranslation from '../../components/_hoc/withTranslation';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import { PassportsApi } from '../../_helpers/service';
import { Notice } from '../../utils/Notice';
import { getErrorCode, translateError } from '../../utils/apiError';
import translate from '../../utils/translate';

const PassportImport = ({ t, onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importProgress, setImportProgress] = useState(0);

  const importPassport = onSuccess => {
    setImporting(true);
    setImportProgress(0);

    const formData = new FormData();
    formData.append('import_data', selectedFiles[0]);

    PassportsApi.importPassport(formData, ({ loaded, total }) => {
      setImportProgress(Math.round((100 * loaded) / total));
    })
      .then(response => {
        if (response.data.status === 'SUCCESS') {
          if (onSuccess) {
            onSuccess();
          }

          Notice.success(translate('passportImport_importPassportSuccess'));
        } else {
          Notice.error(translate('passportImport_importPassportError'));
        }
      })
      .catch(error => {
        const errorCode = getErrorCode(error);
        Notice.error(translateError(errorCode, 'passportImport_importPassportError'));
      })
      .finally(() => {
        setImporting(false);
        setImportProgress(0);
      });
  };

  const selectFile = event => {
    setSelectedFiles(event.target.files);
  };

  const buttonClasses = useButtonStyles();

  return (
    <div style={{ marginTop: '30px' }}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography>{t('passportImport_title')}</Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <input
              style={{ display: 'none' }}
              id="select-import-file"
              type="file"
              accept=".zip"
              disabled={importing}
              onChange={selectFile}
            />
            <label htmlFor="select-import-file">
              <MUIButton
                variant="outlined"
                color="primary"
                component="span"
                disabled={importing}
              >
                {t('passportImport_buttonUpload')}
              </MUIButton>
            </label>
          </Grid>
          <Grid item>
            {selectedFiles.length > 0
              ? `${t('passportImport_labelSelectedFile')}: ${selectedFiles[0].name}`
              : t('passportImport_labelNoSelectedFile')}
          </Grid>
        </Grid>
        <Grid item>
          <div className={buttonClasses.buttonsGroup}>
            <Button
              type="button"
              text={`${t('passportImport_import')}${
                importing ? ` ...${importProgress}%` : ''
              }`}
              className={buttonClasses.submitButton}
              disabled={importing || selectedFiles.length === 0}
              onClick={() => importPassport(onClose)}
            />
            <Button
              type="button"
              color="secondary"
              text={t('simpleModal_close')}
              onClick={onClose}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation(PassportImport);
