import React from 'react';
import Typography from '@material-ui/core/Typography';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import routers from "../../_helpers/routers";
import { BreadcrumbsContainer } from "./BreadcrumbsStyle";

export default function Breadcrumbs({ pathname }) {
  const breadcrumbNameMap = {};
  Object.values(routers).forEach(route => {
    if (route.name) {
      breadcrumbNameMap[route.path] = route.name();
    }
  });

  const pathnames = pathname.split('/').filter(x => x);

  return (
    <BreadcrumbsContainer>
      <MuiBreadcrumbs aria-label="breadcrumb">
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography key={to} className="last">
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <Link to={to} key={to}>
              {breadcrumbNameMap[to]}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  );
}