import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import ReactTableWrapper from './ReactTableWrapper';

class StaticTable extends React.Component {
  state = {
    collapsed: false
  };

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.collapsible) {
      if (prevProps.data !== this.props.data && prevState.collapsed) {
        this.setState({
          collapsed: false
        });
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.data !== nextProps.data ||
      this.props.loading !== nextProps.loading ||
      this.state.collapsed !== nextState.collapsed ||
      this.props.columns !== nextProps.columns
    );
  }

  render() {
    const { t, collapsible, loading, data } = this.props;
    const { collapsed } = this.state;

    // pageSize
    let size = collapsible ? 20 : data.length || 5;
    if (collapsible) {
      if (data.length < 20 || collapsed) {
        size = data.length;
      }
      if (size < 5 && (data.length === 0 || loading)) {
        size = 5;
      }
    }

    return (
      <div>
        <ReactTableWrapper {...this.props} pageSize={size}/>
        {collapsible && !loading && data.length > 20 && (
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => this.setState({ collapsed: !collapsed })}
          >
            {collapsed ? t('staticTable_collapse') : t('staticTable_expand')}
          </Button>
        )}
      </div>
    );
  }
}

StaticTable.propTypes = {
  collapsible: PropTypes.bool,
  filterable: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  buttons: PropTypes.element,
  onClickRow: PropTypes.func,
  withoutHeader: PropTypes.bool,
  markText: PropTypes.string
};

StaticTable.defaultProps = {
  collapsible: true,
  filterable: true,
  loading: false,
  withoutHeader: false
};

export default withTranslation(StaticTable);
