import React, { useState, useMemo } from 'react';
import { difference, flatten } from 'lodash';
import withTranslation from '../../../_hoc/withTranslation';
import Select from '../../../../_ui/Select/Select';
import Input from '../../../../_ui/Input/Input';
import Checkbox from '../../../../_ui/Checkbox/Checkbox';
import Button from '../../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../../_styles/ButtonsStyle';

const ExportFormQuestionCodeEdit = props => {
  const {
    t, lang,
    questions,
    questionCodes,
    excludedQuestionCodes = [],
    requiredQuestionCode,
    onClose,
    onSubmit
  } = props;
  const [questionCode, setQuestionCode] = useState(requiredQuestionCode);

  const [manualEdit, setManualEdit] = useState(false);

  const options = useMemo(() => {
      const { __root__, ...rest } = questionCodes;

      return flatten(
        Object.keys({ __root__, ...rest })
          .map(
            key =>
              difference(
                questionCodes[key],
                excludedQuestionCodes.filter(code => code !== questionCode)
              )
                .map(code => ({
                  label: `[${key === "__root__" ? "" : `${key}:`}${code}] ${questions[key][code] ? questions[key][code][`${lang}_name`] : 'external'}`,
                  value: code
                }))
          )
      );
    },
    [questions, questionCodes, excludedQuestionCodes, questionCode, lang]
  );

  const buttonClasses = useButtonStyles();

  return (
    <>
      <div style={{ height: '400px' }}>
        <Select
          withoutForm
          fullWidth
          searchable={true}
          autoFocus={true}
          label={t('exportFormQuestionCodesEdit_questionCode')}
          options={options}
          value={questionCode}
          disabled={manualEdit}
          onChange={setQuestionCode}
        />
        <Checkbox
          withoutForm
          fullWidth
          label={t('exportFormQuestionCodesEdit_manualEdit')}
          value={manualEdit}
          onChange={() => setManualEdit(value => !value)}
        />
        {manualEdit &&
        <Input
          withoutForm
          fullWidth
          placeholder={t('exportFormQuestionCodesEdit_questionCode')}
          autoFocus={manualEdit}
          value={questionCode}
          onChange={event => setQuestionCode(event.target.value)}
        />
        }
      </div>
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          onClick={() => onSubmit(questionCode)}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default withTranslation(ExportFormQuestionCodeEdit);
