import React, { useState, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import TreeSelect from '../../_ui/TreeSelect/TreeSelect';
import Select from '../../_ui/Select/Select';
import withTranslation from '../../components/_hoc/withTranslation';
import { makeExternalIndicator } from '../../components/IndicatorEdit/IndicatorEditUtils';

const sectionsConfig = {
  codeKey: 'id',
  childKey: 'subGroups'
};

const LinkedIndicators = props => {
  const {
    t, lang,
    passport,
    onSelectIndicator
  } = props;

  const [section, setSection] = useState({ name: null, value: null });
  const [indicatorCode, setIndicatorCode] = useState(null);

  const sectionsOptions = useMemo(
    () => passport ? cloneDeep(passport.group.subGroups) : [],
    [passport]
  );

  const indicatorCodesOptions = useMemo(
    () => section.value ?
      section.value.sections.length > 0 ?
        section.value.sections[0].questionCodes
          .map(code => ({
            label: `[${code}] ${passport.questions[code] ? passport.questions[code][`${lang}_name`] : t(`common_${lang}ExternalName`)}`,
            value: code
          }))
        : []
      : [],
    [t, lang, passport, section]
  );

  return (
    <div style={{ marginTop: '15px', height: '450px' }}>
      <TreeSelect
        withoutForm
        fullWidth
        label={t('linkedIndicators_chooseSection')}
        parentSelectable={true}
        data={sectionsOptions}
        dataConfig={sectionsConfig}
        value={section.name}
        onChange={(name, value) => {
          setSection({ name, value: cloneDeep(value) });
          setIndicatorCode(null);
        }}
        renderLabel={item => item[`${lang}_name`]}
        disabled={!!!passport || passport.group.subGroups.length === 0}
      />
      <div style={{ marginTop: '10px' }}>
        <Select
          withoutForm
          fullWidth
          searchable={true}
          label={t('linkedIndicators_chooseIndicator')}
          options={indicatorCodesOptions}
          disabled={!!!passport || !!!section.value}
          value={indicatorCode}
          onChange={code => {
            setIndicatorCode(code || null);
            if (code) {
              onSelectIndicator(passport.questions[code] || makeExternalIndicator(code))
            }
          }}
        />
      </div>
    </div>
  );
};

export default withTranslation(LinkedIndicators);
