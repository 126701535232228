import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as DumpIcon,
  FileCopy as CloneIcon
} from '@material-ui/icons';
import { Permissions } from '../../_helpers/Permissions';
import Button from '../../_ui/Button/Button';
import StaticTable from '../../_ui/Table/StaticTableMulti';
import Modal from '../../_ui/Modal/Modal';
import SimpleModal from '../../_ui/Modal/SimpleModal';
import Input from '../../_ui/Input/Input';
import Checkbox from '../../_ui/Checkbox/Checkbox';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import PassportEdit from '../../components/PassportEdit/PassportEdit';
import PassportImport from '../../components/PassportImport/PassportImport';
import PassportExport from '../../components/PassportExport/PassportExport';
import PassportDumpIndicators from '../../components/PassportDumpIndicators/PassportDumpIndicators';
import { makePassport } from '../../components/PassportEdit/PassportEditUtils';
import { getRandomDigits } from '../../utils/random';
import {
  passportsModule,
  getPassportsVersions,
  getPassport,
  setPassport,
  addPassport,
  clonePassport,
  updatePassport,
  deletePassport,
  deleteAllPassportVersions
} from './PassportsDucks';
import { useStyles } from './PassportsPageStyle';

const PassportsPage = props => {
  const {
    t,
    lang,
    loading,
    passportsInfo,
    getPassportsVersions,
    passport,
    passportVersion,
    getPassport,
    setPassport,
    addPassport,
    clonePassport,
    updatePassport,
    deletePassport,
    deleteAllPassportVersions
  } = props;

  const [openEdit, setOpenEdit] = useState(null);

  const [openDelete, setOpenDelete] = useState(null);
  const [deleteConfirmCode, setDeleteConfirmCode] = useState(0);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);

  const [openDump, setOpenDump] = useState(null);

  const [selection, setSelection] = useState([]);
  const [openExport, setOpenExport] = useState(null);
  const [openImport, setOpenImport] = useState(null);

  useEffect(() => {
    getPassportsVersions();
  }, [getPassportsVersions]);

  const classes = useStyles();

  const passportColumns = [
    {
      Header: t('common_id'),
      accessor: 'id'
    },
    {
      Header: t('common_ruName'),
      accessor: 'ru_name'
    },
    {
      Header: t('common_kkName'),
      accessor: 'kk_name'
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ original }) => (
        <>
          <Access permissions={[Permissions.editPassport.code]}>
            <Button
              size="small"
              icon={<EditIcon />}
              className={classes.leftButton}
              tooltip={t('passportsPage_actionTooltipEdit')}
              onClick={event => {
                event.stopPropagation();
                setOpenEdit({ op: 'EDIT', id: original.id });
                getPassport(original.id);
              }}
            />
          </Access>
          <Access permissions={[Permissions.editPassport.code]}>
            <Button
              className={classes.middleButton}
              size="small"
              icon={<CloneIcon />}
              tooltip={t('passportsPage_actionTooltipClone')}
              onClick={event => {
                event.stopPropagation();
                setOpenEdit({ op: 'CLONE', id: original.id });
                getPassport(original.id);
              }}
            />
          </Access>
          <Access permissions={[Permissions.deletePassport.code]}>
            <Button
              className={classes.rightButton}
              size="small"
              icon={<DeleteIcon />}
              color="secondary"
              tooltip={t('passportsPage_actionTooltipDelete')}
              onClick={event => {
                event.stopPropagation();
                makeDeleteConfirmCode();
                setOpenDelete(original);
              }}
            />
          </Access>
          &nbsp;&nbsp;
          <Access permissions={[Permissions.dumpIndicatorsPassport.code]}>
            <Button
              style={{ backgroundColor: 'orange' }}
              size="small"
              icon={<DumpIcon />}
              tooltip={t('passportsPage_actionTooltipDumpIndicators')}
              onClick={event => {
                event.stopPropagation();
                setOpenDump(original.id);
                getPassport(original.id);
              }}
            />
          </Access>
        </>
      ),
      style: {
        textAlign: 'center'
      },
      width: 280
    }
  ];

  const onCloseEdit = () => {
    setOpenEdit(null);
  };

  const onCloseDelete = () => {
    setOpenDelete(null);
  };

  const onCloseDump = () => {
    setOpenDump(null);
  };

  const onCloseExport = () => {
    setSelection([]);
    setOpenExport(null);
  };

  const onCloseImport = () => {
    getPassportsVersions();
    setOpenImport(null);
  };

  const onSavePassport = (newPassport, currentVersion, commitComment) => {
    if (openEdit.op === 'ADD') {
      addPassport(newPassport, commitComment, onCloseEdit);
    } else if (openEdit.op === 'CLONE') {
      clonePassport(passportVersion, passport.id, newPassport.id, onCloseEdit);
    } else {
      updatePassport(
        newPassport,
        currentVersion ? passportVersion : null,
        commitComment,
        onCloseEdit
      );
    }
  };

  const makeDeleteConfirmCode = () => setDeleteConfirmCode(getRandomDigits(6));

  const codeValidation = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required(() => t('common_required'))
          .test(
            'is-equal',
            t('sectionsTree_notEqual'),
            value => value === deleteConfirmCode
          )
      }),
    [t, deleteConfirmCode]
  );

  const onConfirmDelete = () => {
    if (deleteAllVersions) {
      setDeleteAllVersions(false);
      deleteAllPassportVersions(openDelete.id);
    } else {
      deletePassport(openDelete.id);
    }
    setSelection([]);
    setOpenDelete(null);
  };

  return (
    <>
      <Access permissions={[Permissions.createPassport.code]}>
        <Button
          style={{ marginBottom: '15px', marginRight: '8px' }}
          type="button"
          text={t('passportsPage_create')}
          disabled={loading}
          onClick={() => {
            setOpenEdit({ op: 'ADD', id: null });
            setPassport(makePassport());
          }}
        />
      </Access>
      <Access permissions={[Permissions.importPassport.code]}>
        <Button
          style={{ marginBottom: '15px', marginRight: '8px' }}
          type="button"
          text={t('passportsPage_import')}
          disabled={loading}
          onClick={event => {
            event.stopPropagation();
            setOpenImport(true);
          }}
        />
      </Access>
      <Access permissions={[Permissions.exportPassport.code]}>
        <Button
          style={{ marginBottom: '15px' }}
          type="button"
          text={t('passportsPage_export')}
          disabled={loading || selection.length === 0}
          onClick={event => {
            event.stopPropagation();
            setOpenExport(selection);
          }}
        />
      </Access>
      <StaticTable
        withoutHeader
        filterable={false}
        loading={loading}
        columns={passportColumns}
        data={passportsInfo}
        selection={selection}
        setSelection={setSelection}
      />
      <Access
        permissions={[Permissions.createPassport.code, Permissions.editPassport.code]}
      >
        <Modal
          open={
            !!passport &&
            !!openEdit &&
            (openEdit.id === passport.id || openEdit.op === 'ADD')
          }
          onClose={onCloseEdit}
          title={
            !!!openEdit
              ? ''
              : openEdit.op === 'ADD'
              ? t('passportsPage_addPassportTitle')
              : openEdit.op === 'CLONE'
              ? t('passportsPage_clonePassportTitle')
              : t('passportsPage_editPassportTitle')
          }
        >
          <PassportEdit
            op={openEdit ? openEdit.op : ''}
            passport={passport}
            onClose={onCloseEdit}
            onSubmit={onSavePassport}
          />
        </Modal>
      </Access>
      <Access permissions={[Permissions.dumpIndicatorsPassport.code]}>
        <Modal
          open={!!passport && !!openDump && openDump === passport.id}
          title={t('passportsPage_dumpIndicatorsPassportTitle')}
        >
          <PassportDumpIndicators passport={passport} onClose={onCloseDump} />
        </Modal>
      </Access>
      <Access permissions={[Permissions.exportPassport.code]}>
        <Modal
          open={!!openExport}
          onClose={onCloseExport}
          title={t('passportsPage_exportPassportTitle')}
        >
          <PassportExport ids={selection} onClose={onCloseExport} />
        </Modal>
      </Access>
      <Access permissions={[Permissions.importPassport.code]}>
        <Modal
          open={!!openImport}
          onClose={onCloseImport}
          title={t('passportsPage_importPassportTitle')}
        >
          <PassportImport onClose={onCloseImport} />
        </Modal>
      </Access>
      <Access permissions={[Permissions.deletePassport.code]}>
        <SimpleModal
          open={!!openDelete}
          onClose={onCloseDelete}
          onSubmit={onConfirmDelete}
          title={t('passportsPage_deleting')}
          validate={codeValidation}
        >
          <b>"{openDelete && openDelete[`${lang}_name`]}"</b>
          <p>
            {t('passportsPage_deletePassport')}
            <b>{deleteConfirmCode}</b>
          </p>
          <Input
            fullWidth
            label={t('sectionTree_enterCode')}
            name="code"
            type="number"
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              shrink: true
            }}
          />
          <Checkbox
            fullWidth
            withoutForm
            label={
              <b style={{ color: 'red' }}>
                {t('passportsPage_deleteAllPassportVersions')}
              </b>
            }
            value={deleteAllVersions}
            onChange={() => setDeleteAllVersions(value => !value)}
          />
        </SimpleModal>
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[passportsModule].loading,
      passportsInfo: state[passportsModule].passportsInfo,
      passport: state[passportsModule].passport,
      passportVersion: state[passportsModule].passportVersion
    }),
    {
      getPassportsVersions,
      getPassport,
      setPassport,
      addPassport,
      clonePassport,
      updatePassport,
      deletePassport,
      deleteAllPassportVersions
    }
  )
)(PassportsPage);
