import React from 'react';
import Form from '../../../_ui/Form/Form';
import Input from '../../../_ui/Input/Input';
import Checkbox from '../../../_ui/Checkbox/Checkbox';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';
import withTranslation from '../../../components/_hoc/withTranslation';
import IntermediateNotificationQuestionCodesEdit from './IntermediateNotificationQuestionCodesEdit';
import { EsMetadataCustomConfigSchema } from './EsMetadataCustomConfigValidation';

const EsMetadataCustomConfig = props => {
  const {
    t,
    questions,
    questionCodes,
    config,
    onSave,
    onClose
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={{
        serviceRagConfig: {
          greenUntil: '',
          amberUntil: ''
        },
        ...config
      }}
      validate={EsMetadataCustomConfigSchema}
      onSubmit={values => {
        onSave(values);
      }}
      render={(config, { setValues: setConfig }) => {
        return (
          <>
            <Input
              fullWidth
              name="id"
              label={'id'}
              disabled={true}
            />
            <Input
              fullWidth
              name="assigneeGroupNameTemplate"
              label={'assigneeGroupNameTemplate'}
            />
            <Checkbox
              fullWidth
              name="sendIntermediateNotifications"
              label={t('passportCustomConfigEdit_sendIntermediateNotifications')}
            />
            <Checkbox
              fullWidth
              name="sendResolutionToIisCon"
              label={t('passportCustomConfigEdit_sendResolutionToIisCon')}
            />
            {config.sendIntermediateNotifications &&
            <div style={{ marginTop: '15px' }}>
              <h4>{t('passportCustomConfigEdit_intermediateNotificationQuestionCodesTitle')}</h4>
              <IntermediateNotificationQuestionCodesEdit
                questions={questions}
                questionCodes={questionCodes}
                data={config.intermediateNotificationQuestionCodes}
                onChange={intermediateNotificationQuestionCodes => {
                  setConfig({
                    ...config,
                    intermediateNotificationQuestionCodes
                  });
                }}
              />
            </div>
            }
            <div style={{ marginTop: '15px' }}>
              <h4>{t('passportCustomConfigEdit_serviceRagConfigTitle')}</h4>
              <Input
                fullWidth
                name="serviceRagConfig.greenUntil"
                label={t('passportCustomConfigEdit_serviceRagConfigGreenUntil')}
              />
              <Input
                fullWidth
                name="serviceRagConfig.amberUntil"
                label={t('passportCustomConfigEdit_serviceRagConfigAmberUntil')}
              />
            </div>
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="submit"
                text={t('simpleModal_submit')}
                className={buttonClasses.submitButton}
              />
              <Button
                type="button"
                text={t('simpleModal_cancel')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default withTranslation(EsMetadataCustomConfig);
