export class PassportDumpIndicatorsWorker {
  constructor(worker) {
    const code = worker.toString();
    const blob = new Blob(['(' + code + ')()']);
    return new Worker(URL.createObjectURL(blob));
  }
}

export default () => {
  const indicatorCustomConfigs = [
    'NEDB_SCHOOL_SUBTYPES_VISIBILITY_CONFIG'
  ];

  const formatIndicatorCustomConfig = {
    'NEDB_SCHOOL_SUBTYPES_VISIBILITY_CONFIG': config => {
      return config.subtypes.join(', ');
    }
  };

  const walkSections = (fn, sections) => {
    sections.forEach(section => {
      fn(section);

      walkSections(fn, section.subGroups);
    });
  };

  const dumpIndicator = (csv, lang, indicator, complexMetadatas) => {
    let name = indicator[`${lang}_name`];

    if (['dynamic lookup', 'multiple dynamic lookup'].includes(indicator.type)) {
      name = `${name} [${indicator.config.lookupId}]`;
    }

    const line = [
      indicator.code, name, indicator.type
    ];

    if (indicator.customConfigs) {
      indicatorCustomConfigs.forEach(config => {
        const index = indicator.customConfigs.findIndex(({ id }) => config === id);
        if (index >= 0) {
          line.push(formatIndicatorCustomConfig[config](indicator.customConfigs[index]));
        } else {
          line.push('');
        }
      });
    }

    csv.push(line);

    if (indicator.type === 'complex') {
      const passport = complexMetadatas[indicator.code];
      if (!passport) {
        return;
      }

      const { group, questions } = passport;
      const complexCode = indicator.code;
      const sections = [];

      walkSections(section => {
          sections.push({
            id: section.id,
            name: section[`${lang}_name`],
            indicators: section.sections.length > 0 ? section.sections[0].questionCodes : []
          });
        },
        group.subGroups
      );

      sections.forEach(section => {
        section.indicators.forEach(code => {
          const indicator = questions[code];
          if (indicator) {
            let name = indicator[`${lang}_name`];

            if (['dynamic lookup', 'multiple dynamic lookup'].includes(indicator.type)) {
              name = `${name} [${indicator.config.lookupId}]`;
            }

            csv.push([`${indicator.code}/${complexCode}`, name, indicator.type]);
          }
        });
      });
    }
  };

  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', event => {
    if (!event) return;

    const { type, data } = event.data;

    switch (type) {
      case 'START_DUMP':
        postMessage({ type });

        let progress = 0;
        postMessage({ type: 'PROGRESS_DUMP', data: progress });

        const { lang, passport, complexMetadatas } = data;
        const { group } = passport;

        const sections = [{
          id: group.id,
          name: group[`${lang}_name`],
          indicators: group.sections.length > 0 ? group.sections[0].questionCodes : []
        }];

        walkSections(section => {
            sections.push({
              id: section.id,
              name: section[`${lang}_name`],
              indicators: section.sections.length > 0 ? section.sections[0].questionCodes : []
            });
          },
          group.subGroups
        );

        progress += 50;
        postMessage({ type: 'PROGRESS_DUMP', data: progress });

        const csv = [[
          'code', 'name', 'type',
          ...indicatorCustomConfigs
        ]];

        sections.forEach((section, idx) => {
          const { id, name, indicators } = section;

          csv.push([id, name, '']);

          indicators.forEach(name => {
            const indicator = passport.questions[name];
            if (indicator) {
              dumpIndicator(csv, lang, indicator, complexMetadatas);
            }
          });

          progress = 50 + Math.round((idx + 1) / sections.length * 50);
          postMessage({ type: 'PROGRESS_DUMP', data: progress });
        });

        postMessage({ type: 'FINISH_DUMP', data: csv });
        break;

      default:
        break;
    }
  });
};
