import React from 'react';
import Form from '../../../_ui/Form/Form';
import Input from '../../../_ui/Input/Input';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';
import withTranslation from '../../../components/_hoc/withTranslation';
import ExportFormsEdit from '../Common/ExportFormsEdit';
import { ExportFormMetadataCustomConfigSchema } from './ExportFormMetadataCustomConfigValidation';

const ExportFormMetadataCustomConfig = props => {
  const {
    t,
    questions,
    questionCodes,
    config,
    onSave,
    onClose
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={config}
      validate={ExportFormMetadataCustomConfigSchema}
      onSubmit={values => {
        onSave(values);
      }}
      render={(config, { setValues: setConfig }) => {
        return (
          <>
            <Input
              fullWidth
              name="id"
              label={'id'}
              disabled={true}
            />
            <div style={{ marginTop: '15px' }}>
              <h4>{t('passportCustomConfigEdit_exportFormsTitle')}</h4>
              <ExportFormsEdit
                questions={questions}
                questionCodes={questionCodes}
                exportForms={config.exportForms}
                deleteExportForm={index => {
                  const exportForms = [...config.exportForms];
                  exportForms.splice(index, 1);

                  setConfig({
                    ...config,
                    exportForms
                  });
                }}
                editExportForm={(exportForm, index) => {
                  const exportForms = [...config.exportForms];

                  if (index === -1) {
                    exportForms.push(exportForm);
                  } else {
                    exportForms[index] = { ...exportForm };
                  }

                  setConfig({
                    ...config,
                    exportForms
                  });
                }}
              />
            </div>
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="submit"
                text={t('simpleModal_submit')}
                className={buttonClasses.submitButton}
              />
              <Button
                type="button"
                text={t('simpleModal_cancel')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default withTranslation(ExportFormMetadataCustomConfig);
