import { useState, useEffect } from 'react';
import Dict from '../../utils/Dict';

export function useDictInfo(code) {
  const [dictInfo, setDictInfo] = useState(null);

  useEffect(() => {
      let isCancelled = false;

      Dict.itemInfo(code).then(info => {
        if (!isCancelled) setDictInfo(info);
      });

      return () => {
        isCancelled = true;
      };
    },
    [code]
  );

  return dictInfo;
}

export function useDictInfos(codes) {
  const [dictInfos, setDictInfos] = useState([]);

  useEffect(() => {
      let isCancelled = false;

      Dict.itemsInfo(codes).then(infos => {
        if (!isCancelled) setDictInfos(infos);
      });

      return () => {
        isCancelled = true;
      };
    },
    [codes]
  );

  return dictInfos;
}
