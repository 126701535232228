import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import withTranslation from '../../_hoc/withTranslation';
import Input from '../../../_ui/Input/Input';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';
import ExternalClientQuestionCodesConfigsEdit from './ExternalClientQuestionCodesConfigsEdit';

const ExternalClientConfigEdit = props => {
  const { t, questions, questionCodes, externalClientConfig, onClose, onSubmit } = props;

  const [clientCode, setClientCode] = useState(externalClientConfig.clientCode || '');

  const [clientName, setClientName] = useState({
    clientNameRu: externalClientConfig.clientNameRu || '',
    clientNameKk: externalClientConfig.clientNameKk || ''
  });

  const [clientApplicableRule, setClientApplicableRule] = useState(
    externalClientConfig.clientApplicableRule || ''
  );

  const [clientQuestionCodesConfigs, setClientQuestionCodesConfigs] = useState(
    externalClientConfig.clientQuestionCodesConfigs || []
  );

  const buttonClasses = useButtonStyles();

  const canSubmit =
    !!clientCode && !!clientName.clientNameRu && !!clientName.clientNameKk;

  return (
    <>
      <Input
        withoutForm
        fullWidth
        placeholder={t(
          'passportCustomConfigEdit_externalClientMetadata_clientConfig_clientCode'
        )}
        autoFocus={true}
        value={clientCode}
        onChange={event => setClientCode(event.target.value)}
      />
      <Input
        withoutForm
        fullWidth
        placeholder={t(
          'passportCustomConfigEdit_externalClientMetadata_clientConfig_clientNameRu'
        )}
        value={clientName.clientNameRu}
        onChange={event =>
          setClientName(value => ({ ...value, clientNameRu: event.target.value }))
        }
      />
      <Input
        withoutForm
        fullWidth
        placeholder={t(
          'passportCustomConfigEdit_externalClientMetadata_clientConfig_clientNameKk'
        )}
        value={clientName.clientNameKk}
        onChange={event =>
          setClientName(value => ({ ...value, clientNameKk: event.target.value }))
        }
      />
      <TextField
        fullWidth
        multiline
        placeholder={t(
          'passportCustomConfigEdit_externalClientMetadata_clientConfig_clientApplicableRule'
        )}
        autoComplete="false"
        variant="filled"
        rows="10"
        value={clientApplicableRule}
        onChange={event => setClientApplicableRule(event.target.value)}
        margin="normal"
      />
      <div style={{ marginTop: '15px' }}>
        <h4>
          {t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_clientQuestionCodesConfigsTitle'
          )}
        </h4>
        <ExternalClientQuestionCodesConfigsEdit
          questions={questions}
          questionCodes={questionCodes}
          data={clientQuestionCodesConfigs}
          onChange={setClientQuestionCodesConfigs}
        />
      </div>
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          disabled={!canSubmit}
          onClick={() =>
            onSubmit({
              clientCode,
              clientApplicableRule,
              clientQuestionCodesConfigs,
              ...clientName
            })
          }
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default withTranslation(ExternalClientConfigEdit);
