import React, { useState, useMemo } from 'react';
import { difference, flatten } from 'lodash';
import withTranslation from '../../_hoc/withTranslation';
import Select from '../../../_ui/Select/Select';
import Input from '../../../_ui/Input/Input';
import Checkbox from '../../../_ui/Checkbox/Checkbox';
import Button from '../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../_styles/ButtonsStyle';

const ExternalClientQuestionCodeEdit = props => {
  const {
    t,
    lang,
    questions,
    questionCodes,
    excludedQuestionCodes = [],
    requiredQuestionCode,
    onClose,
    onSubmit
  } = props;
  const [questionCode, setQuestionCode] = useState(
    requiredQuestionCode.questionCodeInner
  );

  const [manualEdit, setManualEdit] = useState(false);

  const options = useMemo(() => {
    const { __root__, ...rest } = questionCodes;

    return flatten(
      Object.keys({ __root__, ...rest }).map(key =>
        difference(
          questionCodes[key],
          excludedQuestionCodes.filter(code => code !== questionCode)
        ).map(code => ({
          label: `[${key === '__root__' ? '' : `${key}:`}${code}] ${
            questions[key][code] ? questions[key][code][`${lang}_name`] : 'external'
          }`,
          value: code
        }))
      )
    );
  }, [questions, questionCodes, excludedQuestionCodes, questionCode, lang]);

  const [questionCodeOuter, setQuestionCodeOuter] = useState(
    requiredQuestionCode.questionCodeOuter
  );
  const [questionCodeValue, setQuestionCodeValue] = useState(
    requiredQuestionCode.questionCodeValue
  );
  const [questionCodeAsStatic, setQuestionCodeAsStatic] = useState(
    requiredQuestionCode.questionCodeAsStatic
  );

  const canSubmit =
    (!!questionCode && !!questionCodeOuter) ||
    (!!questionCodeOuter && !!questionCodeValue);

  const buttonClasses = useButtonStyles();

  return (
    <>
      <div style={{ height: '400px' }}>
        <Select
          withoutForm
          fullWidth
          searchable={true}
          autoFocus={true}
          label={t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeInner'
          )}
          options={options}
          value={questionCode}
          disabled={manualEdit}
          onChange={setQuestionCode}
        />
        <Checkbox
          withoutForm
          fullWidth
          label={t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_manualEdit'
          )}
          value={manualEdit}
          onChange={() => setManualEdit(value => !value)}
        />
        {manualEdit && (
          <Input
            withoutForm
            fullWidth
            placeholder={t(
              'passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeInner'
            )}
            autoFocus={manualEdit}
            value={questionCode}
            onChange={event => setQuestionCode(event.target.value)}
          />
        )}
        <Input
          withoutForm
          fullWidth
          placeholder={t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeOuter'
          )}
          value={questionCodeOuter}
          onChange={event => setQuestionCodeOuter(event.target.value)}
        />
        <Input
          withoutForm
          fullWidth
          placeholder={t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodesEdit_questionCodeValue'
          )}
          value={questionCodeValue}
          onChange={event => setQuestionCodeValue(event.target.value)}
        />
        <Checkbox
          withoutForm
          fullWidth
          label={t(
            'passportCustomConfigEdit_externalClientMetadata_clientConfig_questionCodeAsStatic'
          )}
          value={questionCodeAsStatic}
          onChange={() => setQuestionCodeAsStatic(value => !value)}
        />
      </div>
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          disabled={!canSubmit}
          onClick={() =>
            onSubmit({
              questionCodeInner: questionCode,
              questionCodeOuter,
              questionCodeValue,
              questionCodeAsStatic
            })
          }
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default withTranslation(ExternalClientQuestionCodeEdit);
