import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from "redux";
import ExitIcon from '@material-ui/icons/ExitToApp';

import InheritButton from '../../_ui/Button/InheritButton';
import routers from '../../_helpers/routers';
import withTranslation from '../_hoc/withTranslation';
import { LocaleBtns, useStyles } from './HeaderStyle';
import { logout } from "../../pages/LoginPage/LoginDucks";
import { changeLocale } from "../LocaleProvider/LocaleDucks";
import IconButton from "../../_ui/Button/IconButton";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

function Header({ user, t, logout, lang, changeLocale, pathname }) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Link className={classes.logo} to={routers.homePage.path}>
          <Typography variant="h6" noWrap>
            Конструктор метаданных
          </Typography>
        </Link>
        <Breadcrumbs pathname={pathname} />
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <LocaleBtns lang={lang}>
            <InheritButton
              size="small"
              text="ҚАЗ"
              vaiant="outlined"
              onClick={() => changeLocale('kk')}
            />
            <InheritButton
              size="small"
              text="РУС"
              vaiant="outlined"
              onClick={() => changeLocale('ru')}
            />
          </LocaleBtns>
          {
            user &&
            <IconButton
              icon={<ExitIcon style={{fontSize: '1.4em'}} />}
              onClick={logout}
              className={classes.exitButton}
            />
          }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default compose(
  withTranslation,
  connect(null, { logout, changeLocale })
)(Header);
