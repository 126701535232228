import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Permissions } from '../../_helpers/Permissions';
import Select from '../../_ui/Select/Select';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import FlexContainer from '../../_styles/FlexContainer';
import SectionsTree from '../../components/SectionsTree/SectionsTree';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import PassportSave from '../../components/PassportSave/PassportSave';
import { gcQuestions } from '../../components/PassportEdit/PassportEditUtils';
import { collectSectionRuleMethods } from '../../components/SectionsTree/SectionsTreeUtils';
import {
  passportsModule,
  getPassportsVersions,
  getPassport,
  setPassport,
  updatePassport
} from '../../pages/PassportsPage/PassportsDucks';
import {
  sectionsModule,
  setSections,
  deleteSection,
  editSection,
  addSection,
  addSubSection,
  moveSection
} from './SectionsDucks';
import { buildNewSections } from './SectionsPageUtils';
import { Title } from './SectionsPageStyle';

const buildSections = (lang, sections, acc = [], root = true, isParentRemoved) => {
  if (root) {
    return buildSections(lang, sections, [], false, isParentRemoved);
  }

  for (let i = 0; i < sections.length; i++) {
    const { id, ru_name, kk_name, en_name, subGroups, isRemoved, isEdited, isAdded } = sections[i];

    if (subGroups.length > 0) {
      acc.push({
        id,
        title: (
          <Title
            isRemoved={isRemoved || isParentRemoved}
            isEdited={isEdited}
            isAdded={isAdded}
          >
            {sections[i][`${lang}_name`]}
          </Title>
        ),
        children: buildSections(lang, subGroups, [], false, (isRemoved || isParentRemoved)),
        names: { ru_name, kk_name, en_name },
        rules: collectSectionRuleMethods(sections[i]),
        expanded: true,
        isRemoved: isRemoved || isParentRemoved
      });
    } else {
      acc.push({
        id,
        title: (
          <Title
            isRemoved={isRemoved || isParentRemoved}
            isEdited={isEdited}
            isAdded={isAdded}
          >
            {sections[i][`${lang}_name`]}
          </Title>
        ),
        names: { ru_name, kk_name, en_name },
        rules: collectSectionRuleMethods(sections[i]),
        isRemoved: isRemoved || isParentRemoved
      });
    }
  }

  return acc;
};

const SectionsPage = props => {
  const {
    t, lang,
    passportsLoading,
    passportsInfo,
    getPassportsVersions,
    passport,
    isPassportChanged,
    getPassport,
    setPassport,
    sections,
    setSections,
    deleteSection,
    editSection,
    addSection,
    addSubSection,
    updatePassport,
    passportVersion,
    moveSection
  } = props;

  const [treeData, setTreeData] = useState([]);
  const [openSavePassport, setOpenSavePassport] = useState(false);

  useEffect(() => {
      getPassportsVersions();
    },
    [getPassportsVersions]
  );

  useEffect(() => {
      if (passport) {
        setSections(passport);
      }
    },
    [passport, setSections]
  );

  useEffect(() => {
      if (passport) {
        const { ru_name, kk_name, en_name, id } = passport;
        const tree = [{
          title: passport[`${lang}_name`],
          children: buildSections(lang, sections),
          expanded: true,
          main: true,
          rules: collectSectionRuleMethods(passport),
          names: { ru_name, kk_name, en_name },
          id
        }];
        setTreeData(tree);
      } else {
        setTreeData([]);
      }
    },
    [lang, passport, sections]
  );

  const toggleSavePassport = () =>
    setOpenSavePassport(open => !open);

  const onSavePassport = (currentVersion, commitComment) => {
    const newSections = buildNewSections(sections);

    updatePassport({
        ...passport,
        group: {
          ...passport.group,
          subGroups: newSections
        },
        questions: gcQuestions(newSections, passport.questions)
      },
      currentVersion ? passportVersion : null,
      commitComment,
      toggleSavePassport
    );
  };

  const onResetPassport = () => {
    setSections(passport);
    toggleSavePassport();
  };

  return (
    <>
      <FlexContainer
        justifySpaceBetween
        itemsCenter
        style={{ marginBottom: '15px' }}
      >
        <div style={{ width: '80%' }}>
          <Select
            withoutForm
            fullWidth
            label={t('sectionsPage_choosePassport')}
            loading={passportsLoading}
            disabled={isPassportChanged}
            options={passportsInfo.map(x => ({ label: x.id, value: x.id }))}
            value={passport && passport.id}
            onChange={name => {
              if (name) {
                getPassport(name);
              } else {
                setPassport(null);
              }
            }}
          />
        </div>
        <Access permissions={[Permissions.savePassport.code]}>
          <Button
            type="button"
            text={t('sectionsPage_save')}
            disabled={!isPassportChanged}
            onClick={toggleSavePassport}
          />
        </Access>
      </FlexContainer>
      <SectionsTree
        treeData={treeData}
        deleteSection={deleteSection}
        editSection={editSection}
        addSection={addSection}
        addSubSection={addSubSection}
        moveSection={moveSection}
      />
      <Access permissions={[Permissions.savePassport.code]}>
        <Modal
          open={openSavePassport}
          onClose={toggleSavePassport}
          title={t('indicatorsPage_savePassportTitle')}
        >
          <PassportSave
            loading={passportsLoading}
            onClose={toggleSavePassport}
            onReset={onResetPassport}
            onSave={onSavePassport}
          />
        </Modal>
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      passportsLoading: state[passportsModule].loading,
      passportsInfo: state[passportsModule].passportsInfo,
      passport: state[passportsModule].passport,
      passportVersion: state[passportsModule].passportVersion,
      isPassportChanged: state[passportsModule].isPassportChanged,
      sections: state[sectionsModule].sections
    }),
    {
      getPassportsVersions,
      getPassport,
      setPassport,
      updatePassport,
      setSections,
      deleteSection,
      editSection,
      addSection,
      addSubSection,
      moveSection
    })
)(SectionsPage);
