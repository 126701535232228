import React from 'react';
import { CssBaseline } from '@material-ui/core';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { useStyles } from './ContentStyle';
import ControlRefreshToken from "../ControlRefreshToken/ControlRefreshToken";

function Content({ user, children, pathname }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header user={user} pathname={pathname} />
      {
        user &&
        <>
          <Sidebar />
          <ControlRefreshToken />
        </>
      }
      <main className={classes.content}>
        {children}
      </main>
    </div>
  )
}

export default Content;