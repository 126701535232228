import * as Yup from 'yup';
import translate from '../../utils/translate';

const initialIndicatorSchemaShape = {
  code: Yup.string(() => translate('indicatorEditValidation_codeHint'))
    .matches(/^[A-Za-z0-9_]+$/, () => translate('indicatorEditValidation_codeMatch'))
    .required(() => translate('indicatorEditValidation_codeRequired')),
  ru_name: Yup.string(() =>
    translate('indicatorEditValidation_ruNameHint')
  ).required(() => translate('indicatorEditValidation_ruNameRequired')),
  kk_name: Yup.string(() =>
    translate('indicatorEditValidation_kkNameHint')
  ).required(() => translate('indicatorEditValidation_kkNameRequired')),
  en_name: Yup.string(() =>
    translate('indicatorEditValidation_enNameHint')
  ).required(() => translate('indicatorEditValidation_enNameRequired')),
  type: Yup.string(() => translate('indicatorEditValidation_typeHint')).required(() =>
    translate('indicatorEditValidation_typeRequired')
  ),
  recurrent: Yup.bool(() => translate('indicatorEditValidation_recurrentHint')),
  renderer: Yup.string(() =>
    translate('indicatorEditValidation_rendererHint')
  ).required(() => translate('indicatorEditValidation_rendererRequired')),
  alwaysRequired: Yup.bool(() => translate('indicatorEditValidation_alwaysRequired')),
  keepIfHidden: Yup.bool(() => translate('indicatorEditValidation_keepIfHidden'))
};

export const makeIndicatorSchema = type => {
  switch (type) {
    case 'dynamic lookup':
    case 'multiple dynamic lookup':
      return Yup.object().shape({
        ...initialIndicatorSchemaShape,
        '#config#lookupId': Yup.string(() =>
          translate('indicatorEditValidation_dynamicLookupIdHint')
        ).required(() => translate('indicatorEditValidation_dynamicLookupIdRequired'))
      });

    case 'document':
      return Yup.object().shape({
        ...initialIndicatorSchemaShape,
        '#config#metadataKey': Yup.string(() =>
          translate('indicatorEditValidation_documentMetadataKeyHint')
        ).required(() => translate('indicatorEditValidation_documentMetadataKeyRequired'))
      });

    case 'complex':
      return Yup.object().shape({
        ...initialIndicatorSchemaShape,
        '#config#metadataKey': Yup.string(() =>
          translate('indicatorEditValidation_complexMetadataKeyHint')
        ).required(() => translate('indicatorEditValidation_complexMetadataKeyRequired')),
        '#config#ru_name': Yup.string(() =>
          translate('indicatorEditValidation_ruNameHint')
        ).required(() => translate('indicatorEditValidation_ruNameRequired')),
        '#config#kk_name': Yup.string(() =>
          translate('indicatorEditValidation_kkNameHint')
        ).required(() => translate('indicatorEditValidation_kkNameRequired')),
        '#config#en_name': Yup.string(() =>
          translate('indicatorEditValidation_enNameHint')
        ).required(() => translate('indicatorEditValidation_enNameRequired')),
        '#config#pageSize': Yup.number(() =>
          translate('indicatorEditValidation_complexPageSizeHint')
        )
          .integer()
          .transform(value => (isNaN(value) ? 0 : value))
          .required(() => translate('indicatorEditValidation_complexPageSizeRequired'))
      });

    case 'table':
      return Yup.object().shape({
        ...initialIndicatorSchemaShape,
        '#cellType#recurrent': Yup.bool(() =>
          translate('indicatorEditValidation_cellTypeRecurrentHint')
        ),
        '#cellType#type': Yup.string(() =>
          translate('indicatorEditValidation_cellTypeTypeHint')
        ).required(() => translate('indicatorEditValidation_cellTypeTypeRequired')),
        '#cellType#renderer#id': Yup.string(() =>
          translate('indicatorEditValidation_cellTypeRendererHint')
        ).required(() => translate('indicatorEditValidation_cellTypeRendererRequired'))
      });

    default:
      break;
  }

  return Yup.object().shape(initialIndicatorSchemaShape);
};
