import translate from './translate';

export function isResponseError(error) {
  return error && error.response && error.response.data;
}

export function getResponseErrorCode(response, defaultErrorCode = 'UNKNOWN_ERROR') {
  const { data } = response;

  if (
    data.result &&
    data.result.errors &&
    Array.isArray(data.result.errors)
  ) {
    const error = data.result.errors[0];

    return error.errorCode || error.code || defaultErrorCode;
  }

  if (data.errors && Array.isArray(data.errors) && data.errors.length > 0) {
    const error = data.errors[0];

    return error.errorCode || error.code || defaultErrorCode;
  }

  if (data.status) {
    return data.status;
  }

  return null;
}

function isTimeoutError(error) {
  return error && error.code && error.code === 'ECONNABORTED';
}

function isNetworkError(error) {
  return error instanceof Error && error.message === 'Network Error';
}

export function getErrorCode(error, defaultErrorCode = 'UNKNOWN_ERROR') {
  if (isResponseError(error)) {
    const errorCode = getResponseErrorCode(error.response, defaultErrorCode);
    if (errorCode) {
      return errorCode;
    }
  }

  if (isTimeoutError(error)) {
    return 'NETWORK_TIMEOUT';
  }

  if (isNetworkError(error)) {
    return 'NETWORK_ERROR';
  }

  return defaultErrorCode;
}

export function translateError(errorCode, errorMessage) {
  if(errorMessage) {
    return `${translate(errorMessage)}: ${translate(`apiErrors_${errorCode}`)}`
  }

  return translate(`apiErrors_${errorCode}`);
}
