import axios from 'axios';

let baseUrl = process.env.REACT_APP_BASEURL;
let serviceUrl = baseUrl;
let protocol = window.location.protocol;
let appName = '';

switch (window.location.hostname) {
  case 'es-metadata.iac.kz':
    baseUrl = `${protocol}//es-api.iac.kz`;
    serviceUrl = `${protocol}//nobd-services.iac.kz`;
    appName = 'ES_APP';
    break;
  case 'es-metadata-test.iac.kz':
    baseUrl = `${protocol}//es-api-test.iac.kz`;
    serviceUrl = `${protocol}//nobd-services-test.iac.kz`;
    appName = 'ES_APP';
    break;
  case 'nobd-config.iac.kz':
    baseUrl = `${protocol}//nobd-api.iac.kz`;
    serviceUrl = `${protocol}//nobd-services.iac.kz`;
    appName = 'NEDB_APP';
    break;
  case 'nobd-config-test.iac.kz':
    baseUrl = `${protocol}//nobd-api-test.iac.kz`;
    serviceUrl = `${protocol}//nobd-services-test.iac.kz`;
    appName = 'NEDB_APP';
    break;
  case 'es-metadata.e-zhetysu.kz':
    baseUrl = `${protocol}//es-api.e-zhetysu.kz`;
    serviceUrl = `${protocol}//es-services.e-zhetysu.kz`;
    appName = 'ES_APP';
    break;
  case 'es-metadata-test.e-zhetysu.kz':
    baseUrl = `${protocol}//es-api-test.e-zhetysu.kz`;
    serviceUrl = `${protocol}//es-services-test.e-zhetysu.kz`;
    appName = 'ES_APP';
    break;
  case '192.168.9.154':
    baseUrl = `${protocol}//192.168.9.154/api:runtime`;
    serviceUrl = `${protocol}//192.168.9.154/api:services`;
    appName = 'IQYZMET_APP';
    break;
  case 'iqyzmet.gov.kz':
    baseUrl = `${protocol}//iqyzmet.gov.kz/api:runtime`;
    serviceUrl = `${protocol}//iqyzmet.gov.kz/api:services`;
    appName = 'IQYZMET_APP';
    break;
  case 'iqyzmet.dlife.kz':
    baseUrl = `${protocol}//iqyzmet.dlife.kz/api:runtime`;
    serviceUrl = `${protocol}//iqyzmet.dlife.kz/api:services`;
    appName = 'IQYZMET_APP';
    break;
  case 'gu.almobl.kz':
    baseUrl = `${protocol}//api.gu.almobl.kz`;
    serviceUrl = `${protocol}//services.gu.almobl.kz`;
    appName = 'ES_APP';
    break;
  case 'metadata.gu.almobl.kz':
    baseUrl = `${protocol}//api.gu.almobl.kz`;
    serviceUrl = `${protocol}//services.gu.almobl.kz`;
    appName = 'ES_APP';
    break;
  default:
    baseUrl = window.location.origin;
}

export const Api = axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' }
});

export const ServiceApi = axios.create({
  baseURL: serviceUrl,
  headers: { 'Content-Type': 'application/json' }
});

const configureAuth = config => {
  if (!config.headers.Authorization) {
    const newConfig = {
      headers: {},
      ...config
    };
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  }
  return config;
};

const unauthorizedResponse = async error => {
  if (
    error.response &&
    error.response.status === 401 &&
    window.location.pathname !== '/' &&
    error.response.config.url.indexOf('/login') === -1
  ) {
    window.history.go('/');
  }
  return Promise.reject(error);
};

Api.interceptors.request.use(configureAuth, e => Promise.reject(e));
Api.interceptors.response.use(r => r, unauthorizedResponse);

export const LoginApi = {
  login: data => Api.post('/login', data),
  logout: () => Api.get('/logout'),
  refreshUser: token =>
    Api.post('/token/refresh', null, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }),

  getUser: () => Api.get('/login')
};

export const PassportsApi = {
  getPassportsVersions: () =>
    Api.get(
      `/api/public/metadata?excludeDynamicTables=true&excludeDocumentTypes=true&excludeQuestionnaire=true`
    ),

  getPassport: (name, version = null, includeRecipients = true) =>
    Api.get(
      `/api/public/metadata/${name}?includeRecipients=${includeRecipients}${
        version ? `&version=${version}` : ''
      }`
    ),

  getMetadata: name => Api.get(`/api/public/metadata/${name}`),

  addPassport: (passport, commitComment) => {
    const { recipients, ...metadata } = passport;
    const description =
      passport.en_name || passport.ru_name || passport.kk_name || passport.id;

    return Api.post(`/api/public/metadata`, {
      type: passport.id,
      value: metadata,
      version: '1',
      description: description.slice(0, 255),
      comment: commitComment.slice(0, 500),
      recipients
    });
  },

  updatePassport: (passport, commitComment, version = null) => {
    const { recipients, ...metadata } = passport;
    const description =
      passport.en_name || passport.ru_name || passport.kk_name || passport.id;

    return Api.put(`/api/public/metadata`, {
      type: passport.id,
      value: metadata,
      version: `${version || ''}`,
      description: description.slice(0, 255),
      comment: commitComment.slice(0, 500),
      recipients
    });
  },

  addMetadata: metadata => {
    const description =
      metadata.en_name || metadata.ru_name || metadata.kk_name || metadata.id;

    return Api.post(`/api/public/metadata`, {
      type: metadata.id,
      value: metadata,
      version: '',
      description: description.slice(0, 255)
    });
  },

  updateMetadata: metadata => {
    const description =
      metadata.en_name || metadata.ru_name || metadata.kk_name || metadata.id;

    return Api.put(`/api/public/metadata`, {
      type: metadata.id,
      value: metadata,
      version: '',
      description: description.slice(0, 255)
    });
  },

  deletePassport: (name, version = null) =>
    Api.delete(
      `/api/public/master/metadata/${name}${version ? `/version/${version}` : ''}`
    ),

  deleteAllPassportVersions: name => Api.delete(`/api/public/metadata/${name}/all`),

  exportPassport: (ids, onDownloadProgress) =>
    Api.get(`/api/public/metadata/export?ids=${ids.join(',')}`, {
      responseType: 'arraybuffer',
      onDownloadProgress
    }),

  importPassport: (formData, onUploadProgress) =>
    Api.post('/api/public/metadata/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    }),

  clonePassport: (version, id, cloneId) =>
    Api.post(`/api/public/metadata/${id}/${version}/clone/${cloneId}`)
};

export const DictionaryApi = {
  getDictionaries: () => ServiceApi.get('/nedb-ddm/api/v1/domainsets')
};

export const getAppName = () => appName;
