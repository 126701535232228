import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import withTranslation from '../../components/_hoc/withTranslation';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';

const RuleEdit = props => {
  const {
    t,
    rule,
    onClose,
    onSubmit
  } = props;

  const [method, setMethod] = useState(rule.method);

  const buttonClasses = useButtonStyles();

  return (
    <>
      <TextField
        fullWidth
        multiline
        autoComplete="false"
        autoFocus={true}
        variant="filled"
        rows="10"
        placeholder={rule.type}
        value={method}
        onChange={event => setMethod(event.target.value)}
        margin="normal"
      />
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          onClick={() => onSubmit(rule.type, method)}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
    </>
  );
};

export default withTranslation(RuleEdit);
