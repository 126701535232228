import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { history } from '../../_helpers/store';
import routers from '../../_helpers/routers';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import { Permissions } from '../../_helpers/Permissions';
import IndicatorEditComplex from '../../components/IndicatorEditComplex/IndicatorEditComplex';
import {
  indicatorEditModule,
  updateComplexIndicator,
  setComplexIndicator
} from '../../components/IndicatorEdit/IndicatorEditDucks';
import {
  indicatorsModule
} from '../IndicatorsPage/IndicatorsDucks';

const IndicatorsEditComplexPage = props => {
  const {
    t,
    indicatorIndex,
    loadingComplexIndicators,
    complexIndicators,
    complexIndicatorIndex,
    updateComplexIndicator,
    setComplexIndicator
  } = props;

  useEffect(() => {
      if (indicatorIndex === null) {
        history.push(routers.indicatorsPage.path);
      }
    },
    [indicatorIndex]
  );

  const onSaveIndicator = indicator => {
    updateComplexIndicator(
      indicator,
      complexIndicatorIndex,
      () => {
        setComplexIndicator(null);
        history.push(indicatorIndex === -1 ? routers.indicatorsAddPage.path : routers.indicatorsEditPage.path)
      }
    );
  };

  const onCancelIndicator = () => {
    history.push(indicatorIndex === -1 ? routers.indicatorsAddPage.path : routers.indicatorsEditPage.path);
  };

  return (
    <>
      <h3>{t('indicatorsEditComplexPage_title')}</h3>
      <Access permissions={[Permissions.addIndicator.code, Permissions.editIndicator.code]}>
        {complexIndicatorIndex !== null &&
        <IndicatorEditComplex
          loading={loadingComplexIndicators}
          indicator={complexIndicators[complexIndicatorIndex]}
          onCancel={onCancelIndicator}
          onSubmit={onSaveIndicator}
        />
        }
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      indicatorIndex: state[indicatorsModule].indicatorIndex,
      loadingComplexIndicators: state[indicatorEditModule].loading,
      complexIndicators: state[indicatorEditModule].complexIndicators,
      complexIndicatorIndex: state[indicatorEditModule].complexIndicatorIndex
    }),
    {
      updateComplexIndicator,
      setComplexIndicator
    })
)(IndicatorsEditComplexPage);
