import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Input from '../../_ui/Input/Input';
import SimpleModal from '../../_ui/Modal/SimpleModal';
import withTranslation from '../_hoc/withTranslation';
import { passportsModule } from '../../pages/PassportsPage/PassportsDucks';
import { getRandomDigits } from '../../utils/random';
import RulesEdit from '../RulesEdit/RulesEdit';
import { ADD_SECTION_MODE, ADD_SUBSECTION_MODE, EDIT_SECTION_MODE, REMOVE_SECTION_MODE } from './SectionsTree';
import { SectionEditSchema } from './SectionsTreeValidation';
import { collectSectionRuleMethods } from './SectionsTreeUtils';

function getTitle(mode, t) {
  switch (mode) {
    case EDIT_SECTION_MODE:
      return t('sectionsPage_editingSection');
    case ADD_SECTION_MODE:
      return t('sectionsPage_addingSection');
    case ADD_SUBSECTION_MODE:
      return t('sectionsPage_addingSubSection');
    case REMOVE_SECTION_MODE:
      return t('sectionsPage_removingSection');
    default:
      return '';
  }
}

const SectionsTreeModal = ({ isOpen, close, onSubmit, t, mode, modification, lang, values }) => {
  const code = getRandomDigits(6);
  const codeValidation = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .required(() => t('common_required'))
          .test('is-equal', t('sectionsTree_notEqual'), value => value === code)
      }),
    [t, code]
  );

  const initialValues = mode => {
    switch (mode) {
      case EDIT_SECTION_MODE:
      case REMOVE_SECTION_MODE:
        return values;

      case ADD_SECTION_MODE:
      case ADD_SUBSECTION_MODE:
        return { rules: collectSectionRuleMethods() };

      default:
        break;
    }

    return {};
  };

  return (
    <SimpleModal
      open={isOpen}
      onClose={() => close(false)}
      onSubmit={onSubmit}
      title={getTitle(mode, t)}
      validate={mode === REMOVE_SECTION_MODE ? codeValidation : SectionEditSchema}
      initialValues={initialValues(mode)}
      loading={modification}
      render={(values, { setValues }) => {
        if (mode === REMOVE_SECTION_MODE) {
          return (
            <>
              <h4>{values[`${lang}_name`]}</h4>
              <p>
                {t('sectionsTree_removeSection')}
                <b>{code}</b>
              </p>
              <Input
                fullWidth
                label={t('sectionTree_enterCode')}
                name="code"
                type="number"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </>
          );
        }

        return (
          <>
            <Input
              fullWidth
              autoTrim
              label={t('common_id')}
              name="id"
            />
            <Input
              fullWidth
              autoTrim
              label={t('common_ruName')}
              name="ru_name"
            />
            <Input
              fullWidth
              autoTrim
              label={t('common_kkName')}
              name="kk_name"
            />
            <Input
              fullWidth
              autoTrim
              label={t('common_enName')}
              name="en_name"
            />
            <div style={{ marginBottom: '15px' }}>
              <h4>{t('sectionTree_flkTitle')}</h4>
              <RulesEdit
                minRows={4}
                title={t('sectionTree_flkEditTitle')}
                loading={modification}
                rules={values === null ? [] : values.rules || []}
                editRule={(type, method) => {
                  const index = values.rules.findIndex(rule => rule.type === type);

                  if (index >= 0) {
                    const rules = [...values.rules];
                    rules[index].method = method;

                    setValues({
                      ...values,
                      rules
                    });
                  }
                }}
              />
            </div>
          </>
        );
      }}
    />
  );
};

const mapStateToProps = state => ({
  modification: state[passportsModule].sectionModification
});

export default compose(
  withTranslation,
  connect(mapStateToProps)
)(SectionsTreeModal);
