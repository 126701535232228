import LoginPage from '../pages/LoginPage/LoginPage';
import HomePage from '../pages/HomePage/HomePage';
import PassportsPage from '../pages/PassportsPage/PassportsPage';
import SectionsPage from '../pages/SectionsPage/SectionsPage';
import IndicatorsPage from '../pages/IndicatorsPage/IndicatorsPage';
import IndicatorsAddPage from '../pages/IndicatorsAddPage/IndicatorsAddPage';
import IndicatorsEditPage from '../pages/IndicatorsEditPage/IndicatorsEditPage';
import IndicatorsAddComplexPage from '../pages/IndicatorsAddComplexPage/IndicatorsAddComplexPage';
import IndicatorsEditComplexPage from '../pages/IndicatorsEditComplexPage/IndicatorsEditComplexPage';
import { Permissions } from './Permissions';
import translate from '../utils/translate';

const routers = {
  loginPage: {
    path: '/login',
    component: LoginPage,
    withoutAuth: true
  },
  homePage: {
    path: '/',
    component: HomePage,
    permissions: [
      Permissions.homePage.code
    ]
  },
  passportsPage: {
    path: '/passports',
    component: PassportsPage,
    permissions: [
      Permissions.passportsPage.code
    ],
    name: () => translate('passportsPage_name')
  },
  sectionsPage: {
    path: '/sections',
    component: SectionsPage,
    permissions: [
      Permissions.sectionsPage.code
    ],
    name: () => translate('sectionsPage_name')
  },
  indicatorsPage: {
    path: '/indicators',
    component: IndicatorsPage,
    permissions: [
      Permissions.indicatorsPage.code
    ],
    name: () => translate('indicatorsPage_name')
  },
  indicatorsAddPage: {
    path: '/indicators/add',
    component: IndicatorsAddPage,
    permissions: [
      Permissions.indicatorsAddPage.code
    ],
    name: () => translate('indicatorsAddPage_name')
  },
  indicatorsEditPage: {
    path: '/indicators/edit',
    component: IndicatorsEditPage,
    permissions: [
      Permissions.indicatorsEditPage.code
    ],
    name: () => translate('indicatorsEditPage_name')
  },
  indicatorsAddComplexPage: {
    path: '/indicators/addComplex',
    component: IndicatorsAddComplexPage,
    permissions: [
      Permissions.indicatorsAddComplexPage.code
    ],
    name: () => translate('indicatorsAddComplexPage_name')
  },
  indicatorsEditComplexPage: {
    path: '/indicators/editComplex',
    component: IndicatorsEditComplexPage,
    permissions: [
      Permissions.indicatorsEditComplexPage.code
    ],
    name: () => translate('indicatorsEditComplexPage_name')
  }
};

export default routers;
