import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import withTranslation from '../../components/_hoc/withTranslation';
import StaticTable from '../../_ui/Table/StaticTableMulti';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import { PassportsApi } from '../../_helpers/service';
import { Notice } from '../../utils/Notice';
import { getErrorCode, translateError } from '../../utils/apiError';
import translate from '../../utils/translate';

const PassportExport = ({ t, onClose, ids }) => {
  const [selection, setSelection] = useState(ids);

  const [exporting, setExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);

  const exportPassport = onSuccess => {
    setExporting(true);
    setExportProgress(0);

    PassportsApi.exportPassport(selection, ({ loaded, total }) => {
      setExportProgress(Math.round((100 * loaded) / total));
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'octet/stream' }));
        const link = document.createElement('a');
        const filename = selection.length === 1 ? selection[0] : `${selection[0]}+`;

        link.href = url;
        link.setAttribute('download', `${filename}.zip`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);

        if (onSuccess) {
          onSuccess();
        }

        Notice.success(translate('passportExport_exportPassportSuccess'));
      })
      .catch(error => {
        const errorCode = getErrorCode(error);
        Notice.error(translateError(errorCode, 'passportExport_exportPassportError'));
      })
      .finally(() => {
        setExporting(false);
        setExportProgress(0);
      });
  };

  const buttonClasses = useButtonStyles();

  return (
    <div style={{ marginTop: '30px' }}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography>{t('passportExport_title')}</Typography>
        </Grid>
        <Grid item>
          <StaticTable
            withoutHeader
            filterable={false}
            columns={[
              {
                Header: t('common_id'),
                accessor: 'id'
              }
            ]}
            data={ids.map(id => ({ id }))}
            selection={selection}
            setSelection={setSelection}
          />
        </Grid>
        <Grid item>
          <div className={buttonClasses.buttonsGroup}>
            <Button
              type="button"
              text={`${t('passportExport_export')}${exporting ? ` ...${exportProgress}%` : ''}`}
              className={buttonClasses.submitButton}
              disabled={exporting || selection.length === 0}
              onClick={() => exportPassport(onClose)}
            />
            <Button
              type="button"
              color="secondary"
              text={t('simpleModal_close')}
              onClick={onClose}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withTranslation(PassportExport);
