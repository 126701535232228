import styled from 'styled-components';

export const Div = styled.div`
  .ReactTable .rt-table {
    overflow: inherit;
  }
  .rt-thead.-header {
    height: 40px;
    position: sticky;
    display: flex;
    top: 0;
    z-index: 110;
    background-color: white;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  }
  .rt-thead.-filters {
    position: sticky;
    top: 40px;
    z-index: 101;
    background-color: white;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    
    .rt-th {
      overflow: inherit !important;
    }
  }

  .rt-thead .rt-th {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .ReactTable {
    z-index: 1;
    border-top: none;
  }

  .top-block {
    position: sticky;
    top: 0;
    height: 45px;
    background-color: white;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    z-index: 111;
    padding: 5px;
    text-align: right;
    button { background-color: white; }
    button:focus { background-color: inherit; }
    button:hover { background-color: #cacbcd; }
    input {
      margin-right: 10px;
      width: 250px;
      transition: width 0.3s;
    }
    input:focus { width: 300px; }
  }


  .ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
  }

  .ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: none;
  }

  .rt-sort {
    display: inline-block;
    margin-left: 5px;
    margin-top: -20px;
    transform: translateY(5px);
    .up, .down {
      opacity: 0.3;
    }
  }

  .-sort-asc .rt-sort .up {
    opacity: 0.8;
  }

  .-sort-desc .rt-sort .down {
    opacity: 0.8;
  }
`;

export const LoadingBox = styled.div`
  z-index: 100 !important;
  .-loading-inner {
    margin-top: 30px;
  }
`;
