import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const InputField = styled(TextField)`
  margin: 0;

  input {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
