import React, { useState } from 'react';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import StaticTable from '../../../_ui/Table/StaticTable';
import Checkbox from '../../../_ui/Checkbox/Checkbox';
import Modal from '../../../_ui/Modal/Modal';
import Button from '../../../_ui/Button/Button';
import withTranslation from '../../_hoc/withTranslation';
import ExternalClientQuestionCodeEdit from './ExternalClientQuestionCodeEdit';
import { useStyles } from './ExternalClientConfigsStyles';

const unshowData = showData =>
  showData.filter(({ questionCodeInner }) => questionCodeInner !== null);

const ExternalClientQuestionCodesConfigsEdit = props => {
  const { t, questions, questionCodes, data, onChange } = props;

  const classes = useStyles();

  const [questionCodeEditIndex, setQuestionCodeEditIndex] = useState(null);

  const closeQuestionCodeEditIndex = () => {
    setQuestionCodeEditIndex(null);
  };

  const showData = [...data, { questionCodeInner: null }];

  const columns = [
    {
      sortable: false,
      Cell: ({ original: { questionCodeInner } }) => {
        return (
          questionCodeInner === null && (
            <Button
              size="small"
              icon={<AddIcon />}
              className={classes.addButton}
              tooltip={t(
                'passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipAdd'
              )}
              onClick={event => {
                event.stopPropagation();
                setQuestionCodeEditIndex(-1);
              }}
            />
          )
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 80
    },
    {
      Header: t(
        'passportCustomConfigEdit_externalClientMetadata_clientConfig_tablеQuestionCodeInner'
      ),
      sortable: false,
      Cell: ({ original: { questionCodeInner } }) => {
        if (questionCodeInner === null) {
          return (
            <div style={{ color: 'gray' }}>
              {t('passportCustomConfigEdit_externalClientMetadata_clientConfig_addCode')}
            </div>
          );
        }

        return questionCodeInner;
      }
    },
    {
      Header: t(
        'passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeOuter'
      ),
      accessor: 'questionCodeOuter',
      sortable: false
    },
    {
      Header: t(
        'passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeValue'
      ),
      accessor: 'questionCodeValue',
      sortable: false
    },
    {
      Header: t(
        'passportCustomConfigEdit_externalClientMetadata_clientConfig_tablequestionCodeAsStatic'
      ),
      sortable: false,
      Cell: ({ original: { questionCodeInner, questionCodeAsStatic } }) =>
        questionCodeInner !== null && (
          <Checkbox withoutForm value={questionCodeAsStatic} />
        ),
      style: {
        textAlign: 'center'
      }
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ original: { questionCodeInner }, index }) => {
        return (
          questionCodeInner !== null && (
            <>
              <Button
                size="small"
                icon={<EditIcon />}
                className={classes.leftButton}
                tooltip={t(
                  'passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipEdit'
                )}
                onClick={event => {
                  event.stopPropagation();
                  setQuestionCodeEditIndex(index);
                }}
              />
              <Button
                size="small"
                icon={<DeleteIcon />}
                className={classes.rightButton}
                color="secondary"
                tooltip={t(
                  'passportCustomConfigEdit_externalClientMetadata_clientConfig_actionTooltipDelete'
                )}
                onClick={event => {
                  event.stopPropagation();

                  const data = unshowData(showData);
                  data.splice(index, 1);

                  onChange(data);
                }}
              />
            </>
          )
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 140
    }
  ];

  return (
    <>
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        columns={columns}
        data={showData}
      />
      <Modal
        open={questionCodeEditIndex !== null}
        onClose={closeQuestionCodeEditIndex}
        title={
          questionCodeEditIndex === -1
            ? t(
                'passportCustomConfigEdit_externalClientMetadata_clientConfig_addQuestionCodeTitle'
              )
            : t(
                'passportCustomConfigEdit_externalClientMetadata_clientConfig_editQuestionCodeTitle'
              )
        }
      >
        <ExternalClientQuestionCodeEdit
          questions={questions}
          questionCodes={questionCodes}
          excludedQuestionCodes={data}
          requiredQuestionCode={
            questionCodeEditIndex === -1
              ? {
                  questionCodeInner: '',
                  questionCodeOuter: '',
                  questionCodeValue: '',
                  questionCodeAsStatic: false
                }
              : unshowData(showData)[questionCodeEditIndex]
          }
          onClose={closeQuestionCodeEditIndex}
          onSubmit={questionCode => {
            const data = unshowData(showData);
            if (questionCodeEditIndex === -1) {
              data.push(questionCode);
            } else {
              data[questionCodeEditIndex] = { ...questionCode };
            }
            onChange(data);

            closeQuestionCodeEditIndex();
          }}
        />
      </Modal>
    </>
  );
};

export default withTranslation(ExternalClientQuestionCodesConfigsEdit);
