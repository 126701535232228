import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import withTranslation from '../../../_hoc/withTranslation';
import Input from '../../../../_ui/Input/Input';
import Button from '../../../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../../../_styles/ButtonsStyle';
import ExportFormQuestionCodesEdit from './ExportFormQuestionCodesEdit';

const ExportFormEdit = props => {
  const { t, questions, questionCodes, exportForm, onClose, onSubmit } = props;

  const [fileName, setFileName] = useState(exportForm.exportFormFileName || '');
  const [reportName, setReportName] = useState({
    reportNameRu: exportForm.reportNameRu || '',
    reportNameKk: exportForm.reportNameKk || ''
  });
  const [applicableRule, setApplicableRule] = useState(exportForm.exportFormApplicableRule || '');
  const [exportQuestionCodes, setExportQuestionCodes] = useState(
    exportForm.exportFormQuestionCodes || []
  );

  const buttonClasses = useButtonStyles();

  const canSubmit = !!fileName && !!reportName.reportNameRu && !!reportName.reportNameKk;

  return (
    <>
      <Input
        withoutForm
        fullWidth
        placeholder={t('exportFormsEdit_exportFormFileName')}
        autoFocus={true}
        value={fileName}
        onChange={event => setFileName(event.target.value)}
      />
      <Input
        withoutForm
        fullWidth
        placeholder={t('exportFormsEdit_exportFormReportNameRu')}
        value={reportName.reportNameRu}
        onChange={event => setReportName(value => ({ ...value, reportNameRu: event.target.value }))}
      />
      <Input
        withoutForm
        fullWidth
        placeholder={t('exportFormsEdit_exportFormReportNameKk')}
        value={reportName.reportNameKk}
        onChange={event => setReportName(value => ({ ...value, reportNameKk: event.target.value }))}
      />
      <TextField
        fullWidth
        multiline
        placeholder={t('exportFormsEdit_exportFormApplicableRule')}
        autoComplete="false"
        variant="filled"
        rows="10"
        value={applicableRule}
        onChange={event => setApplicableRule(event.target.value)}
        margin="normal"
      />
      <div style={{ marginTop: '15px' }}>
        <h4>{t('exportFormsEdit_exportFormQuestionCodesTitle')}</h4>
        <ExportFormQuestionCodesEdit
          questions={questions}
          questionCodes={questionCodes}
          data={exportQuestionCodes}
          onChange={setExportQuestionCodes}
        />
      </div>
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          disabled={!canSubmit}
          onClick={() =>
            onSubmit({
              exportFormFileName: fileName,
              exportFormApplicableRule: applicableRule,
              exportFormQuestionCodes: exportQuestionCodes,
              ...reportName
            })
          }
        />
        <Button type="button" text={t('simpleModal_cancel')} color="secondary" onClick={onClose} />
      </div>
    </>
  );
};

export default withTranslation(ExportFormEdit);
