import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Loading from '../../_ui/Loading/CircularLoading';
import {
  LoadingLayer,
  useCondensedCheckBoxStyles
} from './CheckboxTreeStyle';

export const makeTree = () => [];

export const makeNode = (value, label, nodes = []) =>
  ({ value, label, nodes });

const findNode = (tree, value) => {
  for (const node of tree) {
    if (node.value === value) {
      return node;
    }

    if (Array.isArray(node.nodes)) {
      const found = findNode(node.nodes, value);
      if (found) return found;
    }
  }

  return undefined;
};

export const insertNode = (tree, node, value = undefined) => {
  if (!value) {
    tree.push(node);
  }

  const found = findNode(tree, value);
  if (found) {
    found.nodes.push(node);
  }
};

const leafIds = node => {
  return !node.nodes || node.nodes.length === 0
    ? [node.value]
    : node.nodes.map(leafIds).flat();
};

const CheckboxTree = props => {
  const {
    loading,
    readOnly,
    options,
    expanded: expandedData,
    checked: checkedData,
    onCheck
  } = props;
  const classes = useCondensedCheckBoxStyles();

  const [expanded, setExpanded] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
      setExpanded(expandedData);
    },
    [expandedData]
  );

  useEffect(() => {
      setChecked(checkedData);
    },
    [checkedData]
  );

  const handleCheck = (node, newValue) => {
    const value = checked.includes(node.value);

    if (node.nodes.length === 0) {
      if (value === newValue) return;
      const newChecked = newValue
        ? [...checked, node.value]
        : checked.filter(id => id !== node.value);
      setChecked(newChecked);
      onCheck(newChecked);
    } else {
      const ids = leafIds(node);
      const remaining = checked.filter(id => !ids.includes(id));
      const newChecked = newValue ? [...remaining, ...ids] : remaining;
      setChecked(newChecked);
      onCheck(newChecked);
    }
  };

  const TreeNode = ({ node }) => {
    const isChecked = leafIds(node).every(id => checked.includes(id));
    const isIndeterminate = !isChecked && leafIds(node).some(id => checked.includes(id));

    const onChange = () => {
      handleCheck(node, !isChecked);
    };

    return (
      <TreeItem
        key={node.value}
        nodeId={node.value}
        label={
          <FormControlLabel
            color="white"
            style={{ display: "flex", alignItems: "center" }}
            disabled={readOnly}
            label={node.label}
            control={
              <Checkbox
                className={classes.condensed}
                checked={isChecked}
                onChange={onChange}
                onClick={e => e.stopPropagation()}
                indeterminate={isIndeterminate}
              />
            }
            onClick={e => e.stopPropagation()}
          />
        }
      >
        {node.nodes.length > 0 ?
          <TreeNodes nodes={node.nodes} /> : null
        }
      </TreeItem>
    );
  };

  const TreeNodes = ({ nodes }) =>
    <>
      {nodes.map(node =>
        <TreeNode key={node.label} node={node} />
      )}
    </>;

  return (
    <>
      {loading ?
        <LoadingLayer>
          <Loading />
        </LoadingLayer>
        :
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultEndIcon={<ChevronRightIcon style={{ visibility: 'hidden' }} />}
          expanded={expanded}
          onNodeToggle={(_, nodes) => setExpanded(nodes)}
        >
          <TreeNodes nodes={options} />
        </TreeView>
      }
    </>
  );
};

CheckboxTree.propsTypes = {
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  options: PropTypes.array.isRequired,
  expanded: PropTypes.array,
  checked: PropTypes.array,
  onCheck: PropTypes.func.isRequired
};

CheckboxTree.defaultProps = {
  loading: false,
  readOnly: false,
  expanded: [],
  checked: []
};

export default CheckboxTree;
