import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  buttonsGroup: {
    textAlign: 'right',
    marginTop: '10px',
    paddingTop: '15px',
    borderTop: '2px solid #d2d2d2'
  },
  submitButton: {
    marginRight: '10px'
  }
}));