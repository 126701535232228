import styled, { css } from 'styled-components';

export const TreeWrapper  = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  div {
    outline: none;
  };
  font-size: 18px;
`;

export const FolderIcon = styled.div`
  border-left: solid 8px gray;
  border-bottom: solid 10px gray;
  margin-right: 10px;
  box-sizing: border-box;
  width: 16px;
  height: 12px;
  ${props => props.expanded
    ? css`
      filter: drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray);
      border-color: white;
    ` : css`
      filter: none;
      border-color: gray;
    `
  }
`;

export const TooltipBox = styled.div`
  display: flex;
  flex-direction: column;
  button {
    text-transform: none;
    margin-bottom: 3px;
  }
`;

export const InfoButton = styled.button`
  padding: 0;
  border-radius: 100%;
  background-color: gray;
  color: white;
  width: 16px;
  height: 16px;
  border: 0;
  font-weight: 100;
  outline: none;
  cursor: pointer;
`;