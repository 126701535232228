import React, { useState, useMemo } from 'react';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import { useDictInfos } from '../_hooks/useDictInfo';
import StaticTable from '../../_ui/Table/StaticTable';
import Button from '../../_ui/Button/Button';
import Modal from '../../_ui/Modal/Modal';
import withTranslation from '../../components/_hoc/withTranslation';
import DimensionEditCode from './DimensionEditCode';
import { useStyles } from './DimensionEditStyle';

const unshowData = showData =>
  showData
    .map(({ code }) => code)
    .slice(null, -1);

const DimensionEdit = props => {
  const {
    t,
    title,
    data,
    excludedData,
    onChange
  } = props;

  const classes = useStyles();

  const [dimensionEditIndex, setDimensionEditIndex] = useState(null);

  const dataInfos = useDictInfos(data);

  const showData = useMemo(() => {
      return [
        ...dataInfos.map(({ code, ru_name, kk_name, description }) => ({
          code,
          name: ru_name || kk_name || description || ''
        })),
        { code: null, name: null }
      ];
    },
    [dataInfos]
  );

  const closeDimensionEditIndex = () => {
    setDimensionEditIndex(null);
  };

  const columns = [
    {
      Header: t('dimensionEdit_tableDictCodeTitle'),
      sortable: false,
      Cell: ({ original: { code } }) => {
        return (
          code ||
          <Button
            size="small"
            icon={<AddIcon/>}
            className={classes.addButton}
            tooltip={t('dimensionEdit_actionTooltipAdd')}
            onClick={event => {
              event.stopPropagation();
              setDimensionEditIndex(-1);
            }}
          />
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 200
    },
    {
      Header: title,
      sortable: false,
      Cell: ({ original: { code, name } }) => {
        if (code === null) {
          return (
            <div style={{ color: 'gray' }}>
              {t('dimensionEdit_addMore')}
            </div>
          );
        }

        return name;
      }
    },
    {
      Header: t('common_actions'),
      sortable: false,
      Cell: ({ original: { code }, index }) => {
        return (
          code &&
          <>
            <Button
              size="small"
              icon={<EditIcon/>}
              className={classes.leftButton}
              tooltip={t('dimensionEdit_actionTooltipEdit')}
              onClick={event => {
                event.stopPropagation();

                setDimensionEditIndex(index);
              }}
            />
            <Button
              size="small"
              icon={<DeleteIcon/>}
              className={classes.rightButton}
              color="secondary"
              tooltip={t('dimensionEdit_actionTooltipDelete')}
              onClick={event => {
                event.stopPropagation();

                const data = unshowData(showData);
                const code = data[index];
                data.splice(index, 1);

                const newExcludedData = { ...excludedData };
                delete newExcludedData[code];

                onChange(data, newExcludedData);
              }}
            />
          </>
        );
      },
      style: {
        textAlign: 'center'
      },
      width: 140
    }
  ];

  return (
    <>
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        columns={columns}
        data={showData}
      />
      <Modal
        open={dimensionEditIndex !== null}
        onClose={closeDimensionEditIndex}
        title={
          dimensionEditIndex === -1 ?
            t('dimensionEdit_addTitle') :
            t('dimensionEdit_editTitle')
        }
      >
        <DimensionEditCode
          code={dimensionEditIndex === -1 ? '' : unshowData(showData)[dimensionEditIndex]}
          excludedData={excludedData}
          onClose={closeDimensionEditIndex}
          onSubmit={(code, excludedData) => {
            const data = unshowData(showData);
            if (dimensionEditIndex === -1) {
              data.push(code);
            } else {
              data[dimensionEditIndex] = code;
            }
            onChange(data, excludedData);

            closeDimensionEditIndex();
          }}
        />
      </Modal>
    </>
  );
};

export default withTranslation(DimensionEdit);
