import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Button from '@material-ui/core/Button';
import withTranslation from '../../components/_hoc/withTranslation';
import Loading from '../Loading/CircularLoading';
import { Div, LoadingBox } from './TableStyle';

const CheckboxTable = checkboxHOC(ReactTable);

class StaticTable extends React.Component {
  state = {
    selectAll: false
  };

  toggleSelection = (key, shift, row) => {
    let selection = [...this.props.selection];
    const keyIndex = selection.indexOf(key);
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      selection.push(key);
    }
    this.props.setSelection(selection);
  };

  toggleAll = () => {
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        selection.push(item._original.id);
      });
    }
    this.setState({ selectAll });
    this.props.setSelection(selection);
  };

  isSelected = key => {
    return this.props.selection.includes(key);
  };

  render() {
    const { loading, data, columns, t } = this.props;
    const { selectAll } = this.state;
    const { toggleSelection, toggleAll, isSelected } = this;
    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      keyField: 'id',
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = r ? this.isSelected(r.original.id) : false;
        return {
          onClick: () => {
            this.toggleSelection(r.original.id, false, r.original);
          },
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
            cursor: 'pointer'
          }
        };
      }
    };

    // pageSize
    let size = 5;
    if (data.length > 0) {
      size = data.length;
    }

    return (
      <Div>
        <CheckboxTable
          {...this.props}
          {...checkboxProps}
          ref={r => (this.checkboxTable = r)}
          NoDataComponent={() => {
            if (loading) {
              return null;
            } else {
              return (
                <LoadingBox className="-loading -active">
                  <div className="-loading-inner">
                    {t('reactTableWrapper_selectNoOptions')}
                  </div>
                </LoadingBox>
              );
            }
          }}
          LoadingComponent={data => {
            if (!data.loading) return null;
            return (
              <LoadingBox className="-loading -active">
                <div className="-loading-inner">
                  <Loading/>
                </div>
              </LoadingBox>
            );
          }}
          showPaginationBottom={false}
          data={data}
          pageSize={size}
          filterable={this.props.filterable}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          }
          columns={columns}
          className="-striped -highlight"
        />
        {!this.props.hideBtn && this.props.onClickLoadMore && (
          <Button
            fullWidth
            size="small"
            variant="outlined"
            loading={this.props.loadingBtn}
            onClick={this.props.onClickLoadMore}
          >
            {t('staticTable_load')}
          </Button>
        )}
      </Div>
    );
  }
}

StaticTable.propTypes = {
  hideBtn: PropTypes.bool,
  filterable: PropTypes.bool,
  loading: PropTypes.bool,
  selection: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  buttons: PropTypes.element,
  onClickRow: PropTypes.func,
  withoutHeader: PropTypes.bool,
  markText: PropTypes.string
};

StaticTable.defaultProps = {
  hideBtn: false,
  filterable: true,
  loading: false,
  withoutHeader: false
};

export default withTranslation(StaticTable);
