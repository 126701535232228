/**
 * Передвигает елемент массива из одной позиции в другую.  Входной массив будет изменен.
 * @param array - входной массив
 * @param oldIndex - старый индех елемента
 * @param newIndex - новый индех елемента
 * @return array
 */
export function arrayMove(array, oldIndex, newIndex) {
  while (oldIndex < 0) {
    oldIndex += array.length;
  }
  while (newIndex < 0) {
    newIndex += array.length;
  }
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1;
    while (k--) {
      array.push(undefined);
    }
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return array;
}