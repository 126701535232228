import React, { useState, useEffect } from 'react';
import SortableTree from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { FolderIcon, InfoButton, TooltipBox, TreeWrapper } from './SectionsTreeStyle';
import withTranslation from '../_hoc/withTranslation';
import Button from '../../_ui/Button/Button';
import Access from '../Access';
import { Permissions } from '../../_helpers/Permissions';
import SectionsTreeModal from './SectionsTreeModal';

export const EDIT_SECTION_MODE = 'EDIT_SECTION_MODE';
export const ADD_SECTION_MODE = 'ADD_SECTION_MODE';
export const ADD_SUBSECTION_MODE = 'ADD_SUBSECTION_MODE';
export const REMOVE_SECTION_MODE = 'REMOVE_SECTION_MODE';

function SectionsTree(
  {
    treeData,
    t,
    deleteSection,
    editSection,
    addSection,
    addSubSection,
    moveSection,
    ...oProps
  }
) {
  const [data, setData] = useState(treeData);
  const [open, setModalState] = useState(false);
  const [mode, setMode] = useState(null);
  const [values, setValues] = useState(null);

  useEffect(() => {
    setData(treeData);
  }, [treeData]);

  const onClick = (info, mode) => {
    setMode(mode);
    setModalState(true);
    setValues({
      id: info.node.id,
      ru_name: info.node.names.ru_name,
      kk_name: info.node.names.kk_name,
      en_name: info.node.names.en_name,
      rules: info.node.rules
    });
  };

  const onSubmit = formValues => {
    switch (mode) {
      case EDIT_SECTION_MODE:
        return editSection(values.id, formValues, setModalState);
      case ADD_SECTION_MODE:
        return addSection(values.id, formValues, setModalState);
      case ADD_SUBSECTION_MODE:
        return addSubSection(values.id, formValues, setModalState);
      case REMOVE_SECTION_MODE:
        return deleteSection(values.id, setModalState);
      default:
        return '';
    }
  };

  return (
    <>
      <TreeWrapper>
        <SortableTree
          treeData={data}
          onChange={data => setData(data)}
          theme={FileExplorerTheme}
          onMoveNode={props => {
            if (
              props.prevTreeIndex !== props.nextTreeIndex ||
              props.prevPath.length !== props.nextPath.length
            ) {
              moveSection(
                props.node.id,
                props.treeIndex,
                props.nextParentNode.id,
                props.nextParentNode.children[props.nextParentNode.children.length - 2] &&
                  props.nextParentNode.children[props.nextParentNode.children.length - 1].id ===
                    props.node.id &&
                  props.nextParentNode.children[props.nextParentNode.children.length - 2].id
              );
            }
          }}
          canDrag={({ treeIndex }) => treeIndex !== 0}
          canDrop={({ nextParent }) => !!nextParent}
          generateNodeProps={rowInfo => ({
            icons: [<FolderIcon expanded={rowInfo.node.expanded} />],
            buttons: rowInfo.node.isRemoved
              ? []
              : [
                  <Tooltip
                    interactive
                    placement="right"
                    title={
                      <TooltipBox>
                        {!rowInfo.node.main && (
                          <Access permissions={[Permissions.editSection.code]}>
                            <Button
                              size="small"
                              text={t('sectionsTree_edit')}
                              onClick={() => onClick(rowInfo, EDIT_SECTION_MODE)}
                            />
                          </Access>
                        )}
                        {!rowInfo.node.main && (
                          <Access permissions={[Permissions.addSection.code]}>
                            <Button
                              size="small"
                              text={t('sectionsTree_addSection')}
                              onClick={() => onClick(rowInfo, ADD_SECTION_MODE)}
                            />
                          </Access>
                        )}
                        <Access permissions={[Permissions.addSubsection.code]}>
                          <Button
                            size="small"
                            text={t('sectionsTree_addSubSection')}
                            onClick={() => onClick(rowInfo, ADD_SUBSECTION_MODE)}
                          />
                        </Access>
                        {!rowInfo.node.main && (
                          <Access permissions={[Permissions.deleteSection.code]}>
                            <Button
                              color="secondary"
                              size="small"
                              text={t('sectionsTree_remove')}
                              onClick={() => onClick(rowInfo, REMOVE_SECTION_MODE)}
                            />
                          </Access>
                        )}
                      </TooltipBox>
                    }
                  >
                    <InfoButton disabled={rowInfo.node.isRemoved}>i</InfoButton>
                  </Tooltip>
                ]
          })}
          {...oProps}
        />
      </TreeWrapper>
      <SectionsTreeModal
        isOpen={open}
        close={setModalState}
        mode={mode}
        values={values}
        onSubmit={onSubmit}
      />
    </>
  );
}

SectionsTree.propTypes = {
  treeData: PropTypes.array.isRequired
};

export default withTranslation(SectionsTree);
