import { makeStyles } from '@material-ui/core';

export const useStyles = props => makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: props.width || 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflow: 'scroll'
  },
  closeIcon: {
    top: '5px',
    right: '5px',
    position: 'absolute'
  }
}));