export const DICTIONARY = Object.freeze({
  OO_TYPES: '230'
});

export const PASSPORT_CUSTOM_CONFIGS = Object.freeze({
  ES_METADATA_CONFIG: {
    '@type': 'EsMetadataCustomConfig',
    id: 'ES_METADATA_CONFIG',
    assigneeGroupNameTemplate: '',
    sendIntermediateNotifications: false,
    intermediateNotificationQuestionCodes: [],
    serviceRagConfig: {
      greenUntil: '',
      amberUntil: ''
    }
  },
  EXPORT_FORM_METADATA_CONFIG: {
    '@type': 'ExportFormMetadataCustomConfig',
    id: 'EXPORT_FORM_METADATA_CONFIG',
    exportForms: []
  },
  EXTERNAL_CLIENT_METADATA_CONFIG: {
    '@type': 'ExternalClientMetadataCustomConfig',
    id: 'EXTERNAL_CLIENT_METADATA_CONFIG',
    externalClientConfigs: []
  }
});

export const INDICATOR_CUSTOM_CONFIGS = Object.freeze({
  NEDB_SCHOOL_SUBTYPES_VISIBILITY_CONFIG: {
    '@type': 'NedbSchoolSubtypesVisibilityCustomConfig',
    id: 'NEDB_SCHOOL_SUBTYPES_VISIBILITY_CONFIG',
    subtypes: []
  }
});
