import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { history } from '../../_helpers/store';
import routers from '../../_helpers/routers';
import withTranslation from '../../components/_hoc/withTranslation';
import Access from '../../components/Access';
import { Permissions } from '../../_helpers/Permissions';
import IndicatorEdit from '../../components/IndicatorEdit/IndicatorEdit';
import { makeIndicator } from '../../components/IndicatorEdit/IndicatorEditUtils';
import { addMetadata } from '../PassportsPage/PassportsDucks';
import {
  indicatorsModule,
  addIndicator
} from '../IndicatorsPage/IndicatorsDucks';

const IndicatorsAddPage = props => {
  const {
    t,
    loadingIndicators,
    indicatorIndex,
    addIndicator,
    addMetadata
  } = props;

  useEffect(() => {
      if (indicatorIndex === null) {
        history.push(routers.indicatorsPage.path);
      }
    },
    [indicatorIndex]
  );

  const onSaveIndicator = (indicator, metadata) => {
    const actions = () => {
      addIndicator(
        indicator,
        () => history.push(routers.indicatorsPage.path)
      );
    };

    if (metadata) {
      addMetadata(metadata, actions);
    } else {
      actions();
    }
  };

  const onCancelIndicator = () => {
    history.push(routers.indicatorsPage.path);
  };

  return (
    <>
      <h3>{t('indicatorsAddPage_title')}</h3>
      <Access permissions={[Permissions.addIndicator.code, Permissions.editIndicator.code]}>
        {indicatorIndex !== null &&
        <IndicatorEdit
          loading={loadingIndicators}
          indicator={makeIndicator()}
          onCancel={onCancelIndicator}
          onSubmit={onSaveIndicator}
        />
        }
      </Access>
    </>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      loadingIndicators: state[indicatorsModule].loading,
      indicatorIndex: state[indicatorsModule].indicatorIndex
    }),
    {
      addIndicator,
      addMetadata
    })
)(IndicatorsAddPage);
