import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { cloneDeep } from 'lodash';
import { Edit as EditIcon } from '@material-ui/icons';
import Button from '../../_ui/Button/Button';
import StaticTable from '../../_ui/Table/StaticTable';
import Modal from '../../_ui/Modal/Modal';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../../components/_hoc/withTranslation';
import { collectSectionsQuestionCodes } from '../PassportEdit/PassportEditUtils';
import {
  passportCustomConfigsEditModule,
  getMetadatasQuestions
} from './PassportCustomConfigsEditDucks';
import PassportCustomConfigEdit from './PassportCustomConfigEdit';

const PassportCustomConfigsEdit = props => {
  const {
    t,
    passport,
    onSave,
    onClose,
    loading,
    metadatasQuestionCodes,
    metadatasQuestions,
    getMetadatasQuestions
  } = props;

  const [configs, setConfigs] = useState(cloneDeep(passport.customConfigs));

  const [customConfigEdit, setCustomConfigEdit] = useState(null);

  const questionCodes = useMemo(
    () => collectSectionsQuestionCodes(passport.group.subGroups),
    [passport.group.subGroups]
  );

  useEffect(() => {
    getMetadatasQuestions([
      { code: 'REQUESTER', name: 'REQUESTER' },
      { code: 'SERVICE_PROVIDER', name: 'SERVICE_PROVIDER' },
      ...questionCodes
        .filter(
          code =>
            passport.questions.hasOwnProperty(code) &&
            passport.questions[code].type === 'complex' &&
            passport.questions[code].config &&
            passport.questions[code].config.metadataKey
        )
        .map(code => ({
          code,
          name: passport.questions[code].config.metadataKey
        }))
    ]);
  }, [passport.questions, questionCodes, getMetadatasQuestions]);

  const onSaveCustomConfig = config => {
    setConfigs(configs => {
      const index = configs.findIndex(({ id }) => id === config.id);
      if (index >= 0) {
        configs[index] = config;
      }
      return configs;
    });

    setCustomConfigEdit(null);
  };

  const onCloseCustomConfigEdit = () => setCustomConfigEdit(null);

  const buttonClasses = useButtonStyles();

  const columns = [
    {
      Header: 'id',
      accessor: 'id',
      sortable: false
    },
    {
      sortable: false,
      Cell: ({ original }) => (
        <Button
          size="small"
          icon={<EditIcon />}
          tooltip={t('passportEdit_actionTooltipEdit')}
          onClick={event => {
            event.stopPropagation();
            setCustomConfigEdit(original);
          }}
        />
      ),
      style: {
        textAlign: 'center'
      },
      width: 80
    }
  ];

  return (
    <div style={{ marginTop: '15px' }}>
      <StaticTable
        withoutHeader
        collapsible={false}
        filterable={false}
        loading={loading}
        minRows={Math.max(3, configs.length)}
        columns={columns}
        data={configs}
      />
      <div className={buttonClasses.buttonsGroup}>
        <Button
          type="button"
          text={t('simpleModal_submit')}
          className={buttonClasses.submitButton}
          disabled={loading}
          onClick={() => onSave(configs)}
        />
        <Button
          type="button"
          text={t('simpleModal_cancel')}
          color="secondary"
          onClick={onClose}
        />
      </div>
      <Modal
        open={!!customConfigEdit}
        onClose={onCloseCustomConfigEdit}
        title={
          customConfigEdit ? t(`passportEdit_customConfig_${customConfigEdit.id}`) : ''
        }
      >
        <PassportCustomConfigEdit
          questionCodes={{
            __root__: questionCodes,
            ...metadatasQuestionCodes
          }}
          questions={{
            __root__: passport.questions,
            ...metadatasQuestions
          }}
          config={customConfigEdit}
          onSave={onSaveCustomConfig}
          onClose={onCloseCustomConfigEdit}
        />
      </Modal>
    </div>
  );
};

export default compose(
  withTranslation,
  connect(
    state => ({
      loading: state[passportCustomConfigsEditModule].loading,
      metadatasQuestionCodes: state[passportCustomConfigsEditModule].questionCodes,
      metadatasQuestions: state[passportCustomConfigsEditModule].questions
    }),
    {
      getMetadatasQuestions
    }
  )
)(PassportCustomConfigsEdit);
