import styled from 'styled-components';

const separateWords = (string, options) => {
  options = options || {};
  const separator = options.separator || '_';
  const split = options.split || /(?=[A-Z])/;

  return string.split(split).join(separator);
};

const decamelize = (string, options) =>
  separateWords(string, options).toLowerCase();

const getRule = (ruleName, defaultRule) => (props) => {
  const foundRule = Object.keys(props).find(key => key.startsWith(ruleName));
  if (!foundRule || !props[foundRule]) {
    return defaultRule;
  }
  const [, ...rule] = decamelize(foundRule, { separator: '-' }).split('-');
  return rule.join('-');
};

/*
 * Valid Props:
 *
 * inline
 * directionRowReverse
 * directionColumn
 * directionColumnReverse
 * wrapWrap
 * wrapWrapReverse
 * itemsFlexStart
 * itemsFlexEnd
 * itemsCenter
 * itemsBaseline
 * contentFlexStart
 * contentFlexEnd
 * contentCenter
 * contentSpaceBetween
 * contentSpaceAround
 * justifySpaceBetween
 * justifySpaceAround
 * justifyFlexEnd
 * justifyCenter
 */

const FlexContainer = styled.div`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: ${getRule('direction', 'row')};
  flex-wrap: ${getRule('wrap', 'nowrap')};
  justify-content: ${getRule('justify', 'flex-start')};
  align-items: ${getRule('items', 'stretch')};
  align-content: ${getRule('content', 'stretch')};
`;

export default FlexContainer;