import { cloneDeep } from 'lodash';
import { INDICATOR_CUSTOM_CONFIGS } from '../../_helpers/constants';

export const ensureIndicatorCustomConfigs = configs => {
  const customConfigs = cloneDeep(INDICATOR_CUSTOM_CONFIGS);

  if(configs) {
    configs.forEach(config => {
      customConfigs[config.id] = cloneDeep(config);
    });
  }

  return Object.keys(customConfigs).map(key => customConfigs[key]);
};
