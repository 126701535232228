import * as Yup from 'yup';
import translate from '../../utils/translate';

export const PassportEditSchema = Yup.object().shape({
  id: Yup.string(() => translate('passportEditValidation_idHint'))
    .matches(/^[A-Za-z0-9_]+$/, () => translate('passportEditValidation_idMatch'))
    .required(() => translate('passportEditValidation_idRequired')),
  ru_name: Yup.string(() => translate('passportEditValidation_ruNameHint'))
    .required(() => translate('passportEditValidation_ruNameRequired')),
  kk_name: Yup.string(() => translate('passportEditValidation_kkNameHint'))
    .required(() => translate('passportEditValidation_kkNameRequired')),
  en_name: Yup.string(() => translate('passportEditValidation_enNameHint'))
    .required(() => translate('passportEditValidation_enNameRequired'))
});
