import { createReducer } from 'redux-starter-kit';
import { PassportsApi } from '../../_helpers/service';
import { Notice } from '../../utils/Notice';
import { getErrorCode, translateError } from '../../utils/apiError';
import { collectSectionsQuestionCodes } from '../PassportEdit/PassportEditUtils';

/**
 * Constants
 */

export const passportCustomConfigsEditModule = 'passportCustomConfigsEdit';

const LOADING = `${passportCustomConfigsEditModule}/LOADING`;

const GET_METADATAS_QUESTIONS = `${passportCustomConfigsEditModule}/GET_METADATAS_QUESTIONS`;
const GET_METADATAS_QUESTIONS_ERROR = `${passportCustomConfigsEditModule}/GET_METADATAS_QUESTIONS_ERROR`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  questionCodes: {},
  questions: {},
  error: null
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    const { loading } = action;

    state.loading = loading;
  },
  [GET_METADATAS_QUESTIONS]: (state, action) => {
    const { questionCodes, questions } = action;

    state.questionCodes = questionCodes;
    state.questions = questions;
    state.error = null;
  },
  [GET_METADATAS_QUESTIONS_ERROR]: (state, action) => {
    const { error } = action;

    state.error = error;
  }
});

/**
 * Actions
 */

export const getMetadatasQuestions = names => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  try {
    const questionCodes = {};
    const questions = {};

    for (const { code, name } of names) {
      const metadata = (await PassportsApi.getMetadata(name)).data;

      questionCodes[code] = collectSectionsQuestionCodes(metadata.group.subGroups);
      questions[code] = questionCodes[code]
        .reduce((obj, code) =>
            metadata.questions.hasOwnProperty(code)
              ? ({
                ...obj,
                [code]: metadata.questions[code]
              })
              : obj,
          {}
        );
    }

    dispatch({ type: GET_METADATAS_QUESTIONS, questionCodes, questions });
  } catch (error) {
    const errorCode = getErrorCode(error);
    Notice.error(translateError(errorCode, 'passportsDucks_getPassportError'));

    dispatch({ type: GET_METADATAS_QUESTIONS_ERROR, error: errorCode });
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};
