import React from "react";
import { compose } from 'redux';
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import routers from "../_helpers/routers";
import ProtectedRoute from "./ProtectedRoute";
import { loginModule } from "../pages/LoginPage/LoginDucks";
import Page404 from "../pages/Page404/Page404";
import Content from '../components/Content/Content';

const Root = ({ history, ...rest }) => {
  return (
    <>
      <NotificationContainer />
      <div className="scroll-fix">
      <Content user={rest.user} pathname={history.location.pathname}>
        <Switch>
          {Object.values(routers).map(route => (
            <ProtectedRoute exact {...route} key={route.path} {...rest} />
          ))}
          <Route render={() => <Page404 />} />
        </Switch>
      </Content>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state[loginModule].user,
  userPermissions: state[loginModule].permissions,
  loading: state[loginModule].loading
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Root);
