/**
 * Часто применяемые цвета
 */
export default {
  primary: '#2185D0',
  primaryLight: '#4fb4f2',
  error: '#D32F2F',
  errorLight: '#ff8ca3',
  success: '#43A047',
  warning: '#FFA000',
};
