import React, { useState } from 'react';
import withTranslation from '../../components/_hoc/withTranslation';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import Checkbox from '../../_ui/Checkbox/Checkbox';
import Modal from '../../_ui/Modal/Modal';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import { Permissions } from '../../_helpers/Permissions';
import Access from '../../components/Access';
import PassportRecipientsEdit from '../PassportRecipientsEdit/PassportRecipientsEdit';
import PassportCustomConfigsEdit from '../PassportCustomConfigsEdit/PassportCustomConfigsEdit';
import { PassportEditSchema } from './PassportEditValidation';
import {
  filledPassport,
  updatePassportInfo,
  updatePassportRecipients,
  hasPassportCustomConfigs,
  updatePassportCustomConfigs
} from './PassportEditUtils';

const PassportEdit = props => {
  const { t, op, passport, onClose, onSubmit } = props;

  const [currentVersion, setCurrentVersion] = useState(false);

  const [openRecipientsEdit, setOpenRecipientsEdit] = useState(null);

  const [openCustomConfigsEdit, setOpenCustomConfigsEdit] = useState(false);

  // eslint-disable-next-line
  const [allowSavingWithFailedRules, setAllowMissingRequiredQuestions] = useState(
    passport.allowSavingWithFailedRules || false
  );

  const buttonClasses = useButtonStyles();

  const isNew = op === 'ADD';
  const isClone = op === 'CLONE';

  const onSaveRecipients = recipients => {
    onSubmit(
      {
        ...updatePassportRecipients(
          updatePassportInfo(passport, openRecipientsEdit),
          recipients
        ),
        allowSavingWithFailedRules
      },
      currentVersion,
      isNew
        ? t('passportEdit_commitCommentNew')
        : t('passportEdit_commitCommentRecipients')
    );
    setOpenRecipientsEdit(null);
    onClose();
  };

  const onCloseRecipientsEdit = () => {
    setOpenRecipientsEdit(null);
  };

  const onSaveCustomConfigs = configs => {
    onSubmit(
      {
        ...updatePassportCustomConfigs(passport, configs),
        allowSavingWithFailedRules
      },
      currentVersion,
      t('passportEdit_commitCommentCustomConfigs')
    );
    setOpenCustomConfigsEdit(false);
    onClose();
  };

  const onCloseCustomConfigsEdit = () => {
    setOpenCustomConfigsEdit(false);
  };

  const customConfigDisabled = () => {
    const { id, ru_name, kk_name, en_name } = passport;

    return !(id && ru_name && kk_name && en_name);
  };

  return (
    <>
      <Form
        initialValues={{
          id: isClone ? `${passport.id} ${t('passportEdit_cloneIdNote')}` : passport.id,
          ru_name: passport.ru_name,
          kk_name: passport.kk_name,
          en_name: passport.en_name,
          recipients: isNew ? [] : passport.recipients
        }}
        validate={PassportEditSchema}
        onSubmit={values => {
          onSubmit(
            {
              ...updatePassportRecipients(
                updatePassportInfo(passport, values),
                values.recipients
              ),
              allowSavingWithFailedRules
            },
            currentVersion,
            isNew
              ? t('passportEdit_commitCommentNew')
              : t('passportEdit_commitCommentUpdate')
          );
        }}
        render={values => {
          return (
            <>
              <Input fullWidth autoTrim name="id" label={t('common_id')} />
              {!isClone && (
                <Input
                  fullWidth
                  autoTrim
                  multiline
                  rows="3"
                  name="ru_name"
                  label={t('common_ruName')}
                />
              )}
              {!isClone && (
                <Input
                  fullWidth
                  autoTrim
                  multiline
                  rows="3"
                  name="kk_name"
                  label={t('common_kkName')}
                />
              )}
              {!isClone && (
                <Input
                  fullWidth
                  autoTrim
                  multiline
                  rows="3"
                  name="en_name"
                  label={t('common_enName')}
                />
              )}
              {!isClone && (
                <Button
                  style={{ marginTop: '15px', marginBottom: '10px', marginRight: '8px' }}
                  type="button"
                  size="small"
                  text={t('passportEdit_recipientsEdit')}
                  disabled={!(filledPassport(values) && values.recipients)}
                  onClick={() => setOpenRecipientsEdit(values)}
                />
              )}
              {!isClone && (
                <Button
                  style={{ marginTop: '15px', marginBottom: '10px' }}
                  type="button"
                  size="small"
                  text={t('passportEdit_customConfigsEdit')}
                  disabled={
                    isNew || customConfigDisabled() || !hasPassportCustomConfigs(passport)
                  }
                  onClick={() => setOpenCustomConfigsEdit(true)}
                />
              )}
              {!isNew && !isClone && (
                <Checkbox
                  withoutForm
                  fullWidth
                  label={t('passportEdit_currentVersion')}
                  value={currentVersion}
                  onChange={() => setCurrentVersion(currentVersion => !currentVersion)}
                />
              )}
              {/*<Checkbox
                      withoutForm
                      fullWidth
                      label={t('passportSave_allowSavingWithFailedRules')}
                      value={allowSavingWithFailedRules}
                      onChange={() => setAllowMissingRequiredQuestions(value => !value)}
                      />
                    */}
              <div className={buttonClasses.buttonsGroup}>
                <Button
                  type="submit"
                  text={t('simpleModal_submit')}
                  className={buttonClasses.submitButton}
                />
                <Button
                  type="button"
                  text={t('simpleModal_cancel')}
                  color="secondary"
                  onClick={onClose}
                />
              </div>
            </>
          );
        }}
      />
      <Access
        permissions={[
          Permissions.createPassport.code,
          Permissions.editPassport.code,
          Permissions.editPassportCustomConfigs.code
        ]}
      >
        <Modal
          open={openRecipientsEdit !== null}
          onClose={onCloseRecipientsEdit}
          title={t('passportEdit_recipientsEditTitle')}
        >
          <PassportRecipientsEdit
            recipients={openRecipientsEdit ? openRecipientsEdit.recipients : []}
            onSave={onSaveRecipients}
            onClose={onCloseRecipientsEdit}
          />
        </Modal>
        <Modal
          open={openCustomConfigsEdit}
          onClose={onCloseCustomConfigsEdit}
          title={t('passportEdit_customConfigsEdit')}
        >
          <PassportCustomConfigsEdit
            passport={passport}
            onSave={onSaveCustomConfigs}
            onClose={onCloseCustomConfigsEdit}
          />
        </Modal>
      </Access>
    </>
  );
};

export default withTranslation(PassportEdit);
