import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    color: 'white',
    '& h6': {
      fontWeight: 'bold'
    }
  },
  exitButton: {
    borderRadius: 0,
    color: 'white'
  }
}));

export const LocaleBtns = styled.div`
  & button {
    color: white;
    font-weight: bold;
    background: linear-gradient(45deg, #2196F3 30%, #21CBF3 90%);
    box-shadow: none;
  }
  
  & button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: ${props => props.lang === 'kk' && 'linear-gradient(45deg,#005fab 30%,#004f93 90%)'};
  }
  
  & button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: ${props => props.lang === 'ru' && 'linear-gradient(45deg,#005fab 30%,#004f93 90%)'};
  }
`;
