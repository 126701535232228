import React from 'react';
import Form from '../../_ui/Form/Form';
import Input from '../../_ui/Input/Input';
import Button from '../../_ui/Button/Button';
import { useStyles as useButtonStyles } from '../../_styles/ButtonsStyle';
import withTranslation from '../../components/_hoc/withTranslation';
import IndicatorCustomConfigSchoolSubtypesEdit from './IndicatorCustomConfigSchoolSubtypesEdit';
import { IndicatorCustomConfigSchema } from './IndicatorCustomConfigEditValidation';

const IndicatorCustomConfigEdit = props => {
  const {
    t,
    config,
    onSave,
    onClose
  } = props;

  const buttonClasses = useButtonStyles();

  return (
    <Form
      initialValues={config}
      validate={IndicatorCustomConfigSchema}
      onSubmit={config => {
        onSave(config);
      }}
      render={(config, { setValues: setConfig }) => {
        return (
          <>
            <Input
              fullWidth
              name="id"
              label={'id'}
              disabled={true}
            />
            <div style={{ marginTop: '15px' }}>
              <h4>{t('indicatorCustomConfigEdit_SchoolSubtypesTitle')}</h4>
              <IndicatorCustomConfigSchoolSubtypesEdit
                data={config.subtypes}
                onChange={subtypes => {
                  setConfig({
                    ...config,
                    subtypes
                  });
                }}
              />
            </div>
            <div className={buttonClasses.buttonsGroup}>
              <Button
                type="submit"
                text={t('simpleModal_submit')}
                className={buttonClasses.submitButton}
              />
              <Button
                type="button"
                text={t('simpleModal_cancel')}
                color="secondary"
                onClick={onClose}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default withTranslation(IndicatorCustomConfigEdit);
