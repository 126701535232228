import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageLoader from '../components/PageLoader/PageLoader';
import Page403 from '../pages/Page403/Page403';
import routers from '../_helpers/routers';

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={routeProps => {
      if (rest.withoutAuth && rest.path !== routers.loginPage.path) {
        return (
          <div className="content">
            <Component {...routeProps} />
          </div>
        );
      }

      if (rest.loading) {
        return <PageLoader />;
      }

      // перенаправляем на страницу логина если не авторизован
      if (!rest.withoutAuth && rest.user === null) {
        return <Redirect to={routers.loginPage.path} />;
      }

      // перенаправляем если авторизован и находися на странице логина
      if (rest.user && rest.path === routers.loginPage.path) {
        return <Redirect to={routers.homePage.path} />;
      }

      // проверяем доступ к странице
      if (rest.permissions && !rest.userPermissions.some(item => rest.permissions.includes(item))) {
        return <Page403 />;
      }

      return (
        <div className="content">
          <Component {...routeProps} />
        </div>
      );
    }}
  />
);

export default ProtectedRoute;
